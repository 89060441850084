import React from 'react';
import cx from 'classnames';
import { Spinner } from 'react-bootstrap';

import styles from './CourseEditLayout.module.scss';

import CourseHeader, { CourseHeaderProps } from '../../organisms/CourseHeader';
import EditCourseMenu, { EditCourseMenuProps } from '../../blocks/EditCourseMenu';
import CourseEditPage, { CourseEditPageProps } from '../../pages/CourseEditPage';
import ConfirmationModal, { ConfirmationModalProps } from '../../organisms/ConfirmationModal';

export const defaultProps = {
  courseHeader: {
    button: {
      type: 'TextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Basic',
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Exit',
      },
    },
  } as CourseHeaderProps,
  editCourseMenu: {
    menuCourse: {
      type: 'Selected',
      text: {
        type: 'Subtitle1',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'How to Build a Compelling Competitive Strategy',
      },
    },
    divider: {
      style: 'Horizontal',
    },
    menuLessonItemList: {
      menuLessonItems: [
        {
          state: 'Collapsed',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Collapsed',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 1',
            },
            collapsedIcon: {
              asset: 'ChevronUp',
              style: 'Dark',
            },
          },
        },
        {
          state: 'Collapsed',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Collapsed',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 2',
            },
            collapsedIcon: {
              asset: 'ChevronUp',
              style: 'Dark',
            },
          },
        },
        {
          state: 'Expanded',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Expanded',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 3',
            },
            expandedIcon: {
              asset: 'ChevronDown',
              style: 'Dark',
            },
          },
          menuUnitList: {
            menuUnits: [
              {
                type: 'Default',
                icon: {
                  asset: 'File',
                  style: 'Grey',
                },
                text: {
                  type: 'Paragraph3',
                  style: 'Dark',
                  size: 'Small',
                  align: 'Left',
                  value: 'Quiz',
                },
              },
              {
                type: 'Default',
                icon: {
                  asset: 'File',
                  style: 'Grey',
                },
                text: {
                  type: 'Paragraph3',
                  style: 'Dark',
                  size: 'Small',
                  align: 'Left',
                  value: 'Video',
                },
              },
              {
                type: 'Default',
                icon: {
                  asset: 'File',
                  style: 'Grey',
                },
                text: {
                  type: 'Paragraph3',
                  style: 'Dark',
                  size: 'Small',
                  align: 'Left',
                  value: 'Quiz',
                },
              },
            ],
          },
          addUnit: {
            type: 'AddItem',
            icon: {
              asset: 'PlusCircle',
              style: 'Blue',
            },
            text: {
              type: 'Subtitle2',
              style: 'Brand',
              size: 'Medium',
              align: 'Left',
              value: 'Add Unit',
            },
          },
        },
      ],
    },
    addLesson: {
      type: 'AddItem',
      icon: {
        asset: 'PlusCircle',
        style: 'Blue',
      },
      text: {
        type: 'Subtitle2',
        style: 'Brand',
        size: 'Medium',
        align: 'Left',
        value: 'Add Lesson',
      },
    },
  } as EditCourseMenuProps,
  page: {
    courseEditBlock: {
      blockHeader: {
        type: 'Default',
        text: {
          type: 'Title1',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Course Details',
        },
      },
      name: {
        state: 'Default',
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Course name',
        },
        input: {
          style: 'Grey',
          textPlaceholder: 'Input text',
        },
      },
      uploadImage: {
        state: 'Default',
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Course image',
        },
        uploadButton: {
          icon: {
            asset: 'UploadImage',
            style: 'Blue',
          },
          text: {
            type: 'Paragraph2',
            style: 'Grey',
            size: 'Medium',
            align: 'Left',
            value: 'Upload picture',
          },
        },
      },
      description: {
        state: 'Default',
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Course description',
        },
        textArea: {
          textPlaceholder: 'Text area text',
        },
      },
      tagSectionList: {
        tagSectionItems: [
          {
            sectionHeader: {
              state: 'WithDivider',
              text: {
                type: 'Title3',
                style: 'Dark',
                size: 'Medium',
                align: 'Left',
                value: 'Tag group',
              },
              divider: {
                style: 'Horizontal',
              },
            },
            tagTypeList: {
              chipsFields: [
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
              ],
            },
          },
          {
            sectionHeader: {
              state: 'WithDivider',
              text: {
                type: 'Title3',
                style: 'Dark',
                size: 'Medium',
                align: 'Left',
                value: 'Tag group',
              },
              divider: {
                style: 'Horizontal',
              },
            },
            tagTypeList: {
              chipsFields: [
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
              ],
            },
          },
        ],
      },
      htmlEditor: {
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'HTML editor',
        },
      },
      buttonSection: {
        state: 'WithDeleteButton',
        divider: {
          style: 'Horizontal',
        },
        buttonGroup: {
          primary: {
            type: 'TextButton',
            size: 'Large',
            fill: 'Colour',
            colour: 'Brand',
            text: {
              type: 'Subtitle1',
              style: 'White',
              size: 'Medium',
              align: 'Center',
              value: 'Save',
            },
          },
          secondary: {
            type: 'TextButton',
            size: 'Large',
            fill: 'White',
            colour: 'Brand',
            text: {
              type: 'Subtitle1',
              style: 'Brand',
              size: 'Medium',
              align: 'Center',
              value: 'Discard',
            },
          },
        },
        tertiary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Danger',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Delete',
          },
        },
      },
    },
  } as CourseEditPageProps,
};

export type CourseEditLayoutProps = {
  courseHeader?: CourseHeaderProps;
  editCourseMenu?: EditCourseMenuProps;
  courseEditPage?: CourseEditPageProps;
  confirmationModal?: ConfirmationModalProps;
  className?: string;
  loading?: boolean;
  error?: Error;
};

const CourseEditLayout: React.FC<CourseEditLayoutProps> = ({
  courseHeader,
  editCourseMenu,
  courseEditPage,
  confirmationModal,
  className,
  loading,
}) => {
  let contentView;
  if (loading) {
    contentView = (
      <div className={styles.spinnerContainer}>
        <Spinner animation='border' />
      </div>
    );
  } else {
    contentView = (
      <div className={styles.content}>
        <CourseEditPage
          className={styles.page}
          {...courseEditPage} />
      </div>
    );
  }

  return (
    <div className={cx(styles.courseEditLayout, className)}>
      <CourseHeader
        className={styles.courseHeader}
        {...courseHeader} />
      <div className={styles.body}>
        <EditCourseMenu
          className={styles.editCourseMenu}
          {...editCourseMenu} />
        {contentView}
      </div>
      <ConfirmationModal {...confirmationModal} />
    </div>
  );
};

CourseEditLayout.defaultProps = defaultProps;

export default CourseEditLayout;
