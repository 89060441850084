import React from 'react';
import cx from 'classnames';

import styles from './UserIcon.module.scss';

import UserIconAsset from '../../../resources/icons/UserIcon.svg';

export type UserIconTypeType = 'Initials' | 'Image';

export const defaultProps = {
  type: 'Image' as UserIconTypeType,
  background: 'BackgroundAsset',
  value: 'TR',
  userIcon: UserIconAsset,
};

export type UserIconProps = {
  type?: UserIconTypeType;
  background?: string;
  backgroundAlt?: string;
  value?: string;
  className?: string;
  userIcon?: string;
  userIconAlt?: string;
};

const UserIcon: React.FC<UserIconProps> = ({
  type,
  background,
  backgroundAlt,
  value,
  className,
  userIcon,
  userIconAlt,
}) => {

  const currentStyle = styles[`userIcon${type}`];

  let backgroundView;
  let userIconView;
  let valueView;
  
  switch (type) {
    case 'Initials':
      backgroundView = (
        <img
          className={styles.background}
          alt={backgroundAlt}
          src={background} />
      );
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'Image':
      userIconView = (
        <img
          className={styles.userIcon}
          alt={userIconAlt}
          src={userIcon} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {backgroundView}
      {userIconView}
      {valueView}
    </div>
  );
};

UserIcon.defaultProps = defaultProps;

export default UserIcon;
