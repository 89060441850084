import { useCallback } from 'react';
import axios from 'axios';
import useUpdate from '../../lib/api/useUpdate';
import { completeMultipartUpload, createMultipartUpload } from './api';
import { FileMetadata, MediaUploadType, UseMultipartUploadResult } from './types';

const PART_SIZE = 1024 * 1024 * 200; // 200MB

const useMultipartUpload = (mediaType: MediaUploadType): UseMultipartUploadResult => {
  const post = useCallback(async (
    file: File,
  ): Promise<FileMetadata> => {
    const { size, name, type } = file;

    const {
      uuid, key, signedPartUrls, uploadId,
    } = await createMultipartUpload({
      fileMimeType: type,
      fileSize: size,
      fileName: name,
      isPublic: false,
      parts: Math.ceil(size / PART_SIZE),
    }, mediaType);

    const keys = Object.keys(signedPartUrls);
    const completedParts = [];

    for (let i = 0; i < keys.length; i += 1) {
      const index = Number(keys[i]);
      const start = index * PART_SIZE;
      const end = (index + 1) * PART_SIZE;
      const blob = index < keys.length
        ? file.slice(start, end)
        : file.slice(start);

      const part = await axios.put(signedPartUrls[index], blob);
      completedParts.push({ ETag: part.headers.etag, PartNumber: i + 1 });
    }

    return completeMultipartUpload({
      uuid, key, completedParts, uploadId,
    }, mediaType);
  }, []);
  return useUpdate(post);
};

export default useMultipartUpload;
