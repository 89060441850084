import React from 'react';
import { LocaleProvider } from './modules/locale';
import { AuthProvider } from './modules/auth';
import ErrorProvider from './components/organisms/ModalError/context';
import ConfirmationContextProvider from './components/organisms/ConfirmationModal/context';

const AppProvider: React.FC<{}> = ({ children }) => (
  <LocaleProvider>
    <AuthProvider>
      <ErrorProvider>
        <ConfirmationContextProvider>
          {children}
        </ConfirmationContextProvider>
      </ErrorProvider>
    </AuthProvider>
  </LocaleProvider>
);

export default AppProvider;
