import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Lesson } from '../../../modules/lesson/types';
import useDeleteUnit from '../../../modules/unit/useDeleteUnit';
import useUpdateUnit from '../../../modules/unit/useUpdateUnit';
import useUploadImage from '../../../modules/common/useUploadImage';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import { ErrorContext } from '../../organisms/ModalError/context';
import { VideoEditBlockProps } from './VideoEditBlock';
import { VideoEditBlockPresenterProps } from './VideoEditBlock.presenter';
import useMultipartUpload from '../../../modules/common/useMultipartUpload';
import { ConfirmationContext } from '../../organisms/ConfirmationModal/context';

const withInteractor = (
  Presenter: React.FC<VideoEditBlockPresenterProps>,
): React.FC<VideoEditBlockProps> => {
  const Interactor: React.FC<VideoEditBlockProps> = (props) => {
    const { setError } = useContext(ErrorContext);
    const { setConfirmation } = useContext(ConfirmationContext);
    const { courseState } = useContext(CourseDetailsContext);

    const { lessonId, unitId } = useParams<{ lessonId: string; unitId: string }>();
    const [updateUnitState, updateUnit] = useUpdateUnit(Number(unitId));

    const [uploadVideoFileState, uploadVideoFile] = useMultipartUpload('videos');

    const [
      { loading: uploadImageLoading, error: uploadImageError }, uploadImage,
    ] = useUploadImage();

    const lesson: Lesson | null = courseState.data?.lessons.find((item) => {
      return `${item.id}` === lessonId;
    }) || null;

    const [
      { loading: deleteLoading, error: deleteError }, deleteUnit,
    ] = useDeleteUnit();

    const loading = courseState.loading
    || updateUnitState.loading
    || deleteLoading
    || uploadImageLoading
    || uploadVideoFileState.loading;

    const error = courseState.error || updateUnitState.error || deleteError || uploadImageError;

    return (
      <Presenter
        {...props}
        data={lesson}
        loading={loading}
        error={error}
        setError={setError}
        setConfirmation={setConfirmation}
        uploadImage={uploadImage}
        updateUnit={updateUnit}
        deleteUnit={deleteUnit}
        refetchCourse={courseState.refetch}
        uploadingVideoFile={uploadVideoFileState.loading}
        uploadVideoFile={uploadVideoFile}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
