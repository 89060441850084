import React from 'react';
import cx from 'classnames';

import styles from './MainMenuHeader.module.scss';

import Text, { TextProps } from '../Text';

import AppIconAsset from '../../../resources/icons/AppIcon.svg';

export const defaultProps = {
  appIcon: AppIconAsset,
  text: {
    type: 'Subtitle1',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Vericent LMS',
  } as TextProps,
};

export type MainMenuHeaderProps = {
  appIcon?: string;
  appIconAlt?: string;
  text?: TextProps;
  className?: string;
};

const MainMenuHeader: React.FC<MainMenuHeaderProps> = ({
  appIcon,
  appIconAlt,
  text,
  className,
}) => {
  return (
    <div className={cx(styles.mainMenuHeader, className)}>
      <img
        className={styles.appIcon}
        alt={appIconAlt}
        // src={appIcon} 
        />
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
};

MainMenuHeader.defaultProps = defaultProps;

export default MainMenuHeader;
