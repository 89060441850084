import React from 'react';
import cx from 'classnames';
import styles from './MenuLessonItem.module.scss';

import MenuLesson, { MenuLessonProps } from '../../atoms/MenuLesson';
import MenuUnitList, { MenuUnitListProps } from '../MenuUnitList';
import { MenuUnitProps } from '../../atoms/MenuUnit';
import { Lesson } from '../../../modules/lesson/types';
import MenuUnitDropdown from '../MenuUnitDropdown';
import useDragAndDrop from '../../../lib/hooks/useDragAndDrop';

export type MenuLessonItemStateType = 'Collapsed' | 'Expanded';
export type MenuLessonItemTypeType = 'Default' | 'Selected';

export const defaultProps = {
  state: 'Expanded' as MenuLessonItemStateType,
  type: 'Selected' as MenuLessonItemTypeType,
  menuLesson: {
    type: 'Selected',
    state: 'Expanded',
    text: {
      type: 'Paragraph3',
      style: 'Danger',
      size: 'Small',
      align: 'Left',
      value: 'Lesson 1',
    },
    selectedExpandedIcon: {
      asset: 'ChevronDown',
      style: 'Brand',
    },
  } as MenuLessonProps,
  menuUnitList: {
    menuUnits: [
      {
        type: 'Default',
        icon: {
          asset: 'Video',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Video',
        },
      },
      {
        type: 'Default',
        icon: {
          asset: 'Video',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Video',
        },
      },
      {
        type: 'Default',
        icon: {
          asset: 'File',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Quiz',
        },
      },
    ],
  } as MenuUnitListProps,
  addUnit: {
    type: 'AddItem',
    icon: {
      asset: 'PlusCircle',
      style: 'Blue',
    },
    text: {
      type: 'Subtitle2',
      style: 'Brand',
      size: 'Medium',
      align: 'Left',
      value: 'Add Unit',
    },
  } as MenuUnitProps,
};

interface DragItem {
  index: number;
  id: number;
  type: string;
}

export type MenuLessonItemProps = {
  state?: MenuLessonItemStateType;
  type?: MenuLessonItemTypeType;
  menuLesson?: MenuLessonProps;
  className?: string;
  menuUnitList?: MenuUnitListProps;
  addUnitList?: MenuUnitListProps;
  addUnit?: MenuUnitProps;
  lesson: Lesson;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  id?: number;
  index?: number;
};

const MenuLessonItem: React.FC<MenuLessonItemProps> = ({
  state,
  type,
  menuLesson,
  className,
  menuUnitList,
  addUnitList,
  onDrag,
  onDrop,
  index,
  id,
}) => {
  const { ref, isDragging, handlerId } = useDragAndDrop<HTMLDivElement>({
    id, index, onDrag, onDrop, type: 'MenuLesson', direction: 'vertical',
  });
  const opacity = isDragging ? 0 : 1;

  const currentStyle = styles[`menuLessonItem${state}${type}`];

  const menuLessonView = (
    <MenuLesson
      className={styles.menuLesson}
      {...menuLesson} />
  );

  let menuUnitListView;
  let addUnitView;

  switch (`${state}${type}`) {
    case 'CollapsedDefault':
      break;
    case 'ExpandedDefault':
      menuUnitListView = (
        <MenuUnitList
          className={styles.menuUnitList}
          {...menuUnitList} />
      );
      addUnitView = (
        <MenuUnitDropdown menuUnitList={addUnitList} />
      );
      break;
    case 'CollapsedSelected':
      break;
    case 'ExpandedSelected':
      menuUnitListView = (
        <MenuUnitList
          className={styles.menuUnitList}
          {...menuUnitList} />
      );
      addUnitView = (
        <MenuUnitDropdown menuUnitList={addUnitList} />
      );
      break;
    default:
      break;
  }

  return (
    <div
      ref={ref}
      className={cx(currentStyle, className)}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      {menuLessonView}
      {menuUnitListView}
      {addUnitView}
    </div>
  );
};

MenuLessonItem.defaultProps = defaultProps;

export default MenuLessonItem;
