import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntityPayload } from '../common/types';
import { reorderLesson } from './api';
import { UseReorderLessonResult } from './types';

const useReorderLesson = (): UseReorderLessonResult => {
  const onReorder = useCallback((
    payload: ReorderEntityPayload,
  ): Promise<void> => reorderLesson(payload), []);
  return usePatch(onReorder);
};

export default useReorderLesson;
