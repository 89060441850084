import axios from 'axios';

const instance = axios.create({ withCredentials: true });

const { isAxiosError } = axios;
export {
  isAxiosError,
};

export default instance;
