import React from 'react';
import cx from 'classnames';

import styles from './Header.module.scss';

import Logo, { LogoProps } from '../../atoms/Logo';
import HeaderContent, { HeaderContentProps } from '../../molecules/HeaderContent';

export const defaultProps = {
  logo: {
  } as LogoProps,
  headerContent: {
    appsIcon: {
      asset: 'MoreApps',
      style: 'Dark',
    },
    notificationIcon: {
      asset: 'BellDot',
      style: 'Dark',
    },
    userProfile: {
      userIcon: {
        type: 'Image',
      },
      arrowIcon: {
        asset: 'ChevronDown',
        style: 'Dark',
      },
    },
  } as HeaderContentProps,
};

export type HeaderProps = {
  logo?: LogoProps;
  headerContent?: HeaderContentProps;
  className?: string;
};

const Header: React.FC<HeaderProps> = ({
  logo,
  headerContent,
  className,
}) => {
  return (
    <div className={cx(styles.header, className)}>
      <Logo
        className={styles.logo}
        {...logo} />
      <HeaderContent
        className={styles.headerContent}
        {...headerContent} />
    </div>
  );
};

Header.defaultProps = defaultProps;

export default Header;
