import { useCallback } from 'react';
import update from 'immutability-helper';

const useHandleDrag = <T>(
  state: T[], setState: React.Dispatch<React.SetStateAction<T[]>>,
): (
    dragIndex: number, hoverIndex: number
  ) => void => {
  return useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = state[dragIndex];
      if (!dragCard) return;
      setState(
        update(state, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        }),
      );
    },
    [setState, state],
  );
};

export default useHandleDrag;
