import React, { useEffect, useState } from 'react';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { useTranslation } from 'react-i18next';
import { TagsEditBlockProps, defaultProps } from './TagsEditBlock';
import {
  CreateTagPayload, PartialTag, TagResponse, UpdateTagPayload,
} from '../../../modules/tags/types';
import { TagGroupItemProps, TagGroupItemStateType, defaultProps as tagGroupItemDefaultProps } from '../../organisms/TagGroupItem/TagGroupItem';
import { TagTypeItemProps, defaultProps as tagTypeItemDefaultProps } from '../../organisms/TagTypeItem/TagTypeItem';
import { groupTags } from '../../../modules/tags/utils';
import { OptionItem } from '../../molecules/InputChipsField/InputChipsField';
import { ApiResponse } from '../../../lib/api/types';
import { ContextualMenuItemProps } from '../../atoms/ContextualMenuItem';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';

type TagState = {
  id?: number;

  tagGroup?: string;
  tagType?: string;
  tagValue?: string;
  order?: number;

  editTagGroup?: string;
  editTagType?: string;
  editTagValue?: string;
};

type TagTypesState = {
  [key: string]: TagState[];
};

type TagGroupsState = {
  [key: string]: TagTypesState;
};

type EditGroupItemStates = {
  [key: string]: TagGroupItemStateType;
};

export type TagsEditBlockPresenterProps = TagsEditBlockProps & {
  loading?: boolean;
  error?: Error;
  tags: PartialTag[] | null;
  refetchTags: () => void;
  createTag: (payload: CreateTagPayload) => Promise<TagResponse>;
  updateTag: (payload: UpdateTagPayload) => Promise<TagResponse>;
  deleteTag: (id: number) => Promise<ApiResponse<void>>;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
};

const toTagGroupState = (tags: PartialTag[] | null): TagGroupsState => {
  const tagGroups = tags ? groupTags(tags) : null;
  const state: TagGroupsState = {};
  if (tagGroups) {
    Object.keys(tagGroups).forEach((tagGroup) => {
      state[tagGroup] = tagGroups[tagGroup];
    });
  }
  return state;
};

const areTagsModified = (originalTags: PartialTag[] | null, newTags: PartialTag[]): boolean => {
  return !deepEqual(originalTags, newTags);
};

const areTagGroupsModified = (
  originalGroups: TagGroupsState, newGroups: TagGroupsState,
): boolean => {
  return !deepEqual(originalGroups, newGroups);
};

const isMatchingGroupAndType = (tag: PartialTag, tagGroup: string, tagType: string): boolean => {
  return (tag.editTagGroup === tagGroup || tag.tagGroup === tagGroup)
    && (tag.editTagType === tagType || tag.tagType === tagType);
};

const withPresenter = (
  View: React.FC<TagsEditBlockProps>,
): React.FC<TagsEditBlockPresenterProps> => {
  const Presenter: React.FC<TagsEditBlockPresenterProps> = (props) => {
    const {
      tags, setError, refetchTags, createTag, updateTag, deleteTag, error,
    } = props;

    const [editTags, setEditTags] = useState<PartialTag[]>([]);
    const [tagGroupsState, setTagGroupsState] = useState<TagGroupsState>({});
    const [editGroupState, setEditGroupStates] = useState<EditGroupItemStates>({});
    const [deleteTagState, setDeleteTagState] = useState<number[]>([]);

    const areTagsChanged = areTagsModified(tags, editTags);
    const areTagGroupsChanged = areTagGroupsModified({ ...toTagGroupState(tags) }, tagGroupsState);
    const areChanges = areTagsChanged || areTagGroupsChanged;

    const { t } = useTranslation();

    useEffect(() => {
      if (error) {
        setError({
          error,
          description: error?.message,
        });
      }
    }, [error, setError]);

    useEffect(() => {
      setEditTags(tags ? [...tags] : []);
    }, [tags]);

    useEffect(() => {
      const newTagGroupsState = { ...toTagGroupState(editTags) };
      setTagGroupsState(newTagGroupsState);
    }, [editTags]);

    const handleUpdateTags = async (): Promise<void> => {
      try {
        for (let i = 0; i < editTags.length; i += 1) {
          const editTag = editTags[i];
          if (editTag.id !== undefined) {
            if (editTag.editTagGroup !== undefined
              || editTag.editTagType !== undefined
              || editTag.editTagValue !== undefined) {
              await updateTag({
                id: editTag.id,
                tagGroup: editTag.editTagGroup,
                tagType: editTag.editTagType,
                tagValue: editTag.editTagValue,
              });
            }
          } else {
            await createTag({
              tagGroup: editTag.editTagGroup || '',
              tagType: editTag.editTagType || '',
              tagValue: editTag.editTagValue || '',
            });
          }
        }
        if (tags) {
          const editTagMap: Record<number, boolean> = {};
          editTags.forEach((tag) => {
            if (tag.id !== undefined) {
              editTagMap[tag.id] = true;
            }
          });
          for (let i = 0; i < tags.length; i += 1) {
            const tag = tags[i];
            if (tag.id !== undefined && !editTagMap[tag.id]) {
              await deleteTag(tag.id);
            }
          }
        }
        if (deleteTagState.length > 0) {
          for (let i = 0; i < deleteTagState.length; i += 1) {
            await deleteTag(deleteTagState[i]);
          }
          setDeleteTagState([]);
        }

        refetchTags();
        setEditGroupStates({});
        setError(undefined);
      } catch (err) {
        // Dispatch error modal
        setError({
          error: err,
          description: t('error.modal.update_course.description'),
          primaryButton: {
            text: t('error.modal.update_course.button.primary'),
            onClicked: handleUpdateTags,
          },
        });
      }
    };

    // Reset course/image state
    const discardChanges = (): void => {
      setTagGroupsState({ ...toTagGroupState(tags) });
      setEditTags(tags ? [...tags] : []);
      setDeleteTagState([]);
    };

    const updateTagGroupEditState = (tagGroup: string, editTagGroup?: string): void => {
      const updatedEditState: EditGroupItemStates = {
        [tagGroup]: 'Edit',
      };
      if (editTagGroup !== null && editTagGroup !== undefined) {
        updatedEditState[editTagGroup] = 'Edit';
      }
      setEditGroupStates(updatedEditState);
    };

    const addNewTagGroup = (): void => {
      let index = Object.keys(tagGroupsState).length;
      let newTagGroupName = `Tag Group ${index}`;
      // find the next available tag group name
      while (tagGroupsState[newTagGroupName]) {
        index += 1;
        newTagGroupName = `Tag Group ${index}`;
      }

      const updatedTags = editTags ? [...editTags] : [];
      updatedTags.push({
        editTagGroup: newTagGroupName,
        editTagType: 'Tag Type 0',
        editTagValue: '',
      });

      setEditTags(updatedTags);
      updateTagGroupEditState(newTagGroupName);
    };

    const addNewTagType = (tagGroup: string): void => {
      const tagGroupState = tagGroupsState[tagGroup];

      let index = Object.keys(tagGroupState).length;
      let newTagTypeName = `Tag Type ${index}`;

      const updatedTags = cloneDeep(editTags);
      updatedTags.push({
        editTagGroup: tagGroup,
        editTagType: newTagTypeName,
        editTagValue: '',
      });

      setEditTags(updatedTags);
    };

    const removeTagGroup = (tagGroup: string): void => {
      const updateTagGroupsState = cloneDeep(tagGroupsState);
      const currentTagGroup = { ...updateTagGroupsState[tagGroup] };
      const tagsToDelete: number[] = [];
      Object.values(currentTagGroup).forEach((currentTagType) => {
        currentTagType.forEach((tag) => {
          if (tag.id) {
            tagsToDelete.push(tag.id);
          }
        });
      });
      setDeleteTagState(tagsToDelete);
      delete updateTagGroupsState[tagGroup];
      setTagGroupsState(updateTagGroupsState);
    };

    const removeTagGroupType = (tagGroup: string, tagType: string): void => {
      const updateTagGroupState = cloneDeep(tagGroupsState);
      // get the current tag type
      const currentTagGroupType: TagState[] = { ...updateTagGroupState[tagGroup][tagType] };
      const tagsToDelete: number[] = [];
      // adds the tag ids to the list of tags to be deleted
      Object.values(currentTagGroupType).forEach((tag) => {
        if (tag.id) {
          tagsToDelete.push(tag.id);
        }
      });
      // sets the tags to delete
      setDeleteTagState(tagsToDelete);
      // gets the current tags
      const currentTags = cloneDeep(editTags);
      // gets all the tags that aren't part of the same group and type
      const updatedTags = (currentTags.filter((tag) => { return !isMatchingGroupAndType(tag, tagGroup, tagType)}));
      // if all the tag groups are removed, add a new tag type
      if (Object.keys(updateTagGroupState[tagGroup]).length === 1){
        updatedTags.push({
          editTagGroup: tagGroup,
          editTagType: 'Tag Type 0',
          editTagValue: '',
        });
      }
      setEditTags(updatedTags);
    }

    const updateTagGroupName = (tagGroup: string, newName: string): void => {
      const updatedTags = cloneDeep(editTags);
      for (let i = 0; i < updatedTags.length; i += 1) {
        const tag = updatedTags[i];
        if ((tag.editTagGroup === tagGroup || tag.tagGroup === tagGroup)) {
          tag.editTagGroup = newName;
          updatedTags[i] = tag;
        }
      }
      setEditTags(updatedTags);
      updateTagGroupEditState(tagGroup, newName);
    };

    const updateGroupTagTypeName = (tagGroup: string, tagType: string, newName: string): void => {
      const updatedTags = cloneDeep(editTags);
      for (let i = 0; i < updatedTags.length; i += 1) {
        const tag = updatedTags[i];
        if ((tag.editTagGroup === tagGroup || tag.tagGroup === tagGroup)
          && (tag.editTagType === tagType || tag.tagType === tagType)) {
          tag.editTagType = newName;
          updatedTags[i] = tag;
        }
      }
      setEditTags(updatedTags);
    };

    const updateGroupTagTypeValues = (
      tagGroup: string, tagType: string, options: OptionItem[],
    ): void => {
      const updatedTags = cloneDeep(editTags);

      let orderedUpdatedTags: PartialTag[] = [];

      // gest all the tags for the same group and type
      const sameGroupAndTypeTags = (updatedTags.filter((tag) => { return isMatchingGroupAndType(tag, tagGroup, tagType)}));
      // save the lengths for calcations on what to do.
      const sameGroupAndTypeTagsLength = (sameGroupAndTypeTags.length);
      const optionsLength = options.length;
      
      // if the user adds or deletes a tag
      if (sameGroupAndTypeTagsLength === optionsLength || optionsLength > sameGroupAndTypeTagsLength) {
        // user adds a tag
        // if the first option is being added
        if (optionsLength === 1) {
          // gets the index of the tag to update
          const indexOfUpdate = updatedTags.indexOf(sameGroupAndTypeTags[0]);
          // if there is an empty tag, update the edit the tag value
          if (indexOfUpdate !== -1){
            const updatedTagsArray = [...updatedTags];
            // sets the empty tag value to the inputted value
            updatedTagsArray[indexOfUpdate] = {
              ...updatedTagsArray[indexOfUpdate], 
              editTagValue: options[0].value,
            }
            orderedUpdatedTags = updatedTagsArray;
          }
        } else {
          // gets all the tags from options that aren't in the sameGroupAndTypeTags array;
          const tagToAdd = options.find(({ value: id1 }) => !sameGroupAndTypeTags.some(({ tagValue: id2, editTagValue: id3 }) => id2 === id1 || id3 === id1));
          // if there is a tag value
          if(tagToAdd?.value){
            // adds the new tag
            orderedUpdatedTags = [...updatedTags, {
              editTagGroup: tagGroup,
              editTagType: tagType,
              editTagValue: tagToAdd.value
            }];
          } else {
            // if there was no new tag, sets to the previous tags
            orderedUpdatedTags = [...updatedTags];
          }
        }

      } else if (optionsLength < sameGroupAndTypeTagsLength) {
        // user deletes a tag
        // if this the last tag is removed
        if (optionsLength === 0) {
          // index of the last tag in the type and group
          const indexOfUpdate = updatedTags.indexOf(sameGroupAndTypeTags[0]);
          // if there is a tag
          if (indexOfUpdate !== -1) {
            const updatedTagsArray = [...updatedTags];
            // sets the tag to the empty value to the field can still be displayed
            updatedTagsArray[indexOfUpdate] = {
              ...updatedTagsArray[indexOfUpdate],
              editTagValue: '',
              tagValue: '',
            }
            orderedUpdatedTags = updatedTagsArray;
          }
        } else {
          // gets the tag that's not in the options (the one to delete)
          const tagToDelete = sameGroupAndTypeTags.find(({ tagValue: id1, editTagValue: id3}) => !options.some(({value: id2}) => id2 === id1 || id2 === id3));

          // gets the index from tags array
          const indexOfDelete = updatedTags.indexOf(tagToDelete ? tagToDelete : {});

          // if there is a tag to delete
          if( indexOfDelete !== -1){
            const updatedTagsArray = [...updatedTags];
            // removes the tag from the array
            updatedTagsArray.splice(indexOfDelete, 1);
            orderedUpdatedTags = updatedTagsArray;
          }
        }
      }

      // Sets the TagGroupState so you don't need to click twice to remove a tag
      const newTagGroupsState = { ...toTagGroupState(orderedUpdatedTags) };
      setTagGroupsState(newTagGroupsState);
      // sets the tag state to the new tags
      setEditTags(orderedUpdatedTags);
    };

    const tagGroupItemList: TagGroupItemProps[] = Object.keys(tagGroupsState)
      .map((tagGroup): TagGroupItemProps => {
        const tagGroupState = tagGroupsState[tagGroup];

        const tagTypeItems: TagTypeItemProps[] = Object.keys(tagGroupState)
          .map((tagType): TagTypeItemProps => {
            const tagValues: OptionItem[] = tagGroupState[tagType]
              .filter((tag) => tag.editTagValue || tag.tagValue)
              .map((tag): OptionItem => {
                return {
                  id: tag.id,
                  value: (tag.editTagValue || tag.tagValue) as string,
                };
              });
            return {
              ...tagTypeItemDefaultProps,
              tagTypeField: {
                ...tagTypeItemDefaultProps.tagTypeField,
                input: {
                  ...tagTypeItemDefaultProps.tagTypeField.input,
                  textValue: tagType || '',
                  onTextChanged: (event): void => {
                    updateGroupTagTypeName(tagGroup, tagType, event.currentTarget.value);
                  },
                },
              },
              deleteButton: {
                ...tagTypeItemDefaultProps.deleteButton,
                onButtonClicked: () => removeTagGroupType(tagGroup, tagType),
              },
              tagValueField: {
                ...tagTypeItemDefaultProps.tagValueField,
                allowNew: true,
                multiSelect: true,
                options: tagValues,
                selectedOptions: tagValues,
                onOptionSelected: (selected): void => {
                  updateGroupTagTypeValues(tagGroup, tagType, selected);
                },
              },
            };
          });

        const tagGroupItemContextMenuItems: ContextualMenuItemProps[] = [
          {
            style: 'Danger',
            text: {
              type: 'Paragraph3',
              style: 'Danger',
              size: 'Small',
              align: 'Left',
              value: 'Delete',
            },
            onContextualMenuItemClicked: (): void => {
              removeTagGroup(tagGroup);
            },
          },
        ];

        return {
          ...tagGroupItemDefaultProps,
          state: editGroupState[tagGroup] || 'View',
          tagGroup: {
            ...tagGroupItemDefaultProps.tagGroup,
            value: tagGroup || '',
          },
          buttonGroup: {
            ...tagGroupItemDefaultProps.buttonGroup,
            primary: {
              ...tagGroupItemDefaultProps.buttonGroup.primary,
              onButtonClicked: (): void => {
                updateTagGroupEditState(tagGroup);
              },
            },
            secondary: {
              ...tagGroupItemDefaultProps.buttonGroup.secondary,
            },
          },
          tagGroupInput: {
            ...tagGroupItemDefaultProps.tagGroupInput,
            input: {
              ...tagGroupItemDefaultProps.tagGroupInput.input,
              textValue: tagGroup || '',
              onTextChanged: (event): void => {
                updateTagGroupName(tagGroup, event.currentTarget.value);
              },
            },
          },
          tagTypeItemList: {
            ...tagGroupItemDefaultProps.tagTypeItemList,
            tagTypeItems,
          },
          addTagType: {
            ...tagGroupItemDefaultProps.addTagType,
            onButtonClicked: (): void => {
              addNewTagType(tagGroup);
            },
          },
          contextMenu: {
            ...tagGroupItemDefaultProps.contextMenu,
            contextualMenuItemList: {
              ...tagGroupItemDefaultProps.contextMenu.contextualMenuItemList,
              contextualMenuItems: tagGroupItemContextMenuItems,
            },
          },
        };
      });

    const viewProps: TagsEditBlockProps = {
      ...defaultProps,
      ...props,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: 'Tags',
        },
      },
      tagGroupItemList: {
        ...defaultProps.tagGroupItemList,
        tagGroupItems: tagGroupItemList,
      },
      button: {
        ...defaultProps.button,
        onButtonClicked: addNewTagGroup,
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateTags,
            disabled: !areChanges,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: !areChanges,
          },
        },
      },
    };

    return <View {...viewProps} />;
  };

  return Presenter;
};

export default withPresenter;
