import React from 'react';
import cx from 'classnames';

import styles from './QuizEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import InputWithStepperField, { InputWithStepperFieldProps } from '../../molecules/InputWithStepperField';
import Text, { TextProps } from '../../atoms/Text';
import QuestionItemList, { QuestionItemListProps } from '../../organisms/QuestionItemList';
import Button, { ButtonProps } from '../../atoms/Button';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';

export const defaultProps = {
  blockHeader: {
    type: 'Default',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Quiz',
    },
  } as BlockHeaderProps,
  name: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Quiz name',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Quiz 1',
    },
  } as InputFieldProps,
  estimatedTime: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Estimated time (In minutes)',
    },
    inputWithStepper: {
      input: {
        style: 'Grey',
        textPlaceholder: '00',
      },
      stepDown: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronUp',
          style: 'Dark',
        },
      },
      stepUp: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
    },
  } as InputWithStepperFieldProps,
  text: {
    type: 'Title2',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Questions',
  } as TextProps,
  questionItemList: {
    questionItems: [
        {
          state: 'View',
          divider: {
            style: 'Horizontal',
          },
          question: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Question goes here',
          },
          buttonGroup: {
            primary: {
              type: 'TextButton',
              size: 'Large',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Subtitle1',
                style: 'Brand',
                size: 'Medium',
                align: 'Center',
                value: 'Edit',
              },
            },
            secondary: {
              type: 'IconButton',
              size: 'Large',
              fill: 'White',
              colour: 'Basic',
              icon: {
                asset: 'MoreVertical',
                style: 'Blue',
              },
            },
          },
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Options',
          },
          answerOptionList: {
            answerOptions: [
                {
                  state: 'View',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
                {
                  state: 'View',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
                {
                  state: 'View',
                  checkbox: {
                    state: 'Checked',
                    icon: {
                      asset: 'SquareCheck',
                      style: 'Blue',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
            ],
          },
        },
        {
          state: 'Edit',
          divider: {
            style: 'Horizontal',
          },
          questionInput: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Question 2',
            },
            input: {
              style: 'White',
              textPlaceholder: 'Question goes here',
            },
          },
          selectField: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Answer type',
            },
            select: {
              text: {
                type: 'Paragraph1',
                style: 'Grey',
                size: 'Large',
                align: 'Left',
                value: 'Selected option',
              },
              icon: {
                asset: 'ChevronDown',
                style: 'Disabled',
              },
            },
          },
          addQuestionDescriptionButton: {
            type: 'IconTextButton',
            size: 'Medium',
            fill: 'White',
            colour: 'Brand',
            icon: {
              asset: 'Plus',
              style: 'Blue',
            },
            text: {
              type: 'Paragraph3',
              style: 'Brand',
              size: 'Small',
              align: 'Center',
              value: 'Add question description',
            },
          },
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Options',
          },
          answerOptionList: {
            answerOptions: [
                {
                  state: 'Edit',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  input: {
                    style: 'White',
                    textPlaceholder: 'Input text',
                  },
                  button: {
                    type: 'IconButton',
                    size: 'Medium',
                    fill: 'White',
                    colour: 'Danger',
                    icon: {
                      asset: 'MoreVertical',
                      style: 'Dark',
                    },
                  },
                },
                {
                  state: 'Edit',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  input: {
                    style: 'White',
                    textPlaceholder: 'Input text',
                  },
                  button: {
                    type: 'IconButton',
                    size: 'Medium',
                    fill: 'White',
                    colour: 'Danger',
                    icon: {
                      asset: 'MoreVertical',
                      style: 'Dark',
                    },
                  },
                },
                {
                  state: 'Edit',
                  checkbox: {
                    state: 'Checked',
                    icon: {
                      asset: 'SquareCheck',
                      style: 'Blue',
                    },
                  },
                  input: {
                    style: 'White',
                    textPlaceholder: 'Input text',
                  },
                  button: {
                    type: 'IconButton',
                    size: 'Medium',
                    fill: 'White',
                    colour: 'Danger',
                    icon: {
                      asset: 'MoreVertical',
                      style: 'Dark',
                    },
                  },
                },
            ],
          },
          addOptionButton: {
            type: 'IconTextButton',
            size: 'Medium',
            fill: 'White',
            colour: 'Brand',
            icon: {
              asset: 'Plus',
              style: 'Blue',
            },
            text: {
              type: 'Paragraph3',
              style: 'Brand',
              size: 'Small',
              align: 'Center',
              value: 'Add option',
            },
          },
          validationMessage: {
            state: 'Collapsed',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Show validation messages',
            },
            button: {
              type: 'IconButton',
              size: 'Small',
              fill: 'White',
              colour: 'Basic',
              icon: {
                asset: 'CircleChevronDown',
                style: 'Grey',
              },
            },
          },
        },
        {
          state: 'View',
          divider: {
            style: 'Horizontal',
          },
          question: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Question goes here',
          },
          buttonGroup: {
            primary: {
              type: 'TextButton',
              size: 'Large',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Subtitle1',
                style: 'Brand',
                size: 'Medium',
                align: 'Center',
                value: 'Edit',
              },
            },
            secondary: {
              type: 'IconButton',
              size: 'Large',
              fill: 'White',
              colour: 'Basic',
              icon: {
                asset: 'MoreVertical',
                style: 'Blue',
              },
            },
          },
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Options',
          },
          answerOptionList: {
            answerOptions: [
                {
                  state: 'View',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
                {
                  state: 'View',
                  checkbox: {
                    state: 'Unchecked',
                    icon: {
                      asset: 'Square',
                      style: 'Dark',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
                {
                  state: 'View',
                  checkbox: {
                    state: 'Checked',
                    icon: {
                      asset: 'SquareCheck',
                      style: 'Blue',
                    },
                  },
                  text: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Option',
                  },
                },
            ],
          },
        },
    ],
  } as QuestionItemListProps,
  addQuestionButton: {
    type: 'IconTextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    icon: {
      asset: 'Plus',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Add question',
    },
  } as ButtonProps,
  buttonSection: {
    state: 'WithDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
    tertiary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonSectionProps,
};

export type QuizEditBlockProps = {
  blockHeader?: BlockHeaderProps;
  name?: InputFieldProps;
  estimatedTime?: InputWithStepperFieldProps;
  text?: TextProps;
  questionItemList?: QuestionItemListProps;
  addQuestionButton?: ButtonProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
};

const QuizEditBlock: React.FC<QuizEditBlockProps> = ({
  blockHeader,
  name,
  estimatedTime,
  text,
  questionItemList,
  addQuestionButton,
  buttonSection,
  className,
}) => {
  return (
    <div className={cx(styles.quizEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <InputField
          className={styles.name}
          {...name} />
        <InputWithStepperField
          className={styles.estimatedTime}
          {...estimatedTime} />
        <Text
          className={styles.text}
          {...text} />
        <QuestionItemList
          className={styles.questionItemList}
          {...questionItemList} />
        <Button
          className={styles.addQuestionButton}
          {...addQuestionButton} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
      </div>
    </div>
  );
};

QuizEditBlock.defaultProps = defaultProps;

export default QuizEditBlock;
