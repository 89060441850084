// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntitiesPayload } from '../common/types';
import { reorderCourses } from './api';
import { UseReorderCoursesResult } from './types';

const useReorderCourses = (): UseReorderCoursesResult => {
  const onReorder = useCallback((
    payload: ReorderEntitiesPayload,
  ): Promise<void> => reorderCourses(payload), []);
  return usePatch(onReorder);
};

export default useReorderCourses;
