import React, { useContext } from 'react';
import { useParams } from 'react-router';
import useUpdateCourse from '../../../modules/course/useUpdateCourse';
import { ErrorContext } from '../../organisms/ModalError/context';
import { CourseDetailsContext, CourseEditContext } from './context';
import { CourseEditLayoutProps } from './CourseEditLayout';
import { CourseEditLayoutPresenterProps } from './CourseEditLayout.presenter';

const withInteractor = (
  Presenter: React.FC<CourseEditLayoutPresenterProps>,
): React.FC<CourseEditLayoutProps> => {
  const Interactor: React.FC<CourseEditLayoutProps> = (props) => {
    const { courseState: { loading, error, data, refetch } } = useContext(CourseDetailsContext);
    const { isEditing } = useContext(CourseEditContext);
    const { setError } = useContext(ErrorContext);

    const { courseId } = useParams<{courseId: string}>()
    const [updateCourseState, updateCourse] = useUpdateCourse(Number(courseId))
    return (
      <Presenter
        {...props}
        loading={loading}
        error={error}
        refetch={refetch}
        courseDetails={data}
        setError={setError}
        isEditing={isEditing}
        updateCourse={updateCourse}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
