import React from 'react';
import cx from 'classnames';

import styles from './ContextualMenu.module.scss';

import ContextualMenuItemList, { ContextualMenuItemListProps } from '../ContextualMenuItemList';

export const defaultProps = {
  contextualMenuItemList: {
    contextualMenuItems: [
        {
          style: 'Default',
          text: {
            type: 'Paragraph3',
            style: 'Dark',
            size: 'Small',
            align: 'Left',
            value: 'Move up',
          },
        },
        {
          style: 'Default',
          text: {
            type: 'Paragraph3',
            style: 'Dark',
            size: 'Small',
            align: 'Left',
            value: 'Move down',
          },
        },
        {
          style: 'Danger',
          text: {
            type: 'Paragraph3',
            style: 'Danger',
            size: 'Small',
            align: 'Left',
            value: 'Delete question',
          },
        },
    ],
  } as ContextualMenuItemListProps,
};

export type ContextualMenuProps = {
  contextualMenuItemList?: ContextualMenuItemListProps;
  className?: string;
};

const ContextualMenu: React.FC<ContextualMenuProps> = ({
  contextualMenuItemList,
  className,
}) => {
  return (
    <div className={cx(styles.contextualMenu, className)}>
      <ContextualMenuItemList
        className={styles.contextualMenuItemList}
        {...contextualMenuItemList} />
    </div>
  );
};

ContextualMenu.defaultProps = defaultProps;

export default ContextualMenu;
