import React from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';

import styles from './ResourceUploadSection.module.scss';

import InputField, { InputFieldProps } from '../../molecules/InputField';
import UploadField, { UploadFieldProps, defaultProps as defaultUploadFieldProps } from '../../molecules/UploadField/UploadField';

export type ResourceUploadSectionTypeType = 'Default' | 'Upload';

export const defaultProps = {
  type: 'Upload' as ResourceUploadSectionTypeType,
  videoUrl: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Resource link',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputFieldProps,
  uploadField: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Upload resource',
    },
    icon: {
      asset: 'FileAdd',
      style: 'Dark',
    },
    button: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Select file',
      },
    },
  } as UploadFieldProps,
};

export type ResourceUploadSectionProps = {
  type?: ResourceUploadSectionTypeType;
  videoUrl?: InputFieldProps;
  className?: string;
  uploadField?: UploadFieldProps;
  onDeleteFile?: () => void;
  onDrop?: (files: File[]) => void;
};

const ResourceUploadSection: React.FC<ResourceUploadSectionProps> = ({
  type,
  videoUrl,
  className,
  uploadField,
  onDeleteFile,
  onDrop,
}) => {
  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    onDrop, maxFiles: 1, noClick: true, noKeyboard: true,
  });
  const currentStyle = styles[`resourceUploadSection${type}`];

  let videoUrlView;
  let uploadFieldView;

  const uploadFieldProps: UploadFieldProps = {
    ...defaultUploadFieldProps,
    ...uploadField,
    button: {
      ...defaultProps.uploadField.button,
      onButtonClicked: open,
    },
    buttonGroup: {
      primary: {
        ...defaultUploadFieldProps.buttonGroup?.primary,
        onButtonClicked: open,
      },
      secondary: {
        ...defaultUploadFieldProps.buttonGroup?.secondary,
        onButtonClicked: onDeleteFile,
      },
    },
    dropzone: {
      getInputProps,
      getRootProps,
    },
  };

  switch (type) {
    case 'Default':
      videoUrlView = (
        <InputField
          className={styles.videoUrl}
          {...videoUrl} />
      );
      break;
    case 'Upload':
      uploadFieldView = (
        <UploadField
          className={styles.uploadField}
          {...uploadFieldProps} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {videoUrlView}
      {uploadFieldView}
    </div>
  );
};

ResourceUploadSection.defaultProps = defaultProps;

export default ResourceUploadSection;
