import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { reorderAdditionalResource } from './api';
import { ReorderAdditionalResourcePayload, UseReorderAdditionalResourceResult } from './types';

const useReorderAdditionalResource = (): UseReorderAdditionalResourceResult => {
  const onReorder = useCallback((
    payload: ReorderAdditionalResourcePayload,
  ): Promise<void> => reorderAdditionalResource(payload), []);
  return usePatch(onReorder);
};

export default useReorderAdditionalResource;
