import React, { useContext } from 'react';
import { AuthContext } from '../../../modules/auth';
import { MainLayoutProps } from './MainLayout';
import { MainLayoutPresenterProps } from './MainLayout.presenter';

const withInteractor = (
  Presenter: React.FC<MainLayoutPresenterProps>,
): React.FC<MainLayoutProps> => {
  const Interactor: React.FC<MainLayoutProps> = (props) => {
    const { appDetailsState } = useContext(AuthContext);
    return <Presenter
      {...props}
      data={appDetailsState.data} />;
  };

  return Interactor;
};

export default withInteractor;
