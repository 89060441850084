// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { reorderAdditionalResources } from './api';
import { ReorderAdditionalResourcesPayload, UseReorderAdditionalResourcesResult } from './types';

const useReorderAdditionalResources = (): UseReorderAdditionalResourcesResult => {
  const onReorder = useCallback((
    payload: ReorderAdditionalResourcesPayload,
  ): Promise<void> => reorderAdditionalResources(payload), []);
  return usePatch(onReorder);
};

export default useReorderAdditionalResources;
