import React from 'react';
import cx from 'classnames';

import styles from './TagGroupItemList.module.scss';

import TagGroupItem, { TagGroupItemProps } from '../TagGroupItem';

export const defaultProps = {
  tagGroupItems: [
      {
        state: 'Edit',
        divider: {
          style: 'Horizontal',
        },
        tagGroupInput: {
          state: 'Default',
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag group name',
          },
          input: {
            style: 'White',
            textPlaceholder: 'Tag group name goes here',
          },
        },
        tagTypeItemList: {
          tagTypeItems: [
              {
                divider: {
                  style: 'Horizontal',
                },
                tagTypeField: {
                  state: 'Default',
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                  input: {
                    style: 'Grey',
                    textPlaceholder: 'Input text',
                  },
                },
                tagValueField: {
                  state: 'Default',
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag values',
                  },
                  input: {
                    style: 'Grey',
                    textPlaceholder: 'Input text',
                  },
                },
              },
              {
                divider: {
                  style: 'Horizontal',
                },
                tagTypeField: {
                  state: 'Default',
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                  input: {
                    style: 'Grey',
                    textPlaceholder: 'Input text',
                  },
                },
                tagValueField: {
                  state: 'Default',
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag values',
                  },
                  input: {
                    style: 'Grey',
                    textPlaceholder: 'Input text',
                  },
                },
              },
          ],
        },
        addTagType: {
          type: 'IconTextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          icon: {
            asset: 'Plus',
            style: 'Blue',
          },
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Add tag type',
          },
        },
      },
      {
        state: 'View',
        divider: {
          style: 'Horizontal',
        },
        tagGroup: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Tag group name',
        },
        buttonGroup: {
          primary: {
            type: 'TextButton',
            size: 'Large',
            fill: 'White',
            colour: 'Brand',
            text: {
              type: 'Subtitle1',
              style: 'Brand',
              size: 'Medium',
              align: 'Center',
              value: 'Edit',
            },
          },
          secondary: {
            type: 'IconButton',
            size: 'Large',
            fill: 'White',
            colour: 'Basic',
            icon: {
              asset: 'MoreVertical',
              style: 'Blue',
            },
          },
        },
      },
      {
        state: 'View',
        divider: {
          style: 'Horizontal',
        },
        tagGroup: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Tag group name',
        },
        buttonGroup: {
          primary: {
            type: 'TextButton',
            size: 'Large',
            fill: 'White',
            colour: 'Brand',
            text: {
              type: 'Subtitle1',
              style: 'Brand',
              size: 'Medium',
              align: 'Center',
              value: 'Edit',
            },
          },
          secondary: {
            type: 'IconButton',
            size: 'Large',
            fill: 'White',
            colour: 'Basic',
            icon: {
              asset: 'MoreVertical',
              style: 'Blue',
            },
          },
        },
      },
  ] as TagGroupItemProps[],
};

export type TagGroupItemListProps = {
  tagGroupItems?: TagGroupItemProps[];
  className?: string;
};

const TagGroupItemList: React.FC<TagGroupItemListProps> = ({
  tagGroupItems,
  className,
}) => {
  const tagGroupItemArray = tagGroupItems?.map((tagGroupItem, index) => (
    <TagGroupItem
      key={index}
      className={styles.tagGroupItem}
      {...tagGroupItem} />
  ));
  
  return (
    <div className={cx(styles.tagGroupItemList, className)}>
      {tagGroupItemArray}
    </div>
  );
};

TagGroupItemList.defaultProps = defaultProps;

export default TagGroupItemList;
