import React from 'react';
import cx from 'classnames';

import styles from './MenuLesson.module.scss';

import Text, { TextProps } from '../Text';
import Icon, { IconProps } from '../Icon';

export type MenuLessonTypeType = 'Selected' | 'Default';
export type MenuLessonStateType = 'Collapsed' | 'Expanded';
export type MenuLessonMenuLessonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'Default' as MenuLessonTypeType,
  state: 'Expanded' as MenuLessonStateType,
  text: {
    type: 'Paragraph3',
    style: 'Dark',
    size: 'Small',
    align: 'Left',
    value: 'Lesson 1',
  } as TextProps,
  selectedCollapsedIcon: {
    asset: 'ChevronUp',
    style: 'Brand',
  } as IconProps,
  selectedExpandedIcon: {
    asset: 'ChevronDown',
    style: 'Brand',
  } as IconProps,
  collapsedIcon: {
    asset: 'ChevronUp',
    style: 'Dark',
  } as IconProps,
  expandedIcon: {
    asset: 'ChevronDown',
    style: 'Dark',
  } as IconProps,
};

export type MenuLessonProps = {
  type?: MenuLessonTypeType;
  state?: MenuLessonStateType;
  menuLessonType?: MenuLessonMenuLessonTypeType;
  onMenuLessonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: TextProps;
  selectedCollapsedIcon?: IconProps;
  className?: string;
  selectedExpandedIcon?: IconProps;
  collapsedIcon?: IconProps;
  expandedIcon?: IconProps;
};

const MenuLesson: React.FC<MenuLessonProps> = ({
  type,
  state,
  menuLessonType,
  onMenuLessonClicked,
  text,
  selectedCollapsedIcon,
  className,
  selectedExpandedIcon,
  collapsedIcon,
  expandedIcon,
}) => {

  const currentStyle = styles[`menuLesson${type}${state}`];

  let selectorView;
  let contentView;
  
  switch (`${type}${state}`) {
    case 'SelectedCollapsed':
      selectorView = (
        <div className={styles.selector}/>
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.text}
            {...text} />
          <Icon
            className={styles.selectedCollapsedIcon}
            {...selectedCollapsedIcon} />
        </div>
      );
      break;
    case 'SelectedExpanded':
      selectorView = (
        <div className={styles.selector}/>
      );
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.text}
            {...text} />
          <Icon
            className={styles.selectedExpandedIcon}
            {...selectedExpandedIcon} />
        </div>
      );
      break;
    case 'DefaultCollapsed':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.text}
            {...text} />
          <Icon
            className={styles.collapsedIcon}
            {...collapsedIcon} />
        </div>
      );
      break;
    case 'DefaultExpanded':
      contentView = (
        <div className={styles.content}>
          <Text
            className={styles.text}
            {...text} />
          <Icon
            className={styles.expandedIcon}
            {...expandedIcon} />
        </div>
      );
      break;
  }

  return (
    <button
      type={menuLessonType}
      onClick={onMenuLessonClicked}
      className={cx(currentStyle, className)}>
      {selectorView}
      {contentView}
    </button>
  );
};

MenuLesson.defaultProps = defaultProps;

export default MenuLesson;
