import React from 'react';
import cx from 'classnames';

import styles from './Input.module.scss';

export type InputStyleType = 'Grey' | 'White';

export const defaultProps = {
  style: 'White' as InputStyleType,
  textPlaceholder: 'Input text',
};

export type InputProps = {
  style?: InputStyleType;
  textValue?: string;
  textPlaceholder?: string;
  onTextChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  className?: string;
};

const Input: React.FC<InputProps> = ({
  style,
  textValue,
  textPlaceholder,
  onTextChanged,
  className,
}) => {
  const currentStyle = styles[`input${style}`];

  return (
    <div className={cx(currentStyle, className)}>
      <input
        placeholder={textPlaceholder}
        value={textValue}
        onChange={onTextChanged}
        className={styles.text}/>
    </div>
  );
};

Input.defaultProps = defaultProps;

export default Input;
