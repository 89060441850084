import React from 'react';
import cx from 'classnames';

import styles from './TagGroupItem.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Text, { TextProps } from '../../atoms/Text';
import { ButtonGroupProps } from '../../molecules/ButtonGroup';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import TagTypeItemList, { TagTypeItemListProps } from '../TagTypeItemList';
import Button, { ButtonProps } from '../../atoms/Button';
import { Dropdown } from 'react-bootstrap';
import ContextualMenu, { ContextualMenuProps } from '../../molecules/ContextualMenu';

export type TagGroupItemStateType = 'View' | 'Edit';

export const defaultProps = {
  state: 'Edit' as TagGroupItemStateType,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  tagGroup: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Tag group name',
  } as TextProps,
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Edit',
      },
    },
    secondary: {
      type: 'IconButton',
      size: 'Large',
      fill: 'White',
      colour: 'Basic',
      icon: {
        asset: 'MoreVertical',
        style: 'Blue',
      },
    },
  } as ButtonGroupProps,
  tagGroupInput: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Tag group name',
    },
    input: {
      style: 'White',
      textPlaceholder: 'Tag group name goes here',
    },
  } as InputFieldProps,
  tagTypeItemList: {
    tagTypeItems: [
        {
          divider: {
            style: 'Horizontal',
          },
          tagTypeField: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag type',
            },
            input: {
              style: 'Grey',
              textPlaceholder: 'Input text',
            },
          },
          tagValueField: {
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag values',
            },
            input: {
              style: 'Grey',
              textPlaceholder: 'Input text',
            },
          },
        },
        {
          divider: {
            style: 'Horizontal',
          },
          tagTypeField: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag type',
            },
            input: {
              style: 'Grey',
              textPlaceholder: 'Input text',
            },
          },
          tagValueField: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag values',
            },
            input: {
              style: 'Grey',
              textPlaceholder: 'Input text',
            },
          },
        },
    ],
  } as TagTypeItemListProps,
  addTagType: {
    type: 'IconTextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    icon: {
      asset: 'Plus',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Add tag type',
    },
  } as ButtonProps,
  contextMenu: {
    contextualMenuItemList: {
      contextualMenuItems: [
          {
            style: 'Danger',
            text: {
              type: 'Paragraph3',
              style: 'Danger',
              size: 'Small',
              align: 'Left',
              value: 'Delete',
            },
          },
      ],
    },
  } as ContextualMenuProps,
};

export type TagGroupItemProps = {
  state?: TagGroupItemStateType;
  divider?: DividerProps;
  tagGroup?: TextProps;
  buttonGroup?: ButtonGroupProps;
  className?: string;
  tagGroupInput?: InputFieldProps;
  tagTypeItemList?: TagTypeItemListProps;
  addTagType?: ButtonProps;
  contextMenu?: ContextualMenuProps;
};

const TagGroupItem: React.FC<TagGroupItemProps> = ({
  state,
  divider,
  tagGroup,
  buttonGroup,
  className,
  tagGroupInput,
  tagTypeItemList,
  addTagType,
  contextMenu,
}) => {

  const currentStyle = styles[`tagGroupItem${state}`];

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );

  const contextMenuView = (
    <>
      <Button {...buttonGroup?.primary} />
      <Dropdown className={styles.dropDown}>
        <Dropdown.Toggle as='div'>
          <Button {...buttonGroup?.secondary} />
        </Dropdown.Toggle>
        <Dropdown.Menu align='right' className={styles.contextMenu}>
          <ContextualMenu {...contextMenu} />
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
  
  let topContentView;
  let tagGroupInputView;
  let tagTypeItemListView;
  let addTagTypeView;
  
  switch (state) {
    case 'View':
      
      topContentView = (
        <div className={styles.topContent}>
          <Text
            className={styles.tagGroup}
            {...tagGroup} />
          {contextMenuView}
        </div>
      );
      break;
    case 'Edit':
      tagGroupInputView = (
        <InputField
          className={styles.tagGroupInput}
          {...tagGroupInput} />
      );
      tagTypeItemListView = (
        <TagTypeItemList
          className={styles.tagTypeItemList}
          {...tagTypeItemList} />
      );
      addTagTypeView = (
        <Button
          className={styles.addTagType}
          {...addTagType} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {dividerView}
      {topContentView}
      {tagGroupInputView}
      {tagTypeItemListView}
      {addTagTypeView}
    </div>
  );
};

TagGroupItem.defaultProps = defaultProps;

export default TagGroupItem;
