import React from 'react';
import cx from 'classnames';

import styles from './Checkbox.module.scss';

import Icon, { IconProps } from '../Icon';

export type CheckboxStateType = 'Unchecked' | 'Checked';
export type CheckboxCheckboxTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  state: 'Unchecked' as CheckboxStateType,
  icon: {
    asset: 'Square',
    style: 'Disabled',
  } as IconProps,
};

export type CheckboxProps = {
  state?: CheckboxStateType;
  checkboxType?: CheckboxCheckboxTypeType;
  onCheckboxClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  className?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  state,
  checkboxType,
  onCheckboxClicked,
  icon,
  className,
}) => {

  const currentStyle = styles[`checkbox${state}`];
  
  switch (state) {
    case 'Unchecked':
      icon = {...icon, asset: 'Square'};
      break;
    case 'Checked':
      icon = {...icon, asset: 'SquareCheck'};
      break;
  }
  
  const iconView = (
    <Icon
      className={styles.icon}
      {...icon} />
  );

  return (
    <button
      type={checkboxType}
      onClick={onCheckboxClicked}
      className={cx(currentStyle, className)}>
      {iconView}
    </button>
  );
};

Checkbox.defaultProps = defaultProps;

export default Checkbox;
