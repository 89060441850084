import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import {
  AdditionalResource,
  AdditionalResourcePayload,
  CreateOrUpdateAdditionalResourcesPayload,
  ReorderAdditionalResourcePayload,
  ReorderAdditionalResourcesPayload,
} from './types';

export const getAdditionalResources = async (id: number): Promise<AdditionalResource[]> => {
  try {
    const { data } = await axios.get<AdditionalResource[]>(
      `${getServerUrl()}/lessons/${id}/resources`,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const postAdditionalResource = async (
  payload: AdditionalResourcePayload,
  lessonId: number,
): Promise<AdditionalResource> => {
  try {
    const { data } = await axios.post<AdditionalResource>(
      `${getServerUrl()}/lessons/${lessonId}/resources`,
      payload,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const putAdditionalResources = async (
  payload: CreateOrUpdateAdditionalResourcesPayload,
  lessonId: number,
): Promise<AdditionalResource[]> => {
  try {
    const { data } = await axios.put<AdditionalResource[]>(
      `${getServerUrl()}/lessons/${lessonId}/actions/updateResources`,
      payload,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const deleteAdditionalResource = async (
  lessonId: number, id: number,
): Promise<void> => {
  try {
    await axios.delete<void>(
      `${getServerUrl()}/lessons/${lessonId}/resources/${id}`,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderAdditionalResource = async (
  payload: ReorderAdditionalResourcePayload,
): Promise<void> => {
  const { id, order, lessonId } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/lessons/${lessonId}/resources/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderAdditionalResources = async (
  payload: ReorderAdditionalResourcesPayload,
): Promise<void> => {
  const { lessonId, ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/lessons/${lessonId}/resources/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
