import React, { useRef } from 'react';
import cx from 'classnames';

import styles from './SelectFileItem.module.scss';

import Button, { ButtonProps } from '../Button';
import Text, { TextProps } from '../Text';
import { IconProps } from '../Icon';


export type SelectFileItemStateType = 'Default' | 'FileSelected';

export const defaultProps = {
  state: 'FileSelected' as SelectFileItemStateType,
  button: {
    type: 'IconButton',
    size: 'Large',
    fill: 'White',
    colour: 'Basic',
    icon: {
      asset: 'Close',
      style: 'Dark',
    },
  } as ButtonProps,
  text: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'File name.pdf',
  } as TextProps,
};

export type SelectFileItemProps = {
  state?: SelectFileItemStateType;
  button?: ButtonProps;
  className?: string;
  text?: TextProps;
  onChangeFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onDeleteFile?: () => void;
};

const SelectFileItem: React.FC<SelectFileItemProps> = ({
  state,
  button,
  className,
  text,
  onChangeFile,
  onDeleteFile,
}) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const removeFile = (): void => {
    if (inputRef.current) {
      inputRef.current.value='';
    }
    if (onDeleteFile){
      onDeleteFile();
    }
  }

  const selectFile = (): void => {
    if(inputRef.current){
      inputRef.current.click();
    }
  }

  const inputView = (
    <input
      ref={inputRef}
      type="file"
      hidden
      onChange={onChangeFile}
      accept=".DOCX,.HTML,.PPT,.PPTX,.PDF"
      />
  );

  const deleteIcon = {
    asset: 'Close',
    style: 'Dark',
  } as IconProps

  const currentStyle = styles[`selectFileItem${state}`];
  
  let buttonView;
  let textView;
  
  switch (state) {
    case 'Default':
      buttonView = (
        <Button
          className={styles.button}
          {...button}
          onButtonClicked={selectFile} />
      );
      break;
    case 'FileSelected':
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      buttonView = (
        <Button
          className={styles.button}
          type='IconButton'
          size='Large'
          fill='White'
          colour='Basic'
          icon={deleteIcon}
          onButtonClicked={removeFile} />
      )
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {textView}
      {buttonView}
      {inputView}
    </div>
  );
};

SelectFileItem.defaultProps = defaultProps;

export default SelectFileItem;
