import React from 'react';
import cx from 'classnames';

import styles from './SelectField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Select, { SelectProps } from '../../atoms/Select';

export type SelectFieldStateType = 'Default';

export const defaultProps = {
  state: 'Default' as SelectFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  select: {
    text: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Selected option',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Dark',
    },
  } as SelectProps,
};

export type SelectFieldProps = {
  state?: SelectFieldStateType;
  label?: TextProps;
  select?: SelectProps;
  className?: string;
};

const SelectField: React.FC<SelectFieldProps> = ({
  state,
  label,
  select,
  className,
}) => {

  const currentStyle = styles[`selectField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  const selectView = (
    <Select
      className={styles.select}
      {...select} />
  );
  
  
  switch (state) {
    case 'Default':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {selectView}
    </div>
  );
};

SelectField.defaultProps = defaultProps;

export default SelectField;
