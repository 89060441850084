import React from 'react';
import cx from 'classnames';

import styles from './SectionHeader.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Divider, { DividerProps } from '../../atoms/Divider';

export type SectionHeaderStateType = 'WithDivider' | 'NoDivider';

export const defaultProps = {
  state: 'NoDivider' as SectionHeaderStateType,
  text: {
    type: 'Title3',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Section header',
  } as TextProps,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
};

export type SectionHeaderProps = {
  state?: SectionHeaderStateType;
  text?: TextProps;
  divider?: DividerProps;
  className?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({
  state,
  text,
  divider,
  className,
}) => {

  const currentStyle = styles[`sectionHeader${state}`];

  const textView = (
    <Text
      className={styles.text}
      {...text} />
  );
  
  let dividerView;
  
  switch (state) {
    case 'WithDivider':
      dividerView = (
        <Divider
          className={styles.divider}
          {...divider} />
      );
      break;
    case 'NoDivider':
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {textView}
      {dividerView}
    </div>
  );
};

SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
