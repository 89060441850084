import React from 'react';
import MainContextProvider from './context';
import MainLayoutView, { MainLayoutProps } from './MainLayout';
import withInteractor from './MainLayout.interactor';
import withPresenter from './MainLayout.presenter';

export type { MainLayoutProps };

const MainLayoutInteractor = withInteractor(withPresenter(MainLayoutView));

const MainLayout: React.FC<MainLayoutProps> = (props) => {
  return (
    <MainContextProvider>
      <MainLayoutInteractor {...props} />
    </MainContextProvider>
  );
};

export default MainLayout;
