import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Location } from 'history';
import cloneDeep from 'lodash.clonedeep';
import update from 'immutability-helper';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { QuizEditBlockProps, defaultProps } from './QuizEditBlock';
import { UnitResponse, UpdateUnitPayload } from '../../../modules/unit/types';
import {
  Answer,
  Lesson, Question, QuizUnit, Unit,
} from '../../../modules/types';
import {
  QuestionItemProps, defaultProps as defaultQuestionItemProps,
} from '../../organisms/QuestionItem/QuestionItem';
import {
  UpdateQuestionsPayload, QuestionsResponse, CreateQuestionPayload, QuestionResponse,
} from '../../../modules/question/types';
import { ApiResponse } from '../../../lib/api/types';
import { getBasePath } from '../../../lib/api/utils';
import { ConfirmationModalProps, defaultProps as defaultConfirmationModalProps } from '../../organisms/ConfirmationModal/ConfirmationModal';
import { defaultProps as defaultValidationMessageProps } from '../../molecules/ValidationMessage/ValidationMessage';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { AnswerResponse, CreateAnswerPayload } from '../../../modules/answer/types';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../../../modules/common/types';
import useHandleDrag from '../../../lib/hooks/useHandleDrag';
import useHandleReorderMany from '../../../lib/hooks/useHandleReorderMany';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';
import { setNavigateAwayConfirmationModal } from '../../../lib/utils';

type UnitState = {
  title?: string;
  description?: string;
  canSkip?: boolean;
  estimatedTime?: number;
};

export type QuizEditBlockPresenterProps = QuizEditBlockProps & {
  loading?: boolean;
  error?: Error;
  data: Lesson | null;
  updateUnit: (payload: UpdateUnitPayload) => Promise<UnitResponse>;
  deleteUnit: (id: number) => Promise<ApiResponse<void>>;
  updateQuestions: (payload: UpdateQuestionsPayload) => Promise<QuestionsResponse>;
  createQuestion: (payload: CreateQuestionPayload) => Promise<QuestionResponse>;
  createAnswer: (payload: CreateAnswerPayload) => Promise<AnswerResponse>;
  refetchCourse: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
  reorderQuestion: (payload: ReorderEntityPayload) => Promise<ApiResponse<void>>;
  // TODO: Remove this function
  reorderQuestions: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>;
  reorderAnswer: (payload: ReorderEntityPayload) => Promise<ApiResponse<void>>;
  // TODO: Remove this function
  reorderAnswers: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>;
};

const isQuestionModified = (
  originalQuestion: Question,
  newQuestion: Question,
): boolean => {
  return (
    originalQuestion.correctMessage !== newQuestion.correctMessage
    || originalQuestion.incorrectMessage !== newQuestion.incorrectMessage
    || originalQuestion.question !== newQuestion.question
    || originalQuestion.questionType !== newQuestion.questionType
    || originalQuestion.showMessages !== newQuestion.showMessages
  );
};

const isAnswerModified = (
  originalAnswer: Answer,
  newAnswer: Answer,
): boolean => {
  return (
    originalAnswer.answer !== newAnswer.answer
    || originalAnswer.correct !== newAnswer.correct
  );
};

const areAnswersModified = (
  originalAnswers: Answer[] | undefined,
  newAnswers: Answer[],
): boolean => {
  let modified = false;
  if (!originalAnswers || originalAnswers.length !== newAnswers.length) {
    return true;
  }
  originalAnswers.forEach((answer, index) => {
    if (isAnswerModified(answer, newAnswers[index])) {
      modified = true;
    }
  });
  return modified;
};

const areQuestionsModified = (
  originalQuestions: Question[] | undefined,
  newQuestions: Question[],
): boolean => {
  if (!originalQuestions || originalQuestions.length !== newQuestions.length) {
    return true;
  }
  for (let i = 0; i < originalQuestions.length; i += 1) {
    const question = originalQuestions[i];
    if (isQuestionModified(question, newQuestions[i])) {
      return true;
    }
    if (areAnswersModified(question.answers, newQuestions[i].answers)) {
      return true;
    }
  }
  return false;
};

const isUnitModified = (originalUnit: Unit | null, newUnit: UnitState): boolean => {
  return (
    originalUnit?.description !== newUnit.description
    || originalUnit?.title !== newUnit.title
    || originalUnit?.canSkip !== newUnit.canSkip
    || originalUnit?.estimatedTime !== newUnit.estimatedTime
  );
};

const withPresenter = (
  View: React.FC<QuizEditBlockProps>,
): React.FC<QuizEditBlockPresenterProps> => {
  const Presenter: React.FC<QuizEditBlockPresenterProps> = (props) => {
    const {
      data: lesson,
      updateUnit,
      deleteUnit,
      setError,
      setConfirmation,
      refetchCourse,
      updateQuestions,
      createQuestion,
      createAnswer,
      reorderQuestions,
      reorderAnswers,
    } = props;

    const history = useHistory();
    const {
      unitId, lessonId, courseId,
    } = useParams<{ unitId: string; lessonId: string; courseId: string}>();

    const unit: QuizUnit | null = lesson?.units.find((item) => {
      return `${item.id}` === unitId;
    }) || null;

    const ref = useRef<HTMLInputElement>(null);
    const [edittingIndex, setEdittingIndex] = useState<number>(-1);

    const unitQuestions = useMemo(() => unit?.questions || [], [unit]);

    const [unitState, setUnitState] = useState<UnitState>({
      title: unit?.title || '',
      description: unit?.description || '',
      canSkip: unit?.canSkip || true,
      estimatedTime: unit?.estimatedTime || 0,
    });
    const [
      questionState, setQuestionState,
    ] = useState<Question[]>([]);

    const isUnitUpdated = isUnitModified(unit, unitState);
    const areQuestionsUpdated = areQuestionsModified(unit?.questions, questionState);
    const areChanges = isUnitUpdated || areQuestionsUpdated;

    const { t } = useTranslation();
    const {
      title,
      estimatedTime,
    } = unitState;

    useEffect(() => {
      // When unit is loaded, update unit state and question state
      if (unit) {
        setUnitState(cloneDeep(unit));
      }
    }, [unit]);

    useEffect(() => {
      setQuestionState(cloneDeep(unitQuestions || []));
    }, [unitQuestions]);

    const handleDragQuestion = useHandleDrag(questionState, setQuestionState);
    // TODO: Change back to useHandleReorder
    const handleReorderQuestion = useHandleReorderMany(questionState, reorderQuestions, refetchCourse);

    const getAnswerDragHandler = useCallback(
      // Callback
      (questionId: number) => (dragIndex: number, hoverIndex: number): void => {
        const dragCard = questionState[questionId].answers[dragIndex];
        if (!dragCard) return;

        const newState = cloneDeep(questionState);
        const updatedAnswers = update(questionState[questionId].answers, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        newState[questionId].answers = updatedAnswers;
        setQuestionState(newState);
      },
      // Dependencies
      [questionState],
    );
    const getAnswerReorderHandler = useCallback(
      (questionId: number) => (dragIndex: number): void => {
        const dragCard = questionState[questionId].answers[dragIndex];
        if (!dragCard) return;
        const answerIds = questionState[questionId].answers.map((item) => item.id);
        reorderAnswers({ ids: answerIds });
      },
      [questionState, reorderAnswers],
    );

    const handleSaveQuestions = async (): Promise<QuestionsResponse> => {
      if (questionState && questionState.length > 0) {
        return updateQuestions({
          questions: questionState,
          quizId: unit?.id,
        });
      }
      return { data: [] };
    };

    const handleUpdateUnit = async (): Promise<void> => {
      if (buttonsDisabled){
        setEdittingIndex(-1);
        return;
      }
      let err: Error | undefined;
      if (areQuestionsUpdated) {
        const { error } = await handleSaveQuestions();
        if (error) {
          err = error;
        }
      }
      if (isUnitUpdated) {
        const { error } = await updateUnit({ ...unitState, unitType: 'quiz' });
        if (error) {
          err = error;
        }
      }
      if (!err) {
        refetchCourse();
        setError(undefined);
        setEdittingIndex(-1);
      } else {
        // Dispatch error modal
        setError({
          error: err,
          description: t('error.modal.update_course.description'),
          primaryButton: {
            text: t('error.modal.update_course.button.primary'),
            onClicked: handleUpdateUnit,
          },
        });
      }
    };

    const handleDeleteUnit = async (): Promise<void> => {
      if (!unit) {
        return;
      }
      const { error } = await deleteUnit(unit.id);

      setConfirmation(undefined);

      if (error) {
        // Dispatch Error Modal
        setError({
          error,
          description: t('error.modal.delete_unit.description'),
          primaryButton: {
            text: t('error.modal.delete_unit.button.primary'),
            onClicked: handleDeleteUnit,
          },
        });
      } else if (lesson) {
        const currentUnitIndex = lesson.units.findIndex((item) => item.id === Number(unitId));

        let redirectIndex = -1;
        if (currentUnitIndex !== -1) {
          if (lesson.units[currentUnitIndex + 1]) {
            redirectIndex = currentUnitIndex + 1;
          } else if (lesson.units[currentUnitIndex - 1]) {
            redirectIndex = currentUnitIndex - 1;
          }
        }
        const baseRedirectPath = `${getBasePath()}/course/${courseId}/lesson/${lessonId}`;
        if (redirectIndex !== -1) {
          const redirectUnit = lesson.units[redirectIndex];
          history.replace(`${baseRedirectPath}/${redirectUnit.unitType}/${redirectUnit.id}`);
        } else {
          history.replace(`${baseRedirectPath}`);
        }
        refetchCourse();
      }
    };

    // Reset course/image state
    const discardChanges = (): void => {
      setUnitState({
        ...unit,
      });
      setQuestionState(cloneDeep(unit?.questions || []));
      if (ref.current) {
        ref.current.value = '';
      }
    };

    const handleAddQuestion = async (): Promise<void> => {
      if (questionState) {
        const newQuestion = {
          quizId: unit?.id,
          question: {
            question: 'Untitled question',
            questionType: '',
            showMessages: false,
            incorrectMessage: '',
            correctMessage: '',
          },
        };
        const { data: question, error } = await createQuestion(newQuestion);
        if (question) {
          setQuestionState([...cloneDeep(questionState), { ...question, answers: [] }]);
          setEdittingIndex(questionState.length);
          setError(undefined);
        } else if (error) {
          setError({
            error,
            description: t('error.modal.create_question.description'),
            primaryButton: {
              text: t('error.modal.create_question.button.primary'),
              onClicked: handleAddQuestion,
            },
          });
        }
      }
    };

    const handleRemoveQuestion = async (index: number): Promise<void> => {
      if (questionState) {
        const newQuestionState = cloneDeep(questionState);
        newQuestionState.splice(index, 1);
        setQuestionState(newQuestionState);
      }
    }

    const getToggleViewHandler = (index: number) => (): void => {
      setEdittingIndex(index);
    };

    const handleEditQuestionField = (
      key: keyof Pick<Question, 'question' | 'questionType' | 'correctMessage' | 'incorrectMessage'>, value: string,
    ): void => {
      if (edittingIndex !== -1 && questionState) {
        const newQuestionState = cloneDeep(questionState);
        newQuestionState[edittingIndex][key] = value;
        setQuestionState(newQuestionState);
      }
    };

    const handleToggleShowMessages = (): void => {
      if (edittingIndex !== -1 && questionState) {
        const newQuestionState = cloneDeep(questionState);
        const currentValue = newQuestionState[edittingIndex].showMessages;
        newQuestionState[edittingIndex].showMessages = !currentValue;
        setQuestionState(newQuestionState);
      }
    };

    const handleAddOption = async (): Promise<void> => {
      if (edittingIndex !== -1 && questionState) {
        // Find the question currently being editted
        const currentQuestion = questionState[edittingIndex];
        if (currentQuestion) {
          const newOption = { answer: 'Untitled option', correct: false };

          const { data: option, error } = await createAnswer({
            questionId: currentQuestion.id, answer: newOption,
          });

          if (option) {
            // Create array of questions updated with new option
            const updatedQuestions = cloneDeep(questionState);
            updatedQuestions[edittingIndex].answers.push(option);
            // Set questions to state
            setQuestionState(updatedQuestions);
            setError(undefined);
          } else if (error) {
            setError({
              error,
              description: t('error.modal.create_answer.description'),
              primaryButton: {
                text: t('error.modal.create_answer.button.primary'),
                onClicked: handleAddOption,
              },
            });
          }
        }
      }
    };

    const handleRemoveOption = (index: number) => {
      if (edittingIndex !== -1 && questionState) {
        const newQuestionState = cloneDeep(questionState);
        const currentQuestion = newQuestionState[edittingIndex];
        if (currentQuestion) {
          const currentAnswerState = currentQuestion.answers;
          currentAnswerState.splice(index, 1);
          newQuestionState[edittingIndex].answers = currentAnswerState;
          setQuestionState(newQuestionState);
        }
      }
    }

    const handleEditAnswer = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      answerIndex: number,
    ): void => {
      if (edittingIndex !== -1 && questionState[edittingIndex].answers) {
        const newQuestionState = cloneDeep(questionState);
        newQuestionState[edittingIndex].answers[answerIndex].answer = event.target.value;
        setQuestionState(newQuestionState);
      }
    };

    const toggleCorrectAnswerHandler = (questionIndex: number, answerIndex: number) => (): void => {
      if (questionState[questionIndex].answers) {
        const newQuestionState = cloneDeep(questionState);
        const answer = newQuestionState[questionIndex].answers[answerIndex];
        answer.correct = !answer.correct;
        setQuestionState(newQuestionState);
      }
    };

    // Get change handler for unit input fields
    const getChangeHandler = (property: keyof UnitState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setUnitState({
          ...unitState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const questionItems: QuestionItemProps[] = questionState.map((
      question, index,
    ): QuestionItemProps => {
      return {
        ...defaultQuestionItemProps,
        index,
        id: question.id,
        onDrag: handleDragQuestion,
        onDrop: handleReorderQuestion,
        canDrag: edittingIndex === -1,
        onDragAnswer: getAnswerDragHandler(index),
        onDropAnswer: getAnswerReorderHandler(index),
        questionItem: { ...question, index, editing: index === edittingIndex },
        questionInput: {
          textArea: {
            textValue: question.question,
            onTextChanged: (
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
            ): void => handleEditQuestionField('question', event.currentTarget.value),
          },
        },
        buttonGroup: {
          ...defaultQuestionItemProps.buttonGroup,
          primary: {
            ...defaultQuestionItemProps.buttonGroup.primary,
            onButtonClicked: getToggleViewHandler(index),
          },
          secondary: {
            ...defaultQuestionItemProps.buttonGroup.secondary,
            onButtonClicked: () => handleRemoveQuestion(index),
          },
        },
        addOptionButton: {
          ...defaultQuestionItemProps.addOptionButton,
          onButtonClicked: handleAddOption,
        },
        onEditAnswer: handleEditAnswer,
        onDeleteAnswer: handleRemoveOption,
        correctAnswerToggle: toggleCorrectAnswerHandler,
        validationMessage: {
          ...defaultQuestionItemProps.validationMessage,
          checkbox: {
            ...defaultQuestionItemProps.validationMessage.checkbox,
            state: questionState[edittingIndex]?.showMessages ? 'Checked' : 'Unchecked',
            onCheckboxClicked: handleToggleShowMessages,
          },
          correctMessage: {
            ...defaultValidationMessageProps.correctMessage,
            textArea: {
              ...defaultValidationMessageProps.correctMessage?.textArea,
              textValue: questionState[edittingIndex]?.correctMessage,
              onTextChanged: (
                event: React.ChangeEvent<
                HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
                >,
              ): void => handleEditQuestionField('correctMessage', event.currentTarget.value),
            },
          },
          incorrectMessage: {
            ...defaultValidationMessageProps.incorrectMessage,
            textArea: {
              ...defaultValidationMessageProps.incorrectMessage?.textArea,
              textValue: questionState[edittingIndex]?.incorrectMessage,
              onTextChanged: (
                event: React.ChangeEvent<
                HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
                >,
              ): void => handleEditQuestionField('incorrectMessage', event.currentTarget.value),
            },
          },
        },
      };
    });

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateUnit, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_unit.description', { unitName: unit?.title }),
        primaryButton: {
          onClicked: handleDeleteUnit
        }
      })
    };

    const buttonsDisabled = !(isUnitUpdated || areQuestionsUpdated);

    const viewProps: QuizEditBlockProps = {
      ...defaultProps,
      ...props,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: title,
        },
      },
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      estimatedTime: {
        ...defaultProps.estimatedTime,
        inputWithStepper: {
          ...defaultProps.estimatedTime.inputWithStepper,
          input: { textValue: `${estimatedTime}`, onTextChanged: getChangeHandler('estimatedTime') },
        },
      },
      questionItemList: {
        ...defaultProps.questionItemList,
        questionItems,
      },
      addQuestionButton: {
        ...defaultProps.addQuestionButton,
        onButtonClicked: handleAddQuestion,
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateUnit,
            disabled: false,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: buttonsDisabled,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
    };

    return (
      <>
        <Prompt when={areChanges} message={handleBlockedNavigation} />
        <View {...viewProps} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
