import React from 'react';
import cx from 'classnames';

import styles from './Text.module.scss';

export type TextTypeType = 'Title1' | 'Title2' | 'Title3' | 'Title4' | 'Subtitle1' | 'Subtitle2' | 'Paragraph1' | 'Paragraph3' | 'Paragraph2' | 'Caption1';
export type TextStyleType = 'Dark' | 'Grey' | 'Disabled' | 'White' | 'Brand' | 'Danger';
export type TextSizeType = 'Medium' | 'Large' | 'Small' | 'XS';
export type TextAlignType = 'Left' | 'Right' | 'Center';

export const defaultProps = {
  type: 'Caption1' as TextTypeType,
  style: 'Danger' as TextStyleType,
  size: 'XS' as TextSizeType,
  align: 'Center' as TextAlignType,
  value: 'Caption 1/Grey/Center',
};

export type TextProps = {
  type?: TextTypeType;
  style?: TextStyleType;
  size?: TextSizeType;
  align?: TextAlignType;
  value?: React.ReactNode;
  className?: string;
};

const Text: React.FC<TextProps> = ({
  type,
  style,
  size,
  align,
  value,
  className,
}) => {

  const currentStyle = styles[`text${type}${style}${size}${align}`];

  let valueView;
  
  switch (type) {
    case 'Title1':
      valueView = (
        <h1 className={styles.value}>
          {value}
        </h1>
      );
      break;
    case 'Title2':
      valueView = (
        <h2 className={styles.value}>
          {value}
        </h2>
      );
      break;
    case 'Title3':
      valueView = (
        <h3 className={styles.value}>
          {value}
        </h3>
      );
      break;
    case 'Title4':
      valueView = (
        <h4 className={styles.value}>
          {value}
        </h4>
      );
      break;
    case 'Subtitle1':
      valueView = (
        <h5 className={styles.value}>
          {value}
        </h5>
      );
      break;
    case 'Subtitle2':
      valueView = (
        <h6 className={styles.value}>
          {value}
        </h6>
      );
      break;
    case 'Paragraph1':
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'Paragraph3':
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'Paragraph2':
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
    case 'Caption1':
      valueView = (
        <p className={styles.value}>
          {value}
        </p>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {valueView}
    </div>
  );
};

Text.defaultProps = defaultProps;

export default Text;
