import React from 'react';
import cx from 'classnames';

import styles from './TagTypeItem.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import InputTypeaheadField, { InputChipsFieldProps } from '../../molecules/InputChipsField';
import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  tagTypeField: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Tag type',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputFieldProps,
  deleteButton: {
    fill: 'White',
    type: 'IconButton',
    icon: {
      asset: 'Close',
      style: 'Default',
    },
    size: 'Large',
    buttonType: 'button',
  } as ButtonProps,
  tagValueField: {
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Tag values',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputChipsFieldProps,
};

export type TagTypeItemProps = {
  divider?: DividerProps;
  tagTypeField?: InputFieldProps;
  tagValueField?: InputChipsFieldProps;
  deleteButton?: ButtonProps;
  className?: string;
};

const TagTypeItem: React.FC<TagTypeItemProps> = ({
  divider,
  tagTypeField,
  tagValueField,
  deleteButton,
  className,
}) => {
  return (
    <div className={cx(styles.tagTypeItem, className)}>
      <Divider
        className={styles.divider}
        {...divider} />
      <div className={styles.tagTypeSection}>
        <InputField
          className={styles.tagTypeField}
          {...tagTypeField} />
        <Button
          className={styles.deleteTagTypeButton}
          {...deleteButton}
        />
      </div>
      <InputTypeaheadField
        className={styles.tagValueField}
        {...tagValueField} />
    </div>
  );
};

TagTypeItem.defaultProps = defaultProps;

export default TagTypeItem;
