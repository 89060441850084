import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postTag } from './api';
import { CreateTagPayload, Tag, UseCreateTagResult } from './types';

const useCreateTag = (): UseCreateTagResult => {
  const post = useCallback((
    payload: CreateTagPayload,
  ): Promise<Tag> => postTag(payload), []);
  return usePost(post);
};

export default useCreateTag;
