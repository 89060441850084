import React, { useRef } from 'react';
import cx from 'classnames';

import {
  DropTargetMonitor, useDrag, useDrop,
} from 'react-dnd';
import { XYCoord } from 'dnd-core';
import styles from './MenuUnit.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type MenuUnitTypeType = 'Selected' | 'AddItem' | 'Default';
export type MenuUnitMenuUnitTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'Default' as MenuUnitTypeType,
  icon: {
    asset: 'File',
    style: 'Grey',
  } as IconProps,
  text: {
    type: 'Paragraph3',
    style: 'Dark',
    size: 'Small',
    align: 'Left',
    value: 'Quiz',
  } as TextProps,
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export type MenuUnitProps = {
  type?: MenuUnitTypeType;
  menuUnitType?: MenuUnitMenuUnitTypeType;
  onMenuUnitClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  text?: TextProps;
  className?: string;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  index?: number;
  id?: number;
};

const MenuUnit: React.FC<MenuUnitProps> = ({
  type,
  menuUnitType,
  onMenuUnitClicked,
  icon,
  text,
  className,
  onDrag,
  onDrop,
  index,
  id,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'MenuUnit',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !onDrag || typeof index === 'undefined') {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'MenuUnit',
    item: () => {
      return { id: `${id}`, index } as DragItem;
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: DragItem) => {
      if (!onDrop || typeof index === 'undefined') {
        return;
      }
      onDrop(item.index, index);
    },
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  const currentStyle = styles[`menuUnit${type}`];

  const contentView = (
    <div ref={ref} className={styles.content} style={{ opacity }} data-handler-id={handlerId}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.text}
        {...text} />
    </div>
  );

  let selectorView;

  switch (type) {
    case 'Selected':
      selectorView = (
        <div className={styles.selector}/>
      );
      break;
    case 'AddItem':
      break;
    case 'Default':
      break;
    default:
      break;
  }

  return (
    <button
      type={menuUnitType}
      onClick={onMenuUnitClicked}
      className={cx(currentStyle, className)}>
      {selectorView}
      {contentView}
    </button>
  );
};

MenuUnit.defaultProps = defaultProps;

export default MenuUnit;
