import React from 'react';
import cx from 'classnames';

import styles from './CourseEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import UploadImageField, { UploadImageFieldProps } from '../../molecules/UploadImageField';
import TextAreaField, { TextAreaFieldProps } from '../../molecules/TextAreaField';
import TagSectionList, { TagSectionListProps } from '../../organisms/TagSectionList';
import HtmlEditor, { HtmlEditorProps } from '../../molecules/HtmlEditor';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';
import ConfirmationModal, { ConfirmationModalProps } from '../../organisms/ConfirmationModal';

export const defaultProps = {
  blockHeader: {
    type: 'WithButton',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Course Details',
    },
    button: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Course Settings',
      },
    },
  } as BlockHeaderProps,
  name: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Course name',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputFieldProps,
  uploadImage: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Course image',
    },
    uploadButton: {
      icon: {
        asset: 'UploadImage',
        style: 'Blue',
      },
      text: {
        type: 'Paragraph2',
        style: 'Grey',
        size: 'Medium',
        align: 'Left',
        value: 'Upload picture',
      },
    },
  } as UploadImageFieldProps,
  description: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Course description',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
  tagSectionList: {
    tagSectionItems: [
        {
          sectionHeader: {
            state: 'WithDivider',
            text: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Tag group',
            },
            divider: {
              style: 'Horizontal',
            },
          },
          tagTypeList: {
            chipsFields: [
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
            ],
          },
        },
        {
          sectionHeader: {
            state: 'WithDivider',
            text: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Tag group',
            },
            divider: {
              style: 'Horizontal',
            },
          },
          tagTypeList: {
            chipsFields: [
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
                {
                  label: {
                    type: 'Paragraph1',
                    style: 'Dark',
                    size: 'Large',
                    align: 'Left',
                    value: 'Tag type',
                  },
                },
            ],
          },
        },
    ],
  } as TagSectionListProps,
  htmlEditor: {
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'HTML editor',
    },
  } as HtmlEditorProps,
  buttonSection: {
    state: 'WithDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
    tertiary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonSectionProps,
};

export type CourseEditBlockProps = {
  loading?: boolean;
  error?: Error;
  blockHeader?: BlockHeaderProps;
  name?: InputFieldProps;
  uploadImage?: UploadImageFieldProps;
  description?: TextAreaFieldProps;
  tagSectionList?: TagSectionListProps;
  htmlEditor?: HtmlEditorProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
  onChangeImage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CourseEditBlock = React.forwardRef<HTMLInputElement, CourseEditBlockProps>(({
  blockHeader,
  name,
  uploadImage,
  description,
  tagSectionList,
  htmlEditor,
  buttonSection,
  className,
  onChangeImage,
}, ref) => {
  return (
    <div className={cx(styles.courseEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <InputField
          className={styles.name}
          {...name} />
        <UploadImageField
          className={styles.uploadImage}
          {...uploadImage} />
        <TextAreaField
          className={styles.description}
          {...description} />
        <TagSectionList
          className={styles.tagSectionList}
          {...tagSectionList} />
        <HtmlEditor
          className={styles.htmlEditor}
          {...htmlEditor} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
        <input ref={ref} type='file' hidden onChange={onChangeImage} />
      </div>
    </div>
  );
});

CourseEditBlock.defaultProps = defaultProps;
CourseEditBlock.displayName = 'CourseEditBlock';

export default CourseEditBlock;
