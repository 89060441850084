import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { addCourseTag } from './api';
import { UseUpdateCourseTagResult, UpdateCourseTagPayload } from './types';

const useAddCourseTag = (courseId: number): UseUpdateCourseTagResult => {
  const handleAddTag = useCallback((
    payload: UpdateCourseTagPayload,
  ): Promise<void> => addCourseTag(courseId, payload), [courseId]);
  return usePost(handleAddTag);
};

export default useAddCourseTag;
