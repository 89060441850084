import React from 'react';
import cx from 'classnames';

import styles from './TableItem.module.scss';

import Select, { SelectProps } from '../../atoms/Select';
import Input, { InputProps } from '../../atoms/Input';
import SelectFileItem, { SelectFileItemProps } from '../../atoms/SelectFileItem';
import Button, { ButtonProps } from '../../atoms/Button';
import useDragAndDrop from '../../../lib/hooks/useDragAndDrop';

export type TableItemStateType = 'FileSelected' | 'NoFileSelected' | 'Default';
export type TableItemTypeType = 'FileUpload' | 'ExternalLink';

export const defaultProps = {
  state: 'Default' as TableItemStateType,
  type: 'ExternalLink' as TableItemTypeType,
  resourceTypeSelector: {
    text: {
      type: 'Paragraph1',
      style: 'Grey',
      size: 'Large',
      align: 'Left',
      value: 'External link',
    },
    icon: {
      asset: 'ChevronDown',
      style: 'Disabled',
    },
  } as SelectProps,
  resourceNameInput: {
    style: 'Grey',
    textPlaceholder: 'Name',
  } as InputProps,
  selectFileItem: {
    state: 'Default',
    button: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Select file',
      },
    },
    text: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'File name.pdf',
    },
  } as SelectFileItemProps,
  deleteButton: {
    type: 'IconButton',
    size: 'Large',
    fill: 'White',
    colour: 'Basic',
    icon: {
      asset: 'Close',
      style: 'Dark',
    },
  } as ButtonProps,
  linkInput: {
    style: 'Grey',
    textPlaceholder: 'https://www.redthread.io',
  } as InputProps,
};

export type TableItemProps = {
  state?: TableItemStateType;
  type?: TableItemTypeType;
  resourceTypeSelector?: SelectProps;
  resourceNameInput?: InputProps;
  selectFileItem?: SelectFileItemProps;
  deleteButton?: ButtonProps;
  className?: string;
  linkInput?: InputProps;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  id?: number;
  index?: number;
};

const TableItem: React.FC<TableItemProps> = ({
  state,
  type,
  resourceTypeSelector,
  resourceNameInput,
  selectFileItem,
  deleteButton,
  className,
  linkInput,
  id,
  index,
  onDrag,
  onDrop,
}) => {
  const currentStyle = styles[`tableItem${state}${type}`];

  const { isDragging, ref, handlerId } = useDragAndDrop<HTMLDivElement>({
    id, index, onDrag, onDrop, direction: 'vertical', type: 'TableItem',
  });

  const resourceTypeSelectorView = (
    <Select
      className={styles.resourceTypeSelector}
      {...resourceTypeSelector} />
  );
  const resourceNameInputView = (
    <Input
      className={styles.resourceNameInput}
      {...resourceNameInput} />
  );
  const deleteButtonView = (
    <Button
      className={styles.deleteButton}
      {...deleteButton} />
  );

  let selectFileItemView;
  let linkInputView;

  switch (`${state}${type}`) {
    case 'FileSelectedFileUpload':
      selectFileItemView = (
        <SelectFileItem
          className={styles.selectFileItem}
          { ...selectFileItem }
          state={'FileSelected'}
          />
      );
      break;
    case 'NoFileSelectedFileUpload':
      selectFileItemView = (
        <SelectFileItem
          className={styles.selectFileItem}
          {...selectFileItem} />
      );
      break;
    case 'DefaultExternalLink':
      linkInputView = (
        <Input
          className={styles.linkInput}
          {...linkInput} />
      );
      break;
    default:
      break;
  }

  const opacity = isDragging ? 0 : 1;

  return (
    <div
      className={cx(currentStyle, className)}
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}>
      {resourceTypeSelectorView}
      {resourceNameInputView}
      {selectFileItemView}
      {linkInputView}
      {deleteButtonView}
    </div>
  );
};

TableItem.defaultProps = defaultProps;

export default TableItem;
