import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import MenuUnit from '../../atoms/MenuUnit';
import MenuUnitList, { MenuUnitListProps } from '../MenuUnitList';
import styles from './MenuUnitDropdown.module.scss';

type CustomToggleProps = { onClick: (e: React.MouseEvent) => void };
type CustomMenuProps = {
  style?: Record<string, string>;
  'aria-labelledby'?: string;
  className?: string;
  labeledBy?: string;
};

const CustomToggle = React.forwardRef<HTMLDivElement, CustomToggleProps>(({
  children, onClick,
}, ref) => {
  return (
    <div
      ref={ref}
      onClick={(e: React.MouseEvent): void => {
        e.preventDefault();
        onClick(e);
      }}>
    {children}
    </div>
  );
});
CustomToggle.displayName = 'CustomToggle';

const CustomMenu = React.forwardRef<HTMLDivElement, CustomMenuProps>(({
  children, style, className, 'aria-labelledby': labeledBy,
}, ref) => {
  return (
    <div
      ref={ref}
      style={style}
      className={className}
      aria-labelledby={labeledBy}
    >
    {children}
    </div>
  );
});
CustomMenu.displayName = 'CustomMenu';

export type MenuUnitDropdownProps = {
  toggleMenuTextValue?: string;
  menuUnitList?: MenuUnitListProps;
};

const MenuUnitDropdown: React.FC<MenuUnitDropdownProps> = ({ menuUnitList, toggleMenuTextValue }) => {
  const [open, setOpen] = useState(false);

  return (
    <Dropdown className={styles.Dropdown} show={open} onToggle={(): void => setOpen(!open)}>
      <Dropdown.Toggle as={CustomToggle}>
        <MenuUnit
          className={styles.MenuUnit}
          type='AddItem'
          icon={{
            asset: 'PlusCircle',
            style: 'Blue',
          }}
          text={{
            type: 'Subtitle2',
            style: 'Brand',
            size: 'Medium',
            align: 'Left',
            value: toggleMenuTextValue || 'Add Unit',
          }}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} show={open} onSelect={(): void => setOpen(false)}>
        <MenuUnitList
          {...menuUnitList}
        />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default MenuUnitDropdown;
