import React from 'react';
import cx from 'classnames';

import styles from './TableHeader.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  resourceType: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Type',
  } as TextProps,
  resourceName: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Name',
  } as TextProps,
  linkOrFileName: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Link or file',
  } as TextProps,
};

export type TableHeaderProps = {
  resourceType?: TextProps;
  resourceName?: TextProps;
  linkOrFileName?: TextProps;
  className?: string;
};

const TableHeader: React.FC<TableHeaderProps> = ({
  resourceType,
  resourceName,
  linkOrFileName,
  className,
}) => {
  return (
    <div className={cx(styles.tableHeader, className)}>
      <div className={styles.tableItem}>
        <div className={styles.type}>
          <Text
            className={styles.text}
            {...resourceType} />
        </div>
        <div className={styles.name}>
          <Text
            className={styles.text}
            {...resourceName} />
        </div>
        <div className={styles.linkOrFile}>
          <Text
            className={styles.text}
            {...linkOrFileName} />
        </div>
        <div className={styles.emptyFrame}/>
      </div>
    </div>
  );
};

TableHeader.defaultProps = defaultProps;

export default TableHeader;
