// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntitiesPayload } from '../common/types';
import { reorderQuestions } from './api';
import { UseReorderQuestionsResult } from './types';

const useReorderQuestions = (): UseReorderQuestionsResult => {
  const onReorder = useCallback((
    payload: ReorderEntitiesPayload,
  ): Promise<void> => reorderQuestions(payload), []);
  return usePatch(onReorder);
};

export default useReorderQuestions;
