import React from 'react';
import cx from 'classnames';

import ReactQuill from 'react-quill';

import styles from './HtmlEditor.module.scss';

import Text, { TextProps } from '../../atoms/Text';

export const defaultProps = {
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'HTML Editor',
  } as TextProps,
  textValue: '',
};

export type HtmlEditorProps = {
  label?: TextProps;
  className?: string;
  onTextChanged?: (
    value: string,
  ) => void;
  textValue?: string;
};

// Default value for empty field in ReactQuill
export const QUILL_EMPTY_FIELD = '<p><br></p>';

// for reference on the available options
// visit https://quilljs.com/docs/modules/
const EditorModules = {
  // https://quilljs.com/docs/modules/toolbar/
  toolbar: [
    [
      { font: [] },
      { size: [] },
    ],
    [
      { align: [] },
      'direction',
    ],
    [
      'bold',
      'italic',
      'underline',
      'strike',
    ],
    [
      { color: [] },
      { background: [] },
    ],
    [
      { script: 'super' },
      { script: 'sub' },
    ],
    [
      'blockquote',
      'code-block',
    ],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    [
      'link',
      'image',
    ],
    ['clean'],
  ],
};

const HtmlEditor: React.FC<HtmlEditorProps> = ({
  label,
  className,
  textValue,
  onTextChanged,
}) => {
  return (
    <div className={cx(styles.htmlEditor, className)}>
      <Text
        className={styles.label}
        {...label} />
      <div className={styles.textEditor}>
        <ReactQuill
          value={textValue}
          modules={EditorModules}
          onChange={onTextChanged}
          className={styles.quill}
          theme="snow" />
      </div>
    </div>
  );
};

HtmlEditor.defaultProps = defaultProps;

export default HtmlEditor;
