import React from 'react';
import cx from 'classnames';

import { Typeahead } from 'react-bootstrap-typeahead';

import styles from './InputChipsField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import { InputProps } from '../../atoms/Input';


export const defaultProps = {
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  input: {
    style: 'Grey',
    textPlaceholder: 'Input text',
  } as InputProps,
  error: {
    type: 'Paragraph2',
    style: 'Danger',
    size: 'Medium',
    align: 'Left',
    value: 'Something went wrong',
  } as TextProps,
};

export type OptionItem = {
  id?: number;
  value: string;
}

export type InputChipsFieldProps = {
  label?: TextProps;
  input?: InputProps;
  className?: string;
  allowNew?: boolean;
  multiSelect?: boolean;
  options?: OptionItem[];
  onOptionSelected?: (selected: OptionItem[]) => void;
  selectedOptions?: OptionItem[];
};

const InputChipsField: React.FC<InputChipsFieldProps> = ({
  label,
  input,
  className,
  allowNew,
  multiSelect,
  options,
  onOptionSelected,
  selectedOptions,
}) => {

  return (
    <div className={cx(styles.inputChipsField, className)}>
      <Text
        className={styles.label}
        {...label} />
      <Typeahead
        id={styles.input}
        className={styles.input}
        labelKey="value"
        allowNew={allowNew}
        multiple={multiSelect}
        options={options || []}
        onChange={onOptionSelected}
        selected={selectedOptions}
        placeholder={input?.textPlaceholder} />
    </div>
  );
};

InputChipsField.defaultProps = defaultProps;

export default InputChipsField;
