import React, { 
  createContext, Dispatch, SetStateAction, useState 
} from "react";
import { useTranslation } from "react-i18next";
import { ButtonColourType } from "../../../atoms/Button/Button";
import ConfirmationModal, { ConfirmationModalProps, defaultProps as confirmationModalDefaultProps } from "../ConfirmationModal";


export type ConfirmationContextButton = {
  text?: string;
  colour?: ButtonColourType;
  onClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
}

export type ConfirmationContextState = {
  title?: string;
  description?: string;
  primaryButton?: ConfirmationContextButton;
  secondaryButton?: ConfirmationContextButton;
}

export type ConfirmationContextValue = {
  confirmation?: ConfirmationContextState;
  setConfirmation: Dispatch<SetStateAction<ConfirmationContextState | undefined>>;
}

const initialState: ConfirmationContextValue = {
  confirmation: undefined,
  setConfirmation: () => { }, // noop default callback
}

export const ConfirmationContext = createContext<ConfirmationContextValue>(initialState);

const ConfirmationContextProvider: React.FC<{}> = ({ children }) => {
  const [confirmation, setConfirmation] = useState<ConfirmationContextState | undefined>(initialState.confirmation);
  const { t } = useTranslation();

  const handleCloseModal = (): void => {
    setConfirmation(undefined);
  }

  const confirmationProps: ConfirmationModalProps = {};
  if (confirmation) {
    const {
      title,
      description,
      primaryButton,
      secondaryButton,
    } = confirmation;
    confirmationProps.show = true;
    confirmationProps.title = {
      ...confirmationModalDefaultProps.title,
      value: title || t('warning.modal.default.title'),
    };
    confirmationProps.description = {
      ...confirmationModalDefaultProps.description,
      value: description || t('warning.modal.default.description'),
    };
    confirmationProps.buttonGroup = {
      ...confirmationModalDefaultProps.buttonGroup,
      primary: {
        ...confirmationModalDefaultProps.buttonGroup.primary,
        text: {
          ...confirmationModalDefaultProps.buttonGroup.primary?.text,
          value: primaryButton?.text || t('warning.modal.default.button.primary'),
        },
        colour: primaryButton?.colour || confirmationModalDefaultProps.buttonGroup.primary?.colour,
        onButtonClicked: primaryButton?.onClicked || handleCloseModal,
      },
      secondary: {
        ...confirmationModalDefaultProps.buttonGroup.secondary,
        text: {
          ...confirmationModalDefaultProps.buttonGroup.secondary?.text,
          value: secondaryButton?.text || t('warning.modal.default.button.secondary'),
        },
        colour: secondaryButton?.colour || confirmationModalDefaultProps.buttonGroup.secondary?.colour,
        onButtonClicked: secondaryButton?.onClicked || handleCloseModal,
      },
    }
  }

  return (
    <ConfirmationContext.Provider value={{ confirmation, setConfirmation }}>
      {children}
      <ConfirmationModal
        {...confirmationProps} />
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationContextProvider;