import { PartialTag, TagGroups } from './types';

const checkValue = (value?: string): boolean => {
  return value !== null && value !== undefined;
};
// eslint-disable-next-line import/prefer-default-export
export const groupTags = (tags: PartialTag[]): TagGroups => {
  const tagGroups: TagGroups = {};
  tags.forEach((tag) => {
    const tagGroup = checkValue(tag.editTagGroup) ? tag.editTagGroup : tag.tagGroup;
    const tagType = checkValue(tag.editTagType) ? tag.editTagType : tag.tagType;

    if (tagGroup !== null && tagGroup !== undefined) {
      const tagTypeMap = tagGroups[tagGroup] || {};

      if (tagType !== null && tagType !== undefined) {
        const tagValueArray = tagTypeMap[tagType] || [];
        tagValueArray.push(tag);
        tagTypeMap[tagType] = tagValueArray;
      }

      tagGroups[tagGroup] = tagTypeMap;
    }
  });
  return tagGroups;
};
