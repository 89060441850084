import React from 'react';
import cx from 'classnames';

import styles from './ResourceTable.module.scss';

import SectionHeader, { SectionHeaderProps } from '../../molecules/SectionHeader';
import TableHeader, { TableHeaderProps } from '../../molecules/TableHeader';
import TableItemList, { TableItemListProps } from '../../molecules/TableItemList';
import { defaultProps as tableItemListDefaultProps } from '../../molecules/TableItemList/TableItemList';
import MenuUnitDropdown from '../MenuUnitDropdown';
import { MenuUnitListProps, defaultProps as menuUnitListDefaultProps } from '../MenuUnitList/MenuUnitList';
import { defaultProps as menuUnitDefaultProps } from '../../atoms/MenuUnit/MenuUnit';


export const defaultProps = {
  sectionHeader: {
    state: 'WithDivider',
    text: {
      type: 'Title3',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Additional Resources',
    },
    divider: {
      style: 'Horizontal',
    },
  } as SectionHeaderProps,
  tableHeader: {
    resourceType: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Type',
    },
    resourceName: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Name',
    },
    linkOrFileName: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Link or file',
    },
  } as TableHeaderProps,
  tableItemList: {
    ...tableItemListDefaultProps,
  } as TableItemListProps,
  addResourceMenu: {
    ...menuUnitListDefaultProps,
    menuUnits: [
      {
        ...menuUnitDefaultProps,
        icon: {
          ...menuUnitDefaultProps.icon,
          asset: 'Upload',
        },
        text: {
          ...menuUnitDefaultProps.text,
          value: 'Upload file (DOCX, HTML, PPT, PDF)',
        },
      },
      {
        ...menuUnitDefaultProps,
        icon: {
          ...menuUnitDefaultProps.icon,
          asset: 'ExternalLink',
        },
        text: {
          ...menuUnitDefaultProps.text,
          value: 'External Link',
        },
      },
    ],
  } as MenuUnitListProps,
};

export type ResourceTableProps = {
  sectionHeader?: SectionHeaderProps;
  tableHeader?: TableHeaderProps;
  tableItemList?: TableItemListProps;
  addResourceMenu?: MenuUnitListProps;
  className?: string;
};

const ResourceTable: React.FC<ResourceTableProps> = ({
  sectionHeader,
  tableHeader,
  tableItemList,
  addResourceMenu,
  className,
}) => {
  return (
    <div className={cx(styles.resourceTable, className)}>
      <SectionHeader
        className={styles.sectionHeader}
        {...sectionHeader} />
      <TableHeader
        className={styles.tableHeader}
        {...tableHeader} />
      <TableItemList
        className={styles.tableItemList}
        {...tableItemList} />
      <MenuUnitDropdown
        toggleMenuTextValue={"Add Resource"}
        menuUnitList={addResourceMenu}/>
    </div>
  );
};

ResourceTable.defaultProps = defaultProps;

export default ResourceTable;
