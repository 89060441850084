import React from 'react';
import cx from 'classnames';

import styles from './TagsPage.module.scss';

import TagsEditBlock, { TagsEditBlockProps } from '../../blocks/TagsEditBlock';

export const defaultProps = {
  tagsEditBlock: {
    blockHeader: {
      type: 'Default',
      text: {
        type: 'Title1',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'Tags',
      },
    },
    sectionHeader: {
      state: 'NoDivider',
      text: {
        type: 'Title3',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'Tag groups',
      },
    },
    tagGroupItemList: {
      tagGroupItems: [
          {
            state: 'Edit',
            divider: {
              style: 'Horizontal',
            },
            tagGroupInput: {
              state: 'Default',
              label: {
                type: 'Paragraph1',
                style: 'Dark',
                size: 'Large',
                align: 'Left',
                value: 'Tag group name',
              },
              input: {
                style: 'White',
                textPlaceholder: 'Tag group name goes here',
              },
            },
            tagTypeItemList: {
              tagTypeItems: [
                  {
                    divider: {
                      style: 'Horizontal',
                    },
                    tagTypeField: {
                      state: 'Default',
                      label: {
                        type: 'Paragraph1',
                        style: 'Dark',
                        size: 'Large',
                        align: 'Left',
                        value: 'Tag type',
                      },
                      input: {
                        style: 'Grey',
                        textPlaceholder: 'Input text',
                      },
                    },
                    tagValueField: {
                      state: 'Default',
                      label: {
                        type: 'Paragraph1',
                        style: 'Dark',
                        size: 'Large',
                        align: 'Left',
                        value: 'Tag values',
                      },
                      input: {
                        style: 'Grey',
                        textPlaceholder: 'Input text',
                      },
                    },
                  },
                  {
                    divider: {
                      style: 'Horizontal',
                    },
                    tagTypeField: {
                      state: 'Default',
                      label: {
                        type: 'Paragraph1',
                        style: 'Dark',
                        size: 'Large',
                        align: 'Left',
                        value: 'Tag type',
                      },
                      input: {
                        style: 'Grey',
                        textPlaceholder: 'Input text',
                      },
                    },
                    tagValueField: {
                      state: 'Default',
                      label: {
                        type: 'Paragraph1',
                        style: 'Dark',
                        size: 'Large',
                        align: 'Left',
                        value: 'Tag values',
                      },
                      input: {
                        style: 'Grey',
                        textPlaceholder: 'Input text',
                      },
                    },
                  },
              ],
            },
            addTagType: {
              type: 'IconTextButton',
              size: 'Medium',
              fill: 'White',
              colour: 'Brand',
              icon: {
                asset: 'Plus',
                style: 'Blue',
              },
              text: {
                type: 'Paragraph3',
                style: 'Brand',
                size: 'Small',
                align: 'Center',
                value: 'Add tag type',
              },
            },
          },
          {
            state: 'View',
            divider: {
              style: 'Horizontal',
            },
            tagGroup: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag group name',
            },
            buttonGroup: {
              primary: {
                type: 'TextButton',
                size: 'Large',
                fill: 'White',
                colour: 'Brand',
                text: {
                  type: 'Subtitle1',
                  style: 'Brand',
                  size: 'Medium',
                  align: 'Center',
                  value: 'Edit',
                },
              },
              secondary: {
                type: 'IconButton',
                size: 'Large',
                fill: 'White',
                colour: 'Basic',
                icon: {
                  asset: 'MoreVertical',
                  style: 'Blue',
                },
              },
            },
          },
          {
            state: 'View',
            divider: {
              style: 'Horizontal',
            },
            tagGroup: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag group name',
            },
            buttonGroup: {
              primary: {
                type: 'TextButton',
                size: 'Large',
                fill: 'White',
                colour: 'Brand',
                text: {
                  type: 'Subtitle1',
                  style: 'Brand',
                  size: 'Medium',
                  align: 'Center',
                  value: 'Edit',
                },
              },
              secondary: {
                type: 'IconButton',
                size: 'Large',
                fill: 'White',
                colour: 'Basic',
                icon: {
                  asset: 'MoreVertical',
                  style: 'Blue',
                },
              },
            },
          },
      ],
    },
    button: {
      type: 'IconTextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Brand',
      icon: {
        asset: 'Plus',
        style: 'Blue',
      },
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Add tag group',
      },
    },
    buttonSection: {
      state: 'NoDeleteButton',
      divider: {
        style: 'Horizontal',
      },
      buttonGroup: {
        primary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'Colour',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'White',
            size: 'Medium',
            align: 'Center',
            value: 'Save',
          },
        },
        secondary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Discard',
          },
        },
      },
    },
  } as TagsEditBlockProps,
};

export type TagsPageProps = {
  tagsEditBlock?: TagsEditBlockProps;
  className?: string;
};

const TagsPage: React.FC<TagsPageProps> = ({
  tagsEditBlock,
  className,
}) => {
  return (
    <div className={cx(styles.tagsPage, className)}>
      <TagsEditBlock
        className={styles.tagsEditBlock}
        {...tagsEditBlock} />
    </div>
  );
};

TagsPage.defaultProps = defaultProps;

export default TagsPage;
