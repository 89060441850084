import React, { createContext } from 'react';
import useAppDetails from './useAppDetails';
import { UseAppDetailsResult } from './types';

export type AuthenticatedContextValue = {
  appDetailsState: UseAppDetailsResult;
};

const defaultValue = {
  appDetailsState: {
    loading: false,
    error: undefined,
    data: null,
    refetch: (): void => {},
  },
};

export const AuthContext = createContext<AuthenticatedContextValue>(defaultValue);

export const AuthProvider: React.FC<{}> = (props) => {
  const { children } = props;
  const appDetailsState = useAppDetails();

  return (
    <AuthContext.Provider value={{ appDetailsState }}>
      {children}
    </AuthContext.Provider>
  );
};
