// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntitiesPayload } from '../common/types';
import { reorderAnswers } from './api';
import { UseReorderAnswersResult } from './types';

const useReorderAnswers = (): UseReorderAnswersResult => {
  const onReorder = useCallback((
    payload: ReorderEntitiesPayload,
  ): Promise<void> => reorderAnswers(payload), []);
  return usePatch(onReorder);
};

export default useReorderAnswers;
