import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Lesson } from '../../../modules/lesson/types';
import useDeleteUnit from '../../../modules/unit/useDeleteUnit';
import useUpdateUnit from '../../../modules/unit/useUpdateUnit';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import { ConfirmationContext } from '../../organisms/ConfirmationModal/context';
import { ErrorContext } from '../../organisms/ModalError/context';
import { HtmlEditBlockProps } from './HtmlEditBlock';
import { HtmlEditBlockPresenterProps } from './HtmlEditBlock.presenter';

const withInteractor = (
  Presenter: React.FC<HtmlEditBlockPresenterProps>,
): React.FC<HtmlEditBlockProps> => {
  const Interactor: React.FC<HtmlEditBlockProps> = (props) => {
    const { setError } = useContext(ErrorContext);
    const { setConfirmation } = useContext(ConfirmationContext);
    const { courseState } = useContext(CourseDetailsContext);

    const { lessonId, unitId } = useParams<{ lessonId: string; unitId: string }>();
    const [updateUnitState, updateUnit] = useUpdateUnit(Number(unitId));

    const lesson: Lesson | null = useMemo(() => courseState.data?.lessons.find((item) => {
      return `${item.id}` === lessonId;
    }) || null, [courseState.data, lessonId]);

    const [
      { loading: deleteLoading, error: deleteError }, deleteUnit,
    ] = useDeleteUnit();

    return (
      <Presenter
        {...props}
        data={lesson}
        loading={courseState.loading || updateUnitState.loading || deleteLoading}
        error={courseState.error || updateUnitState.error || deleteError}
        setError={setError}
        setConfirmation={setConfirmation}
        updateUnit={updateUnit}
        deleteUnit={deleteUnit}
        refetchCourse={courseState.refetch}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
