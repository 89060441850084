import React from 'react';
import { Modal } from 'react-bootstrap';
import Text, { TextProps } from '../../atoms/Text';
import ButtonGroup, { ButtonGroupProps } from '../../molecules/ButtonGroup';

import styles from './ModalError.module.scss';

export type ModalErrorProps = {
  title?: TextProps;
  description?: TextProps;
  buttonGroup?: ButtonGroupProps;
  show?: boolean;
  onClose?: () => void;
};

export const defaultProps = {
  title: {
    value: 'Error',
  },
  description: {
    value: 'Description',
  },
  buttonGroup: {
    primary: {
      size: 'Large',
      text: {
        value: 'OK',
        style: 'White',
        type: 'Subtitle1',
        size: 'Medium',
      },
      fill: 'Colour',
      colour: 'Brand',
    },
    secondary: {
      size: 'Large',
      text: {
        value: 'Cancel',
        style: 'Dark',
        type: 'Subtitle1',
        size: 'Medium',
      },
      fill: 'Basic',
      colour: 'Basic',
    },
  } as ButtonGroupProps,
};

const ModalError: React.FC<ModalErrorProps> = ({
  title,
  description,
  buttonGroup,
  show,
  onClose,
}) => (
  <>
  <Modal
    show={show}
    onHide={onClose}
    centered
    contentClassName={styles.content}
    className={styles.errorModal}>
    <Modal.Header closeButton className={styles.header} />
    <Modal.Body className={styles.body}>
      <div className={styles.container}>
        <Text
          className={styles.title}
          type='Title2'
          {...title} />
        <Text
          className={styles.description}
          type='Paragraph1'
          {...description} />
      </div>
      <ButtonGroup
        className={styles.buttonGroup}
        {...buttonGroup} />
    </Modal.Body>
  </Modal>
  </>
);

ModalError.defaultProps = defaultProps;

export default ModalError;
