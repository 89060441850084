import React from "react";
import { Location } from 'history';
import { ConfirmationContextState } from "../components/organisms/ConfirmationModal/context/ConfirmationContext";

// eslint-disable-next-line import/prefer-default-export
export const toBase64 = (file: File): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (): void => resolve(reader.result as string);
  reader.onerror = (error): void => reject(error);
});

const NEW_LINE_REGEX = /\r\n|\r|\n/g;

export const replaceNewlines = (text: string): React.ReactNode[] => {
  return text.split(NEW_LINE_REGEX).flatMap((e) => [<br/>, e]).slice(1);
};


const navigateAwayModalButtonOnClick = async (
  nextLocation: Location,
  functionCall: () => Promise<void>,
  history: any,
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>,
): Promise<void> => {
  await functionCall();
  setConfirmation(undefined);
  history.push(nextLocation);
}

export function setNavigateAwayConfirmationModal(
  primaryButtonFunction: () => Promise<void>,
  secondaryButtonFunction: () => Promise<void>,
  nextLocation: Location,
  history: any,
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>,
  t: any,
) {
  setConfirmation({
    description: t('warning.modal.navigate_away.description'),
    primaryButton: {
      text: t('warning.modal.navigate_away.button.primary'),
      colour: 'Brand',
      onClicked: async () => await navigateAwayModalButtonOnClick(nextLocation, primaryButtonFunction, history, setConfirmation),
    },
    secondaryButton: {
      text: t('warning.modal.navigate_away.button.secondary'),
      onClicked: async () => await navigateAwayModalButtonOnClick(nextLocation, secondaryButtonFunction, history, setConfirmation),
    }
  });
};
