import React from 'react';
import cx from 'classnames';

import { Modal } from 'react-bootstrap';
import styles from './ConfirmationModal.module.scss';

import { IconProps } from '../../atoms/Icon';
import Text, { TextProps } from '../../atoms/Text';
import ButtonGroup, { ButtonGroupProps } from '../../molecules/ButtonGroup';
import Button from '../../atoms/Button';

export const defaultProps = {
  icon: {
    asset: 'Close',
    style: 'Dark',
  } as IconProps,
  title: {
    type: 'Title2',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Are you sure?',
  } as TextProps,
  description: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value:
      'Are you sure you want to delete “Introduction to selling” unit. This cannot be undone.',
  } as TextProps,
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Accept',
      },
    },
    secondary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Basic',
      colour: 'Basic',
      text: {
        type: 'Subtitle1',
        style: 'Dark',
        size: 'Medium',
        align: 'Center',
        value: 'Cancel',
      },
    },
  } as ButtonGroupProps,
};

export type ConfirmationModalProps = {
  icon?: IconProps;
  title?: TextProps;
  description?: TextProps;
  buttonGroup?: ButtonGroupProps;
  className?: string;
  onClose?: () => void;
  show?: boolean;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  icon,
  title,
  description,
  buttonGroup,
  className,
  onClose,
  show,
}) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      centered
      contentClassName={styles.content}
      className={styles.modal}
    >
      {/* <Modal.Header closeButton className={styles.header} /> */}
      <Modal.Body className={styles.body}>
        <div className={cx(styles.confirmationModal, className)}>
          <div className={styles.topContainer}>
            <Button
              type='IconButton'
              icon={icon}
              fill='NoFill'
              onButtonClicked={onClose}
            />
          </div>
          <div className={styles.container}>
            <Text className={styles.title} {...title} />
            <Text className={styles.description} {...description} />
            <ButtonGroup className={styles.buttonGroup} {...buttonGroup} />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

ConfirmationModal.defaultProps = defaultProps;

export default ConfirmationModal;
