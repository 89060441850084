import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import axios from '../../../lib/api/axios';
import { ResourceEditBlockProps, defaultProps } from './ResourceEditBlock';
import { UnitResponse, UpdateUnitPayload } from '../../../modules/unit/types';
import { Lesson, ResourceUnit } from '../../../modules/types';
import { ApiResponse } from '../../../lib/api/types';
import { getBasePath, getMediaServiceResourceUrl, isMediaServiceResource } from '../../../lib/api/utils';
import { defaultProps as defaultUploadFieldProps } from '../../molecules/UploadField/UploadField';
import { ConfirmationModalProps, defaultProps as defaultConfirmationModalProps } from '../../organisms/ConfirmationModal/ConfirmationModal';
import { RadioButtonProps, defaultProps as radioButtonDefaultProps } from '../../atoms/RadioButton/RadioButton';
import { ResourceUploadSectionProps } from '../../organisms/ResourceUploadSection';
import { FileMetadata } from '../../../modules/common/types';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';
import { setNavigateAwayConfirmationModal } from '../../../lib/utils';

type UnitState = {
  title?: string;
  description?: string;
  canSkip?: boolean;
  estimatedTime?: number;
  contentType?: string;
  contentButton?: string;
  contentUrl?: string;
  filename?: string;
};

export type ResourceEditBlockPresenterProps = ResourceEditBlockProps & {
  loading?: boolean;
  error?: Error;
  data: Lesson | null;
  updateUnit: (payload: UpdateUnitPayload) => Promise<UnitResponse>;
  deleteUnit: (id: number) => Promise<ApiResponse<void>>;
  refetchCourse: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
  uploadFile: (payload: File) => Promise<ApiResponse<FileMetadata>>;
};

const isUnitModified = (originalUnit: UnitState | null, newUnit: UnitState): boolean => {
  return (
    originalUnit?.description !== newUnit.description
    || originalUnit?.title !== newUnit.title
    || originalUnit?.canSkip !== newUnit.canSkip
    || originalUnit?.estimatedTime !== newUnit.estimatedTime
    || originalUnit?.contentType !== newUnit.contentType
    || originalUnit?.contentButton !== newUnit.contentButton
    || originalUnit?.contentUrl !== newUnit.contentUrl
  );
};

const withPresenter = (
  View: React.FC<ResourceEditBlockProps>,
): React.FC<ResourceEditBlockPresenterProps> => {
  const Presenter: React.FC<ResourceEditBlockPresenterProps> = (props) => {
    const {
      data: lesson, updateUnit, deleteUnit, setError, setConfirmation, refetchCourse, uploadFile,
    } = props;

    const history = useHistory();
    const {
      unitId, lessonId, courseId,
    } = useParams<{ unitId: string; lessonId: string; courseId: string}>();

    const unit: ResourceUnit | null = lesson?.units.find((item) => {
      return `${item.id}` === unitId;
    }) || null;

    const [unitState, setUnitState] = useState<UnitState>({
      title: unit?.title || '',
      description: unit?.description || '',
      canSkip: unit?.canSkip || true,
      estimatedTime: unit?.estimatedTime || 0,
      contentType: unit?.contentType || '',
      contentUrl: unit?.contentUrl || '',
    });

    const [contentUrlType, setContentUrlType] = useState<'Upload' | 'ExternalLink'>('ExternalLink');
    const [filename, setFilename] = useState('');

    const [selectedFile, setSelectedFile] = useState<File>();

    const onDrop = useCallback((files) => {
      // When file selected from file input, save file to state
      if (files && files[0]) {
        setSelectedFile(files[0]);
      }
    }, []);

    const isUnitUpdated = isUnitModified(unit, unitState);

    const { t } = useTranslation();
    const {
      title,
      description,
      estimatedTime,
      contentType,
      contentButton,
      contentUrl,
    } = unitState;

    const isFileUpload = useMemo(() => isMediaServiceResource(unit?.contentUrl), [unit]);
    useEffect(() => {
      // Set the initial content url type based on the contentType of the resource
      if (unit?.contentUrl) {
        setContentUrlType(isFileUpload ? 'Upload' : 'ExternalLink');
      }
    }, [isFileUpload, unit]);

    useEffect(() => {
      // When data is loaded, update unit state
      if (unit) {
        const unitPayload = { ...unit };
        setUnitState(unitPayload);
      }
    }, [unit]);

    useEffect(() => {
      // Retrieve file metadata for resource
      if (contentUrl && isFileUpload) {
        const resourceUrl = getMediaServiceResourceUrl(`${contentUrl}/metadata`);
        if (resourceUrl) {
          axios.get<FileMetadata>(resourceUrl)
            .then((res) => setFilename(res.data.filename))
            .catch(() => setFilename('Could not retrieve file metadata'));
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentUrl, isFileUpload]);

    // When delete button is pressed, set file state to undefined and contentUrl to empty string
    const handleDeleteFile = (): void => {
      setSelectedFile(undefined);
      setUnitState({ ...unitState, contentUrl: '' });
      setFilename('');
    };

    // Make a request to save the image in the media service
    const handleSaveFile = async (file: File): Promise<FileMetadata> => {
      const imageMetadata = (await uploadFile(file)).data;
      if (!imageMetadata) {
        throw new Error();
      }
      return imageMetadata;
    };

    const handleUpdateUnit = async (): Promise<void> => {
      try {
        let fileMetadata;
        if (selectedFile) {
          fileMetadata = await handleSaveFile(selectedFile);
        }
        const { data } = await updateUnit({
          ...unitState, unitType: 'resource', contentUrl: fileMetadata?.url || contentUrl,
        });
        if (!data) {
          throw new Error();
        }
        refetchCourse();
        setError(undefined);
      } catch (err) {
        // Dispatch error modal
        setError({
          error: err,
          description: t('error.modal.update_course.description'),
          primaryButton: {
            text: t('error.modal.update_course.button.primary'),
            onClicked: handleUpdateUnit,
          },
        });
      }
    };

    const handleDeleteUnit = async (): Promise<void> => {
      if (!unit) {
        return;
      }
      const { error } = await deleteUnit(unit.id);

      setConfirmation(undefined);

      if (error) {
        setError({
          error,
          description: t('error.modal.delete_unit.description'),
          primaryButton: {
            text: t('error.modal.delete_unit.button.primary'),
            onClicked: handleDeleteUnit,
          },
        });
      } else if (lesson) {
        const currentUnitIndex = lesson.units.findIndex((item) => item.id === Number(unitId));

        let redirectIndex = -1;
        if (currentUnitIndex !== -1) {
          if (lesson.units[currentUnitIndex + 1]) {
            redirectIndex = currentUnitIndex + 1;
          } else if (lesson.units[currentUnitIndex - 1]) {
            redirectIndex = currentUnitIndex - 1;
          }
        }
        const baseRedirectPath = `${getBasePath()}/course/${courseId}/lesson/${lessonId}`;
        if (redirectIndex !== -1) {
          const redirectUnit = lesson.units[redirectIndex];
          history.replace(`${baseRedirectPath}/${redirectUnit.unitType}/${redirectUnit.id}`);
        } else {
          history.replace(`${baseRedirectPath}`);
        }
        refetchCourse();
      }
    };

    const discardChanges = (): void => {
      setUnitState({ ...unit });
      setSelectedFile(undefined);
    };

    // Get change handler for unit input fields
    const getChangeHandler = (property: keyof UnitState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setUnitState({
          ...unitState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const radioButtons: RadioButtonProps[] = [
      {
        ...radioButtonDefaultProps,
        state: contentUrlType === 'ExternalLink' ? 'Selected' : 'Default',
        icon: {
          ...radioButtonDefaultProps.icon,
          asset: 'ExternalLink',
        },
        text: {
          ...radioButtonDefaultProps.text,
          value: 'External link',
        },
        onRadioButtonClicked: (): void => {
          setContentUrlType('ExternalLink');
        },
      },
      {
        ...radioButtonDefaultProps,
        state: contentUrlType === 'Upload' ? 'Selected' : 'Default',
        icon: {
          ...radioButtonDefaultProps.icon,
          asset: 'Upload',
        },
        text: {
          ...radioButtonDefaultProps.text,
          value: 'Upload file',
        },
        onRadioButtonClicked: (): void => {
          setContentUrlType('Upload');
        },
      },
    ];

    const resourceUploadSectionProps: ResourceUploadSectionProps = {
      ...defaultProps.buttonLink,
      onDrop,
      type: contentUrlType === 'Upload' ? 'Upload' : 'Default',
      onDeleteFile: handleDeleteFile,
      uploadField: {
        ...defaultProps.buttonLink.uploadField,
        state: (selectedFile || (unitState.contentUrl && isFileUpload)) ? 'Uploaded' : 'Default',
        videoName: {
          ...defaultUploadFieldProps.videoName,
          value: selectedFile?.name || filename || '',
        },
      },
      videoUrl: {
        ...defaultProps.buttonLink.videoUrl,
        input: { textValue: contentUrl, onTextChanged: getChangeHandler('contentUrl') },
      },
    };

    const buttonsDisabled = !(isUnitUpdated || !!selectedFile);

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateUnit, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_unit.description', { unitName: unit?.title }),
        primaryButton: {
          onClicked: handleDeleteUnit
        }
      })
    };

    const viewProps: ResourceEditBlockProps = {
      ...defaultProps,
      ...props,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: title,
        },
      },
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      description: {
        ...defaultProps.description,
        textArea: { textValue: description, onTextChanged: getChangeHandler('description') },
      },
      resourceType: {
        ...defaultProps.resourceType,
        input: { textValue: contentType, onTextChanged: getChangeHandler('contentType') },
      },
      buttonText: {
        ...defaultProps.buttonText,
        input: { textValue: contentButton, onTextChanged: getChangeHandler('contentButton') },
      },
      radioButtonList: {
        ...defaultProps.radioButtonList,
        radioButtons,
      },
      buttonLink: resourceUploadSectionProps,
      estimatedTime: {
        ...defaultProps.estimatedTime,
        inputWithStepper: {
          ...defaultProps.estimatedTime.inputWithStepper,
          input: { textValue: `${estimatedTime}`, onTextChanged: getChangeHandler('estimatedTime') },
        },
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateUnit,
            disabled: buttonsDisabled,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: buttonsDisabled,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
    };

    return (
      <>
        <Prompt when={isUnitUpdated} message={handleBlockedNavigation}/>
        <View {...viewProps} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
