import React from 'react';
import cx from 'classnames';

import styles from './TagsEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import SectionHeader, { SectionHeaderProps } from '../../molecules/SectionHeader';
import TagGroupItemList, { TagGroupItemListProps } from '../../organisms/TagGroupItemList';
import Button, { ButtonProps } from '../../atoms/Button';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';

export const defaultProps = {
  blockHeader: {
    type: 'Default',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Tags',
    },
  } as BlockHeaderProps,
  sectionHeader: {
    state: 'NoDivider',
    text: {
      type: 'Title3',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Tag groups',
    },
  } as SectionHeaderProps,
  tagGroupItemList: {
    tagGroupItems: [
        {
          state: 'Edit',
          divider: {
            style: 'Horizontal',
          },
          tagGroupInput: {
            state: 'Default',
            label: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Tag group name',
            },
            input: {
              style: 'White',
              textPlaceholder: 'Tag group name goes here',
            },
          },
          tagTypeItemList: {
            tagTypeItems: [
                {
                  divider: {
                    style: 'Horizontal',
                  },
                  tagTypeField: {
                    state: 'Default',
                    label: {
                      type: 'Paragraph1',
                      style: 'Dark',
                      size: 'Large',
                      align: 'Left',
                      value: 'Tag type',
                    },
                    input: {
                      style: 'Grey',
                      textPlaceholder: 'Input text',
                    },
                  },
                  tagValueField: {
                    state: 'Default',
                    label: {
                      type: 'Paragraph1',
                      style: 'Dark',
                      size: 'Large',
                      align: 'Left',
                      value: 'Tag values',
                    },
                    input: {
                      style: 'Grey',
                      textPlaceholder: 'Input text',
                    },
                  },
                },
                {
                  divider: {
                    style: 'Horizontal',
                  },
                  tagTypeField: {
                    state: 'Default',
                    label: {
                      type: 'Paragraph1',
                      style: 'Dark',
                      size: 'Large',
                      align: 'Left',
                      value: 'Tag type',
                    },
                    input: {
                      style: 'Grey',
                      textPlaceholder: 'Input text',
                    },
                  },
                  tagValueField: {
                    state: 'Default',
                    label: {
                      type: 'Paragraph1',
                      style: 'Dark',
                      size: 'Large',
                      align: 'Left',
                      value: 'Tag values',
                    },
                    input: {
                      style: 'Grey',
                      textPlaceholder: 'Input text',
                    },
                  },
                },
            ],
          },
          addTagType: {
            type: 'IconTextButton',
            size: 'Medium',
            fill: 'White',
            colour: 'Brand',
            icon: {
              asset: 'Plus',
              style: 'Blue',
            },
            text: {
              type: 'Paragraph3',
              style: 'Brand',
              size: 'Small',
              align: 'Center',
              value: 'Add tag type',
            },
          },
        },
        {
          state: 'View',
          divider: {
            style: 'Horizontal',
          },
          tagGroup: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag group name',
          },
          buttonGroup: {
            primary: {
              type: 'TextButton',
              size: 'Large',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Subtitle1',
                style: 'Brand',
                size: 'Medium',
                align: 'Center',
                value: 'Edit',
              },
            },
            secondary: {
              type: 'IconButton',
              size: 'Large',
              fill: 'White',
              colour: 'Basic',
              icon: {
                asset: 'MoreVertical',
                style: 'Blue',
              },
            },
          },
        },
        {
          state: 'View',
          divider: {
            style: 'Horizontal',
          },
          tagGroup: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag group name',
          },
          buttonGroup: {
            primary: {
              type: 'TextButton',
              size: 'Large',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Subtitle1',
                style: 'Brand',
                size: 'Medium',
                align: 'Center',
                value: 'Edit',
              },
            },
            secondary: {
              type: 'IconButton',
              size: 'Large',
              fill: 'White',
              colour: 'Basic',
              icon: {
                asset: 'MoreVertical',
                style: 'Blue',
              },
            },
          },
        },
    ],
  } as TagGroupItemListProps,
  button: {
    type: 'IconTextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    icon: {
      asset: 'Plus',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Add tag group',
    },
  } as ButtonProps,
  buttonSection: {
    state: 'NoDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
  } as ButtonSectionProps,
};

export type TagsEditBlockProps = {
  blockHeader?: BlockHeaderProps;
  sectionHeader?: SectionHeaderProps;
  tagGroupItemList?: TagGroupItemListProps;
  button?: ButtonProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
};

const TagsEditBlock: React.FC<TagsEditBlockProps> = ({
  blockHeader,
  sectionHeader,
  tagGroupItemList,
  button,
  buttonSection,
  className,
}) => {
  return (
    <div className={cx(styles.tagsEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <div className={styles.content}>
          <SectionHeader
            className={styles.sectionHeader}
            {...sectionHeader} />
          <TagGroupItemList
            className={styles.tagGroupItemList}
            {...tagGroupItemList} />
        </div>
        <Button
          className={styles.button}
          {...button} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
      </div>
    </div>
  );
};

TagsEditBlock.defaultProps = defaultProps;

export default TagsEditBlock;
