import React from 'react';
import cx from 'classnames';

import styles from './Logo.module.scss';

import LogoAsset from '../../../resources/icons/Logo.svg';

export const defaultProps = {
  logo: LogoAsset,
};

export type LogoProps = {
  logo?: string;
  logoAlt?: string;
  className?: string;
};

const Logo: React.FC<LogoProps> = ({
  logo,
  logoAlt,
  className,
}) => {
  return (
    <div className={cx(styles.logo, className)}>
      <img
        className={styles.logo}
        alt={logoAlt}
        src={logo} />
    </div>
  );
};

Logo.defaultProps = defaultProps;

export default Logo;
