import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postAnswer } from './api';
import { Answer, CreateAnswerPayload, UseCreateAnswerResult } from './types';

const useCreateAnswer = (): UseCreateAnswerResult => {
  const post = useCallback((
    payload: CreateAnswerPayload,
  ): Promise<Answer> => postAnswer(payload), []);
  return usePost(post);
};

export default useCreateAnswer;
