import React from 'react';
import cx from 'classnames';

import styles from './InputWithStepperField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import InputWithStepper, { InputWithStepperProps } from '../../atoms/InputWithStepper';

export type InputWithStepperFieldStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Error' as InputWithStepperFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  inputWithStepper: {
    input: {
      style: 'Grey',
      textPlaceholder: '00',
    },
    stepDown: {
      type: 'IconButton',
      size: 'Small',
      fill: 'White',
      colour: 'Basic',
      icon: {
        asset: 'ChevronUp',
        style: 'Dark',
      },
    },
    stepUp: {
      type: 'IconButton',
      size: 'Small',
      fill: 'White',
      colour: 'Basic',
      icon: {
        asset: 'ChevronDown',
        style: 'Dark',
      },
    },
  } as InputWithStepperProps,
  error: {
    type: 'Paragraph2',
    style: 'Danger',
    size: 'Medium',
    align: 'Left',
    value: 'Something went wrong',
  } as TextProps,
};

export type InputWithStepperFieldProps = {
  state?: InputWithStepperFieldStateType;
  label?: TextProps;
  inputWithStepper?: InputWithStepperProps;
  className?: string;
  error?: TextProps;
};

const InputWithStepperField: React.FC<InputWithStepperFieldProps> = ({
  state,
  label,
  inputWithStepper,
  className,
  error,
}) => {

  const currentStyle = styles[`inputWithStepperField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  const inputWithStepperView = (
    <InputWithStepper
      className={styles.inputWithStepper}
      {...inputWithStepper} />
  );
  
  let errorView;
  
  switch (state) {
    case 'Default':
      break;
    case 'Error':
      errorView = (
        <Text
          className={styles.error}
          {...error} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {inputWithStepperView}
      {errorView}
    </div>
  );
};

InputWithStepperField.defaultProps = defaultProps;

export default InputWithStepperField;
