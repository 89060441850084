import React from 'react';
import cx from 'classnames';

import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { Spinner } from 'react-bootstrap';
import styles from './UploadField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Icon, { IconProps } from '../../atoms/Icon';
import Button, { ButtonProps } from '../../atoms/Button';
import { LoaderProps } from '../../atoms/Loader';
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup';

export type UploadFieldStateType = 'Default' | 'Uploading' | 'Uploaded';

export const defaultProps = {
  state: 'Uploaded' as UploadFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'File Upload',
  } as TextProps,
  icon: {
    asset: 'VideoReel',
    style: 'Dark',
  } as IconProps,
  button: {
    type: 'TextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Upload',
    },
  } as ButtonProps,
  cancelButton: {
    type: 'TextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Cancel',
    },
  } as ButtonProps,
  videoName: {
    type: 'Title4',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Video name.mp4',
  } as TextProps,
  loader: {
  } as LoaderProps,
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Replace',
      },
    },
    secondary: {
      type: 'TextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonGroupProps,
};

export type UploadFieldProps = {
  state?: UploadFieldStateType;
  label?: TextProps;
  icon?: IconProps;
  button?: ButtonProps;
  cancelButton ?: ButtonProps;
  className?: string;
  videoName?: TextProps;
  loader?: LoaderProps;
  buttonGroup?: ButtonGroupProps;
  dropzone?: {
    getInputProps: (props?: DropzoneInputProps | undefined) => DropzoneInputProps;
    getRootProps: (props?: DropzoneRootProps | undefined) => DropzoneRootProps;
  };
};

const UploadField: React.FC<UploadFieldProps> = ({
  state,
  label,
  icon,
  button,
  cancelButton,
  className,
  videoName,
  loader,
  buttonGroup,
  dropzone,
}) => {
  const currentStyle = styles[`uploadField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );

  let uploadVideoView;
  let containerView;

  const inputProps = dropzone?.getInputProps({ hidden: true });

  switch (state) {
    case 'Default': {
      const rootProps = dropzone?.getRootProps();

      uploadVideoView = (
        <div {...rootProps} className={styles.uploadVideo}>
          <Icon
            className={styles.icon}
            {...icon} />
          <Button
            className={styles.button}
            {...button} />
        </div>
      );
      break;
    }
    case 'Uploading':
      containerView = (
        <div className={styles.container}>
          <div className={styles.uploadedContainer}>
            <div className={styles.uploadedVideo}>
              <Text
                className={styles.videoName}
                {...videoName} />
              <Spinner
                className={styles.loader}
                animation='border' />
            </div>
            <Button
              className={styles.cancelButton}
              {...cancelButton} />
          </div>
          <div className={styles.spacer}/>
        </div>
      );
      break;
    case 'Uploaded': {
      const rootProps = dropzone?.getRootProps();
      containerView = (
        <div {...rootProps} className={styles.container}>
          <div className={styles.uploadedContainer}>
            <div className={styles.uploadedVideo}>
              <Text
                className={styles.videoName}
                {...videoName} />
            </div>
            <ButtonGroup
              className={styles.buttonGroup}
              {...buttonGroup} />
          </div>
          <div className={styles.spacer}/>
        </div>
      );
      break;
    }
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {uploadVideoView}
      {containerView}
      <input {...inputProps} />
    </div>
  );
};

UploadField.defaultProps = defaultProps;

export default UploadField;
