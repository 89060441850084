import React from 'react';
import cx from 'classnames';

import { Route, Switch } from 'react-router-dom';
import styles from './CourseEditPage.module.scss';

import CourseEditBlock, { CourseEditBlockProps } from '../../blocks/CourseEditBlock';
import LessonEditBlock from '../../blocks/LessonEditBlock';
import VideoEditBlock from '../../blocks/VideoEditBlock';
import ResourceEditBlock from '../../blocks/ResourceEditBlock';
import QuizEditBlock from '../../blocks/QuizEditBlock';
import HtmlEditBlock from '../../blocks/HtmlEditBlock';

export const defaultProps = {
  courseEditBlock: {
    blockHeader: {
      type: 'Default',
      text: {
        type: 'Title1',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'Course Details',
      },
    },
    name: {
      state: 'Default',
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Course name',
      },
      input: {
        style: 'Grey',
        textPlaceholder: 'Input text',
      },
    },
    uploadImage: {
      state: 'Default',
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Course image',
      },
      uploadButton: {
        icon: {
          asset: 'UploadImage',
          style: 'Blue',
        },
        text: {
          type: 'Paragraph2',
          style: 'Grey',
          size: 'Medium',
          align: 'Left',
          value: 'Upload picture',
        },
      },
    },
    description: {
      state: 'Default',
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Course description',
      },
      textArea: {
        textPlaceholder: 'Text area text',
      },
    },
    tagSectionList: {
      tagSectionItems: [
        {
          sectionHeader: {
            state: 'WithDivider',
            text: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Tag group',
            },
            divider: {
              style: 'Horizontal',
            },
          },
          tagTypeList: {
            chipsFields: [
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
            ],
          },
        },
        {
          sectionHeader: {
            state: 'WithDivider',
            text: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Tag group',
            },
            divider: {
              style: 'Horizontal',
            },
          },
          tagTypeList: {
            chipsFields: [
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
            ],
          },
        },
      ],
    },
    htmlEditor: {
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'HTML editor',
      },
    },
    buttonSection: {
      state: 'WithDeleteButton',
      divider: {
        style: 'Horizontal',
      },
      buttonGroup: {
        primary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'Colour',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'White',
            size: 'Medium',
            align: 'Center',
            value: 'Save',
          },
        },
        secondary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Discard',
          },
        },
      },
      tertiary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Danger',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Delete',
        },
      },
    },
  } as CourseEditBlockProps,
};

export type CourseEditPageProps = {
  courseEditBlock?: CourseEditBlockProps;
  className?: string;
};

const CourseEditPage: React.FC<CourseEditPageProps> = ({
  className,
}) => {
  return (
    <div className={cx(styles.courseEditPage, className)}>
      <Switch>
        <Route exact path='/:appId/:serviceName/course/:courseId'>
          <CourseEditBlock
            className={styles.block} />
        </Route>
        <Route exact path='/:appId/:serviceName/course/:courseId/lesson/:lessonId'>
          <LessonEditBlock
            className={styles.block} />
        </Route>
        <Route exact path='/:appId/:serviceName/course/:courseId/lesson/:lessonId/video/:unitId'>
          <VideoEditBlock
            className={styles.block} />
        </Route>
        <Route exact path='/:appId/:serviceName/course/:courseId/lesson/:lessonId/resource/:unitId'>
          <ResourceEditBlock
            className={styles.block} />
        </Route>
        <Route exact path='/:appId/:serviceName/course/:courseId/lesson/:lessonId/quiz/:unitId'>
          <QuizEditBlock
            className={styles.block} />
        </Route>
        <Route exact path='/:appId/:serviceName/course/:courseId/lesson/:lessonId/html/:unitId'>
          <HtmlEditBlock
            className={styles.block} />
        </Route>
      </Switch>
    </div>
  );
};

CourseEditPage.defaultProps = defaultProps;

export default CourseEditPage;
