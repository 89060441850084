// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntitiesPayload } from '../common/types';
import { reorderUnits } from './api';
import { UseReorderUnitsResult } from './types';

const useReorderUnits = (): UseReorderUnitsResult => {
  const onReorder = useCallback((
    payload: ReorderEntitiesPayload,
  ): Promise<void> => reorderUnits(payload), []);
  return usePatch(onReorder);
};

export default useReorderUnits;
