import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntityPayload } from '../common/types';
import { reorderQuestion } from './api';
import { UseReorderQuestionResult } from './types';

const useReorderQuestion = (): UseReorderQuestionResult => {
  const onReorder = useCallback((
    payload: ReorderEntityPayload,
  ): Promise<void> => reorderQuestion(payload), []);
  return usePatch(onReorder);
};

export default useReorderQuestion;
