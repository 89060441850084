import React from 'react';
import cx from 'classnames';

import styles from './CoursesPage.module.scss';

import CoursesBlock, { CoursesBlockProps } from '../../blocks/CoursesBlock';

export const defaultProps = {
  coursesBlock: {
    blockHeader: {
      type: 'WithButton',
      text: {
        type: 'Title1',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'Block header',
      },
      button: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Create Course',
        },
      },
    },
    courseCardList: {
      courseCards: [
          {
            title: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'How to Build a Compelling Competitve Strategy',
            },
            units: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '5 Lessons | 2 Quiz | 2 Videos',
            },
            estimatedTime: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '4 Hours',
            },
            description: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
            },
            button: {
              type: 'TextButton',
              size: 'Medium',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Paragraph3',
                style: 'Brand',
                size: 'Small',
                align: 'Center',
                value: 'Open course',
              },
            },
          },
          {
            title: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'How to Build a Compelling Competitve Strategy',
            },
            units: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '5 Lessons | 2 Quiz | 2 Videos',
            },
            estimatedTime: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '4 Hours',
            },
            description: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
            },
            button: {
              type: 'TextButton',
              size: 'Medium',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Paragraph3',
                style: 'Brand',
                size: 'Small',
                align: 'Center',
                value: 'Open course',
              },
            },
          },
          {
            title: {
              type: 'Title3',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'How to Build a Compelling Competitve Strategy',
            },
            units: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '5 Lessons | 2 Quiz | 2 Videos',
            },
            estimatedTime: {
              type: 'Paragraph3',
              style: 'Grey',
              size: 'Small',
              align: 'Left',
              value: '4 Hours',
            },
            description: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
            },
            button: {
              type: 'TextButton',
              size: 'Medium',
              fill: 'White',
              colour: 'Brand',
              text: {
                type: 'Paragraph3',
                style: 'Brand',
                size: 'Small',
                align: 'Center',
                value: 'Open course',
              },
            },
          },
      ],
    },
  } as CoursesBlockProps,
};

export type CoursesPageProps = {
  coursesBlock?: CoursesBlockProps;
  className?: string;
};

const CoursesPage: React.FC<CoursesPageProps> = ({
  coursesBlock,
  className,
}) => {
  return (
    <div className={cx(styles.coursesPage, className)}>
      <CoursesBlock
        className={styles.coursesBlock}
        {...coursesBlock} />
    </div>
  );
};

CoursesPage.defaultProps = defaultProps;

export default CoursesPage;
