// TODO: Remove this file
import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntitiesPayload } from '../common/types';
import { reorderLessons } from './api';
import { UseReorderLessonsResult } from './types';

const useReorderLessons = (): UseReorderLessonsResult => {
  const onReorder = useCallback((
    payload: ReorderEntitiesPayload,
  ): Promise<void> => reorderLessons(payload), []);
  return usePatch(onReorder);
};

export default useReorderLessons;
