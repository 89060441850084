import React from 'react';
import cx from 'classnames';

import styles from './VideoEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import RadioButtonList, { RadioButtonListProps } from '../../organisms/RadioButtonList';
import VideoUploadSection, { VideoUploadSectionProps } from '../../organisms/VideoUploadSection';
import TextAreaField, { TextAreaFieldProps } from '../../molecules/TextAreaField';
import InputWithStepperField, { InputWithStepperFieldProps } from '../../molecules/InputWithStepperField';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';

export const defaultProps = {
  blockHeader: {
    type: 'Default',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Video',
    },
  } as BlockHeaderProps,
  name: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Video Name',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputFieldProps,
  radioButtonList: {
    radioButtons: [
      {
        icon: {
          asset: 'Vimeo',
          style: 'Default',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Vimeo',
        },
      },
      {
        icon: {
          asset: 'Youtube',
          style: 'Default',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Youtube',
        },
      },
      {
        icon: {
          asset: 'Upload',
          style: 'Blue',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Upload',
        },
      },
    ],
  } as RadioButtonListProps,
  videoUploadSection: {
    type: 'Default',
    videoUrl: {
      state: 'Default',
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Video url',
      },
      input: {
        style: 'Grey',
        textPlaceholder: 'Input text',
      },
    },
  } as VideoUploadSectionProps,
  description: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Video Description',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
  estimatedTime: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Estimated time (In minutes)',
    },
    inputWithStepper: {
      input: {
        style: 'Grey',
        textPlaceholder: '00',
      },
      stepDown: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronUp',
          style: 'Dark',
        },
      },
      stepUp: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
    },
  } as InputWithStepperFieldProps,
  buttonSection: {
    state: 'WithDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
    tertiary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonSectionProps,
};

export type VideoEditBlockProps = {
  blockHeader?: BlockHeaderProps;
  name?: InputFieldProps;
  radioButtonList?: RadioButtonListProps;
  videoUploadSection?: VideoUploadSectionProps;
  description?: TextAreaFieldProps;
  estimatedTime?: InputWithStepperFieldProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
  onChangeImage?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const VideoEditBlock = React.forwardRef<HTMLInputElement, VideoEditBlockProps>(({
  blockHeader,
  name,
  radioButtonList,
  videoUploadSection,
  description,
  estimatedTime,
  buttonSection,
  className,
  onChangeImage,
}, ref) => {
  return (
    <div className={cx(styles.videoEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <InputField
          className={styles.name}
          {...name} />
        <RadioButtonList
          className={styles.radioButtonList}
          {...radioButtonList} />
        <VideoUploadSection
          className={styles.videoUploadSection}
          {...videoUploadSection} />
        <TextAreaField
          className={styles.description}
          {...description} />
        <InputWithStepperField
          className={styles.estimatedTime}
          {...estimatedTime} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
        <input ref={ref} type='file' hidden onChange={onChangeImage} />
      </div>
    </div>
  );
});

VideoEditBlock.defaultProps = defaultProps;
VideoEditBlock.displayName = 'VideoEditBlock';

export default VideoEditBlock;
