import React from 'react';
import cx from 'classnames';

import styles from './InputWithStepper.module.scss';

import Input, { InputProps } from '../Input';
import Button, { ButtonProps } from '../Button';

export const defaultProps = {
  input: {
    style: 'Grey',
    textPlaceholder: '00',
  } as InputProps,
  stepDown: {
    type: 'IconButton',
    size: 'Small',
    fill: 'White',
    colour: 'Basic',
    icon: {
      asset: 'ChevronUp',
      style: 'Disabled',
    },
  } as ButtonProps,
  stepUp: {
    type: 'IconButton',
    size: 'Small',
    fill: 'White',
    colour: 'Basic',
    icon: {
      asset: 'ChevronDown',
      style: 'Disabled',
    },
  } as ButtonProps,
};

export type InputWithStepperProps = {
  input?: InputProps;
  stepDown?: ButtonProps;
  stepUp?: ButtonProps;
  className?: string;
};

const InputWithStepper: React.FC<InputWithStepperProps> = ({
  input,
  stepDown,
  stepUp,
  className,
}) => {
  return (
    <div className={cx(styles.inputWithStepper, className)}>
      <Input
        className={styles.input}
        {...input} />
      <div className={styles.buttons}>
        <Button
          className={styles.stepDown}
          {...stepDown} />
        <Button
          className={styles.stepUp}
          {...stepUp} />
      </div>
    </div>
  );
};

InputWithStepper.defaultProps = defaultProps;

export default InputWithStepper;
