import React, { useState } from 'react';
import { replaceNewlines } from '../../../lib/utils';
import { Question } from '../../../modules/types';
import { AnswerOptionProps, defaultProps as answerDefaultProps } from '../../molecules/AnswerOption/AnswerOption';
import { defaultProps as defaultValidationMessageProps, ValidationMessageProps } from '../../molecules/ValidationMessage/ValidationMessage';
import {
  QuestionItemProps, defaultProps, QuestionItemStateType,
} from './QuestionItem';

const withPresenter = (
  View: React.FC<QuestionItemProps>,
): React.FC<QuestionItemProps> => {
  const Presenter: React.FC<QuestionItemProps> = (props) => {
    const {
      questionItem,
      correctAnswerToggle,
      onEditAnswer,
      onDeleteAnswer,
      onDragAnswer,
      onDropAnswer,
      canDrag,
    } = props;

    const [showValidationMessages, setShowValidationMessages] = useState(false);

    const {
      index,
      editing,
      question,
      answers,
    } = questionItem as Question & { editing: false; index: number };

    const itemState: QuestionItemStateType = editing ? 'Edit' : 'View';

    const questionText = { ...defaultProps.question };
    questionText.value = replaceNewlines(`${index + 1}. ${question}`);

    let answerOptions: AnswerOptionProps[] = [];
    if (answers) {
      answerOptions = answers.map((answer, answerIndex): AnswerOptionProps => {
        return {
          ...answerDefaultProps,
          id: answer.id,
          onDrag: onDragAnswer,
          onDrop: onDropAnswer,
          canDrag,
          index: answerIndex,
          state: itemState,
          text: {
            ...answerDefaultProps.text,
            value: answer.answer || '',
          },
          checkbox: {
            ...answerDefaultProps.checkbox,
            state: answer.correct ? 'Checked' : 'Unchecked',
            icon: answer.correct ? { style: 'Blue' } : { style: 'Dark' },
            onCheckboxClicked: correctAnswerToggle(index, answerIndex),
          },
          input: {
            ...answerDefaultProps.input,
            textValue: answer.answer || '',
            onTextChanged: (
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
            ): void => {
              if (onEditAnswer) {
                onEditAnswer(event, answerIndex);
              }
            },
          },
          button: {
            ...answerDefaultProps.button,
            onButtonClicked: () => onDeleteAnswer ? onDeleteAnswer(answerIndex) : {},
          }
        };
      });
    }
    const answerOptionList = {
      ...defaultProps.answerOptionList,
      answerOptions,
    };

    const questionInput = {
      ...defaultProps.questionInput,
      ...props.questionInput,
    };
    questionInput.input = {
      ...questionInput.input,
      textValue: question,
    };
    questionInput.label = {
      ...defaultProps.questionInput.label,
      value: `Question ${index + 1}`,
    };

    const validationMessage: ValidationMessageProps = {
      ...defaultProps.validationMessage,
      ...props.validationMessage,
      state: showValidationMessages ? 'Expanded' : 'Collapsed',
      button: {
        ...defaultValidationMessageProps.button,
        onButtonClicked: (): void => setShowValidationMessages(!showValidationMessages),
        icon: {
          ...defaultValidationMessageProps.button.icon,
          asset: showValidationMessages ? 'CircleChevronUp' : 'CircleChevronDown',
        },
      },
    };

    const selectField = { ...defaultProps.selectField };

    return <View
      {...props}
      state={itemState}
      question={questionText}
      buttonGroup={props.buttonGroup}
      answerOptionList={answerOptionList}
      questionInput={questionInput}
      selectField={selectField}
      validationMessage={validationMessage}
      />;
  };

  return Presenter;
};

export default withPresenter;
