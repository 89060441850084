import React from 'react';
import cx from 'classnames';

import styles from './RadioButton.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type RadioButtonStateType = 'Default' | 'Selected';
export type RadioButtonRadioButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  state: 'Default' as RadioButtonStateType,
  icon: {
    asset: 'Vimeo',
    style: 'Default',
  } as IconProps,
  text: {
    type: 'Paragraph2',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Vimeo',
  } as TextProps,
};

export type RadioButtonProps = {
  state?: RadioButtonStateType;
  radioButtonType?: RadioButtonRadioButtonTypeType;
  onRadioButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  text?: TextProps;
  className?: string;
};

const RadioButton: React.FC<RadioButtonProps> = ({
  state,
  radioButtonType,
  onRadioButtonClicked,
  icon,
  text,
  className,
}) => {
  const currentStyle = styles[`radioButton${state}`];

  return (
    <button
      type={radioButtonType}
      onClick={onRadioButtonClicked}
      className={cx(currentStyle, className)}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.text}
        {...text} />
    </button>
  );
};

RadioButton.defaultProps = defaultProps;

export default RadioButton;
