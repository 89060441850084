import React from 'react';
import { QuestionItemProps } from './QuestionItem';

const withInteractor = (
  Presenter: React.FC<QuestionItemProps>,
): React.FC<QuestionItemProps> => {
  const Interactor: React.FC<QuestionItemProps> = (props) => {
    return (
      <Presenter
        {...props}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
