import React from 'react';
import cx from 'classnames';

import { Spinner } from 'react-bootstrap';
import styles from './Button.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type ButtonTypeType = 'IconButton' | 'TextButton' | 'IconTextButton';
export type ButtonSizeType = 'Large' | 'Medium' | 'Small';
export type ButtonFillType = 'Colour' | 'Basic' | 'White' | 'NoFill';
export type ButtonColourType = 'Brand' | 'Basic' | 'Danger';
export type ButtonButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  type: 'TextButton' as ButtonTypeType,
  size: 'Medium' as ButtonSizeType,
  fill: 'White' as ButtonFillType,
  colour: 'Basic' as ButtonColourType,
  icon: {
    asset: 'Plus',
    style: 'Dark',
  } as IconProps,
  text: {
    type: 'Paragraph3',
    style: 'Brand',
    size: 'Small',
    align: 'Center',
    value: 'Pressed',
  } as TextProps,
};

export type ButtonProps = {
  type?: ButtonTypeType;
  size?: ButtonSizeType;
  fill?: ButtonFillType;
  colour?: ButtonColourType;
  buttonType?: ButtonButtonTypeType;
  onButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  className?: string;
  text?: TextProps;
  disabled?: boolean;
  loading?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  type,
  size,
  fill,
  colour,
  buttonType,
  onButtonClicked,
  icon,
  className,
  text,
  disabled,
  loading,
}) => {
  const currentStyle = styles[`button${type}${size}${fill}${colour}`];

  let iconView;
  let textView;

  if (loading) {
    iconView = <Spinner className={styles.spinner} animation='border' />;
  } else {
    switch (type) {
      case 'IconButton':
        iconView = (
          <Icon
            className={styles.icon}
            {...icon} />
        );
        break;
      case 'TextButton':
        textView = (
          <Text
            className={styles.text}
            {...text} />
        );
        break;
      case 'IconTextButton':
        iconView = (
          <Icon
            className={styles.icon}
            {...icon} />
        );
        textView = (
          <Text
            className={styles.text}
            {...text} />
        );
        break;
    }
  }

  return (
    <button
      type={buttonType}
      onClick={onButtonClicked}
      disabled={disabled}
      className={cx(currentStyle, className)}>
      {iconView}
      {textView}
    </button>
  );
};

Button.defaultProps = defaultProps;

export default Button;
