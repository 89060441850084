import React from 'react';
import cx from 'classnames';

import styles from './QuestionItem.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import Text, { TextProps } from '../../atoms/Text';
import ButtonGroup, { ButtonGroupProps } from '../../molecules/ButtonGroup';
import AnswerOptionList, { AnswerOptionListProps } from '../../molecules/AnswerOptionList';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import SelectField, { SelectFieldProps } from '../../molecules/SelectField';
import Button, { ButtonProps } from '../../atoms/Button';
import ValidationMessage, { ValidationMessageProps } from '../../molecules/ValidationMessage';
import { Question } from '../../../modules/types';
import useDragAndDrop from '../../../lib/hooks/useDragAndDrop';
import TextAreaField, { TextAreaFieldProps } from '../../molecules/TextAreaField';

export type QuestionItemStateType = 'View' | 'Edit';

export const defaultProps = {
  state: 'Edit' as QuestionItemStateType,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  question: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Question goes here',
  } as TextProps,
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Edit',
      },
    },
    secondary: {
      type: 'IconButton',
      size: 'Large',
      fill: 'White',
      colour: 'Basic',
      icon: {
        asset: 'Close',
        style: 'Blue',
      },
    },
  } as ButtonGroupProps,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Options',
  } as TextProps,
  answerOptionList: {
    answerOptions: [
      {
        state: 'View',
        checkbox: {
          state: 'Unchecked',
          icon: {
            asset: 'Square',
            style: 'Dark',
          },
        },
        text: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Option',
        },
      },
      {
        state: 'View',
        checkbox: {
          state: 'Unchecked',
          icon: {
            asset: 'Square',
            style: 'Dark',
          },
        },
        text: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Option',
        },
      },
      {
        state: 'View',
        checkbox: {
          state: 'Checked',
          icon: {
            asset: 'SquareCheck',
            style: 'Blue',
          },
        },
        text: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Option',
        },
      },
    ],
  } as AnswerOptionListProps,
  questionInput: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Question 1',
    },
    input: {
      style: 'White',
      textPlaceholder: 'Question goes here',
    },
  } as InputFieldProps,
  selectField: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Answer type',
    },
    select: {
      text: {
        type: 'Paragraph1',
        style: 'Grey',
        size: 'Large',
        align: 'Left',
        value: 'Selected option',
      },
      icon: {
        asset: 'ChevronDown',
        style: 'Disabled',
      },
    },
  } as SelectFieldProps,
  addQuestionDescriptionButton: {
    type: 'IconTextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    icon: {
      asset: 'Plus',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Add question description',
    },
  } as ButtonProps,
  addOptionButton: {
    type: 'IconTextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    icon: {
      asset: 'Plus',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Add option',
    },
  } as ButtonProps,
  validationMessage: {
    state: 'Collapsed',
    checkbox: {
      state: 'Unchecked',
      icon: {
        asset: 'Square',
        style: 'Dark',
      },
    },
    text: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Show validation messages',
    },
    button: {
      type: 'IconButton',
      size: 'Small',
      fill: 'White',
      colour: 'Basic',
      icon: {
        asset: 'CircleChevronDown',
        style: 'Grey',
      },
    },
  } as ValidationMessageProps,
};

export type QuestionItemProps = {
  state?: QuestionItemStateType;
  divider?: DividerProps;
  question?: TextProps;
  buttonGroup?: ButtonGroupProps;
  label?: TextProps;
  answerOptionList?: AnswerOptionListProps;
  className?: string;
  questionInput?: TextAreaFieldProps;
  selectField?: SelectFieldProps;
  addQuestionDescriptionButton?: ButtonProps;
  addOptionButton?: ButtonProps;
  validationMessage?: ValidationMessageProps;
  questionItem?: Question & { editing: boolean; index: number };
  onEditAnswer?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
    index: number
  ) => void;
  onDeleteAnswer?: (
    index: number,
  ) => void;
  correctAnswerToggle: (
    questionIndex: number, answerIndex: number
  ) => (event?: React.MouseEvent<HTMLButtonElement>) => void;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  onDragAnswer?: (dragIndex: number, hoverIndex: number) => void;
  onDropAnswer?: (dragIndex: number, hoverIndex: number) => void;
  index?: number;
  id?: number;
  canDrag?: boolean;
};

const QuestionItem: React.FC<QuestionItemProps> = ({
  state,
  divider,
  question,
  buttonGroup,
  label,
  answerOptionList,
  className,
  questionInput,
  selectField,
  addQuestionDescriptionButton,
  addOptionButton,
  validationMessage,
  onDrop,
  onDrag,
  id,
  index,
  canDrag,
}) => {
  const currentStyle = styles[`questionItem${state}`];

  const { isDragging, ref, handlerId } = useDragAndDrop<HTMLDivElement>({
    id, index, onDrag, onDrop, direction: 'vertical', type: 'QuestionItem', canDrag,
  });

  const opacity = isDragging ? 0 : 1;

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );

  let topContentView;
  let questionContentView;
  let labelView;
  let answerOptionsView;
  let answerOptionListView;
  let addOptionButtonView;
  let validationMessageView;

  switch (state) {
    case 'View':
      topContentView = (
        <div className={styles.topContent}>
          <Text
            className={styles.question}
            {...question} />
          <ButtonGroup
            className={styles.buttonGroup}
            {...buttonGroup} />
        </div>
      );
      labelView = (
        <Text
          className={styles.label}
          {...label} />
      );
      answerOptionListView = (
        <AnswerOptionList
          className={styles.answerOptionList}
          {...answerOptionList} />
      );
      break;
    case 'Edit':
      questionContentView = (
        <div className={styles.questionContent}>
          <div className={styles.topContent}>
            <TextAreaField
              className={styles.questionInput}
              {...questionInput} />
            <SelectField
              className={styles.selectField}
              {...selectField} />
          </div>
          {/* <Button
            className={styles.addQuestionDescriptionButton}
            {...addQuestionDescriptionButton} /> */}
        </div>
      );
      answerOptionsView = (
        <div className={styles.answerOptions}>
          <Text
            className={styles.label}
            {...label} />
          <AnswerOptionList
            className={styles.answerOptionList}
            {...answerOptionList} />
        </div>
      );
      addOptionButtonView = (
        <Button
          className={styles.addOptionButton}
          {...addOptionButton} />
      );
      validationMessageView = (
        <ValidationMessage
          className={styles.validationMessage}
          {...validationMessage} />
      );
      break;
  }

  return (
    <div
      className={cx(currentStyle, className)}
      style={{ opacity }}
      data-handler-id={handlerId}
      ref={ref}
    >
      {dividerView}
      {topContentView}
      {questionContentView}
      {labelView}
      {answerOptionsView}
      {answerOptionListView}
      {addOptionButtonView}
      {validationMessageView}
    </div>
  );
};

QuestionItem.defaultProps = defaultProps;

export default QuestionItem;
