import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import { CoursesBlockProps, defaultProps } from './CoursesBlock';
import { CourseResponse, CreateCoursePayload } from '../../../modules/course/types';
import { CourseDetails, CourseStatus } from '../../../modules/types';
import { CourseCardProps, defaultProps as cardDefaultProps } from '../../molecules/CourseCard/CourseCard';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ApiResponse } from '../../../lib/types';
import useHandleDrag from '../../../lib/hooks/useHandleDrag';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../../../modules/common/types';
import useHandleReorderMany from '../../../lib/hooks/useHandleReorderMany';

export type CoursesBlockPresenterProps = CoursesBlockProps & {
  courseDetails: CourseDetails[];
  createCourse: (payload: CreateCoursePayload) => Promise<CourseResponse>;
  reorderCourse: (payload: ReorderEntityPayload) => Promise<ApiResponse<void>>;
  // TODO: Remove this function
  reorderCourses: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>;
  refetchCourses: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
};

const UnitTypeKeys: {[unitType: string]: string} = {
  lesson: 'course.metadata.num_lessons',
  quiz: 'course.metadata.num_quizzes',
  resource: 'course.metadata.num_resources',
  video: 'course.metadata.num_videos',
  html: 'course.metadata.num_htmls',
};
const UnitTypes = Object.keys(UnitTypeKeys);

const getUnitsString = (course: CourseDetails, t: TFunction): string => {
  const typeCounts: { [unitType: string]: number } = {
    lesson: course.lessons.length,
  };
  course.lessons.forEach((lesson) => {
    lesson.units.forEach(({ unitType }) => {
      typeCounts[unitType] = (typeCounts[unitType] ?? 0) + 1;
    });
  });

  const unitStrings: string[] = [];
  UnitTypes.forEach((unitType) => {
    const typeCount = typeCounts[unitType] || 0;
    if (typeCount !== 0) {
      unitStrings.push(t(UnitTypeKeys[unitType], { count: typeCount }));
    }
  });

  return unitStrings.join(' | ');
};

const getEstimatedTime = (estimatedTime: number, t: TFunction): string => {
  if (estimatedTime < 60) {
    return t('course.metadata.num_mins', { count: estimatedTime });
  }

  // Round estimated time to the closest hour
  const estimatedTimeInHours: number = Math.round(estimatedTime / 60);
  return t('course.metadata.num_hours', { count: estimatedTimeInHours });
};

const withPresenter = (View: React.FC<CoursesBlockProps>): React.FC<CoursesBlockPresenterProps> => {
  const Presenter: React.FC<CoursesBlockPresenterProps> = (props) => {
    const {
      courseDetails, createCourse, setError, error, refetchCourses, reorderCourses,
    } = props;
    const { t } = useTranslation();
    const {
      appId, serviceName,
    } = useParams<{ appId: string; serviceName: string }>();
    const history = useHistory();

    const [coursesState, setCoursesState] = useState<CourseDetails[]>([...courseDetails]);

    useEffect(() => {
      if (error) {
        setError({
          error,
          description: error?.message,
        });
      }
    }, [error, setError]);

    useEffect(() => {
      setCoursesState([
        ...courseDetails,
      ]);
    }, [courseDetails]);

    const handleDrag = useHandleDrag(coursesState, setCoursesState);
    // TODO: Change back to useHandleReorder
    const handleReorderCourse = useHandleReorderMany(coursesState, reorderCourses, refetchCourses);

    const handleCreateCourse = async (): Promise<void> => {
      const { data: course, error: createCourseError } = await createCourse({ description: '', title: 'Untitled Course' });
      if (!error && course) {
        history.push(`/${appId}/${serviceName}/course/${course.id}`);
      } else {
        // Dispatch error modal
        setError({
          error: createCourseError,
          description: t('error.modal.create_course.description'),
          primaryButton: {
            text: t('error.modal.create_course.button.primary'),
            onClicked: handleCreateCourse,
          },
        });
      }
    };

    const blockHeader = { ...defaultProps.blockHeader };
    blockHeader.text = {
      ...blockHeader.text,
      value: 'Courses',
    };
    blockHeader.button = {
      ...blockHeader.button,
      onButtonClicked: handleCreateCourse,
    };

    const courseCardList = { ...defaultProps.courseCardList };
    courseCardList.courseCards = coursesState.map((courseDetail, index): CourseCardProps => {
      const handleOpenCourse = (): void => {
        history.push(`/${appId}/${serviceName}/course/${courseDetail.id}`);
      };

      const units = getUnitsString(courseDetail, t);
      const estimatedTime = getEstimatedTime(courseDetail.estimatedTime, t);
      const validateCourseStatusPublish =
        courseDetail?.status === CourseStatus.PUBLISH;

      return {
        ...cardDefaultProps,
        title: {
          ...cardDefaultProps.title,
          value: courseDetail.title,
        },
        units: {
          ...cardDefaultProps.units,
          value: units,
        },
        estimatedTime: {
          ...cardDefaultProps.estimatedTime,
          value: estimatedTime,
        },
        description: {
          ...cardDefaultProps.description,
          value: courseDetail.description,
        },
        button: {
          ...cardDefaultProps.button,
          onButtonClicked: handleOpenCourse,
        },
        onDrag: handleDrag,
        onDrop: handleReorderCourse,
        id: courseDetail.id,
        index,
        status: {
          style: validateCourseStatusPublish ? 'Danger' : 'Brand',
          value: validateCourseStatusPublish
            ? t('course.status.caption.publish')
            : t('course.status.caption.draft'),
        },
        onClick: handleOpenCourse,
      };
    });

    return <View
      {...props}
      blockHeader={blockHeader}
      courseCardList={courseCardList} />;
  };

  return Presenter;
};

export default withPresenter;
