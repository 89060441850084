import { useCallback } from 'react';
import useDelete from '../../lib/api/useDelete';
import { deleteLesson } from './api';
import { UseDeleteLessonResult } from './types';

const useDeleteLesson = (): UseDeleteLessonResult => {
  const onDelete = useCallback((id: number): Promise<void> => deleteLesson(id), []);
  return useDelete(onDelete);
};

export default useDeleteLesson;
