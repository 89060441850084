import React from 'react';
import cx from 'classnames';

import styles from './UploadImageField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import UploadButton, { UploadButtonProps } from '../../atoms/UploadButton';
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup';

export type UploadImageFieldStateType = 'Default' | 'Uploaded';

export const defaultProps = {
  state: 'Uploaded' as UploadImageFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  uploadButton: {
    icon: {
      asset: 'UploadImage',
      style: 'Blue',
    },
    text: {
      type: 'Paragraph2',
      style: 'Grey',
      size: 'Medium',
      align: 'Left',
      value: 'Upload picture',
    },
  } as UploadButtonProps,
  uploadedImage: 'UploadedImageAsset',
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Replace',
      },
    },
    secondary: {
      type: 'TextButton',
      size: 'Medium',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Paragraph3',
        style: 'Brand',
        size: 'Small',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonGroupProps,
};

export type UploadImageFieldProps = {
  state?: UploadImageFieldStateType;
  label?: TextProps;
  uploadButton?: UploadButtonProps;
  className?: string;
  uploadedImage?: string;
  uploadedImageAlt?: string;
  buttonGroup?: ButtonGroupProps;
};

const UploadImageField: React.FC<UploadImageFieldProps> = ({
  state,
  label,
  uploadButton,
  className,
  uploadedImage,
  uploadedImageAlt,
  buttonGroup,
}) => {

  const currentStyle = styles[`uploadImageField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  
  let uploadButtonView;
  let uploadedImageView;
  let buttonContainerView;
  
  switch (state) {
    case 'Default':
      uploadButtonView = (
        <UploadButton
          className={styles.uploadButton}
          {...uploadButton} />
      );
      break;
    case 'Uploaded':
      uploadedImageView = (
        <img
          className={styles.uploadedImage}
          alt={uploadedImageAlt}
          src={uploadedImage} />
      );
      buttonContainerView = (
        <div className={styles.buttonContainer}>
          <ButtonGroup
            className={styles.buttonGroup}
            {...buttonGroup} />
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {uploadButtonView}
      {uploadedImageView}
      {buttonContainerView}
    </div>
  );
};

UploadImageField.defaultProps = defaultProps;

export default UploadImageField;
