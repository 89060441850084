import { useCallback } from 'react';

import usePatch from '../../lib/api/useUpdate';
import { ReorderEntityPayload } from '../common/types';

import { reorderUnit } from './api';

import { UseReorderUnitResult } from './types';

const useReorderUnit = (): UseReorderUnitResult => {
  const onReorder = useCallback((
    payload: ReorderEntityPayload,
  ): Promise<void> => reorderUnit(payload), []);
  return usePatch(onReorder);
};

export default useReorderUnit;
