import React from 'react';
import cx from 'classnames';

import styles from './MenuUnitList.module.scss';

import MenuUnit, { MenuUnitProps } from '../../atoms/MenuUnit';

export const defaultProps = {
  menuUnits: [
      {
        type: 'Default',
        icon: {
          asset: 'File',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Quiz',
        },
      },
      {
        type: 'Default',
        icon: {
          asset: 'File',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Video',
        },
      },
      {
        type: 'Default',
        icon: {
          asset: 'File',
          style: 'Grey',
        },
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Quiz',
        },
      },
  ] as MenuUnitProps[],
};

export type MenuUnitListProps = {
  menuUnits?: MenuUnitProps[];
  className?: string;
};

const MenuUnitList: React.FC<MenuUnitListProps> = ({
  menuUnits,
  className,
}) => {
  const menuUnitArray = menuUnits?.map((menuUnit, index) => (
    <MenuUnit
      key={menuUnit.id}
      className={styles.menuUnit}
      {...menuUnit} />
  ));
  
  return (
    <div className={cx(styles.menuUnitList, className)}>
      {menuUnitArray}
    </div>
  );
};

MenuUnitList.defaultProps = defaultProps;

export default MenuUnitList;
