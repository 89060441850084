import React, {
  useEffect, useMemo, useState,
} from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { MenuLessonItemProps, defaultProps } from './MenuLessonItem';
import {
  CreateUnitPayload, Unit, UnitResponse,
} from '../../../modules/unit/types';
import { MenuUnitListProps, defaultProps as menuUnitListDefaultProps } from '../MenuUnitList/MenuUnitList';
import { defaultProps as menuUnitDefaultProps, MenuUnitProps } from '../../atoms/MenuUnit/MenuUnit';
import { IconAssetType } from '../../atoms/Icon/Icon';
import { ApiResponse } from '../../../lib/types';
import useHandleDrag from '../../../lib/hooks/useHandleDrag';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../../../modules/common/types';
import useHandleReorderMany from '../../../lib/hooks/useHandleReorderMany';

export type MenuLessonPresenterProps = MenuLessonItemProps & {
  loading?: boolean;
  error?: Error;
  createUnit: (payload: CreateUnitPayload) => Promise<UnitResponse>;
  reorderUnit: (payload: ReorderEntityPayload) => Promise<ApiResponse<void>>;
  // TODO: Remove this function
  reorderUnits: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>;
  refetchCourse: () => void;
};

const withPresenter = (
  View: React.FC<MenuLessonItemProps>,
): React.FC<MenuLessonPresenterProps> => {
  const Presenter: React.FC<MenuLessonPresenterProps> = (props) => {
    const {
      createUnit, reorderUnit, refetchCourse, lesson, reorderUnits, ...otherProps
    } = props;
    const {
      appId, serviceName, courseId,
    } = useParams<{ appId: string; serviceName: string; courseId: string }>();
    const { pathname } = useLocation();
    const history = useHistory();

    const units = useMemo(
      () => (lesson ? cloneDeep(lesson).units : []), [lesson],
    );

    const [menuUnitsState, setMenuUnitsState] = useState<Unit[]>([]);

    useEffect(() => {
      if (units) {
        setMenuUnitsState([
          ...units,
        ]);
      }
    }, [units]);

    const handleDrag = useHandleDrag(menuUnitsState, setMenuUnitsState);
    // TODO: Change back to useHandleReorder
    const handleReorderUnit = useHandleReorderMany(menuUnitsState, reorderUnits, refetchCourse);

    const lessonId = lesson.id;
    const basePath = `/${appId}/${serviceName}/course/${courseId}/lesson/${lessonId}`;

    const menuLesson = { ...defaultProps.menuLesson };
    const lessonSelected = basePath === pathname;
    menuLesson.type = lessonSelected ? 'Selected' : 'Default';
    menuLesson.state = 'Expanded'; // TODO add handling state
    menuLesson.text = {
      ...menuLesson.text,
      style: lessonSelected ? 'Danger' : 'Dark',
      value: lesson.title || '',
    };
    menuLesson.onMenuLessonClicked = (): void => {
      if (!lessonSelected) {
        history.push(basePath);
      }
    };

    const menuUnits: MenuUnitProps[] = menuUnitsState.map((unit, unitIndex): MenuUnitProps => {
      let unitPagePath = '';
      let iconAsset: IconAssetType | undefined;
      if (!unit) {
        return {};
      }
      switch (unit.unitType) {
        case 'video':
          unitPagePath = `${basePath}/video/${unit.id}`;
          iconAsset = 'Video';
          break;
        case 'quiz':
          unitPagePath = `${basePath}/quiz/${unit.id}`;
          iconAsset = 'Book';
          break;
        case 'resource':
          unitPagePath = `${basePath}/resource/${unit.id}`;
          iconAsset = 'File';
          break;
        case 'html':
          unitPagePath = `${basePath}/html/${unit.id}`;
          iconAsset = 'Code';
          break;
        default:
          break;
      }

      return {
        ...menuUnitDefaultProps,
        type: unitPagePath === pathname ? 'Selected' : 'Default',
        icon: {
          ...menuUnitDefaultProps.icon,
          asset: iconAsset,
        },
        text: {
          ...menuUnitDefaultProps.text,
          value: unit.title,
        },
        onMenuUnitClicked: (): void => {
          if (pathname !== unitPagePath) {
            history.push(unitPagePath);
          }
        },
        onDrag: handleDrag,
        onDrop: handleReorderUnit,
        id: unit.id,
        index: unitIndex,
      };
    });

    const menuUnitList = { ...defaultProps.menuUnitList };
    menuUnitList.menuUnits = menuUnits;

    const handleCreateResourceUnit = async (): Promise<void> => {
      const { data: unit } = await createUnit({
        unitType: 'resource',
        description: '',
        title: 'Untitled Resource',
        canSkip: true,
        estimatedTime: 0,
        contentType: '',
        contentButton: '',
        contentUrl: '',
      });
      if (unit) {
        refetchCourse();
        history.push(`${basePath}/resource/${unit.id}`);
      } else {
        // TODO add error handling
      }
    };

    const handleCreateVideoUnit = async (): Promise<void> => {
      const { data: unit } = await createUnit({
        unitType: 'video',
        description: '',
        title: 'Untitled Video',
        canSkip: true,
        estimatedTime: 0,
        videoType: '',
        videoUrl: '',
        videoLength: 0,
      });
      if (unit) {
        refetchCourse();
        history.push(`${basePath}/video/${unit.id}`);
      } else {
        // TODO add error handling
      }
    };
    const handleCreateQuizUnit = async (): Promise<void> => {
      const { data: unit } = await createUnit({
        unitType: 'quiz',
        description: '',
        title: 'Untitled Quiz',
        canSkip: true,
        estimatedTime: 0,
      });
      if (unit) {
        refetchCourse();
        history.push(`${basePath}/quiz/${unit.id}`);
      } else {
        // TODO add error handling
      }
    };

    const handleCreateHtmlUnit = async (): Promise<void> => {
      const { data: unit } = await createUnit({
        unitType: 'html',
        description: '',
        title: 'Untitled Html',
        text: '',
        canSkip: true,
        estimatedTime: 0,
      });
      if (unit) {
        refetchCourse();
        history.push(`${basePath}/html/${unit.id}`);
      } else {
        // TODO add error handling
      }
    };

    const addUnitList: MenuUnitListProps = {
      ...menuUnitListDefaultProps,
      menuUnits: [
        {
          ...menuUnitDefaultProps,
          icon: {
            ...menuUnitDefaultProps.icon,
            asset: 'File',
          },
          text: {
            ...menuUnitDefaultProps.text,
            value: 'Add Resource',
          },
          onMenuUnitClicked: handleCreateResourceUnit,
        },
        {
          ...menuUnitDefaultProps,
          icon: {
            ...menuUnitDefaultProps.icon,
            asset: 'Video',
          },
          text: {
            ...menuUnitDefaultProps.text,
            value: 'Add Video',
          },
          onMenuUnitClicked: handleCreateVideoUnit,
        },
        {
          ...menuUnitDefaultProps,
          icon: {
            ...menuUnitDefaultProps.icon,
            asset: 'Book',
          },
          text: {
            ...menuUnitDefaultProps.text,
            value: 'Add Quiz',
          },
          onMenuUnitClicked: handleCreateQuizUnit,
        },
        {
          ...menuUnitDefaultProps,
          icon: {
            ...menuUnitDefaultProps.icon,
            asset: 'Code',
          },
          text: {
            ...menuUnitDefaultProps.text,
            value: 'Add Html',
          },
          onMenuUnitClicked: handleCreateHtmlUnit,
        },
      ],
    };

    return <View
      {...otherProps}
      lesson={lesson}
      menuLesson={menuLesson}
      menuUnitList={menuUnitList}
      addUnitList={addUnitList} />;
  };

  return Presenter;
};

export default withPresenter;
