import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntityPayload } from '../common/types';
import { reorderCourse } from './api';
import { UseReorderCourseResult } from './types';

const useReorderCourse = (): UseReorderCourseResult => {
  const onReorder = useCallback((
    payload: ReorderEntityPayload,
  ): Promise<void> => reorderCourse(payload), []);
  return usePatch(onReorder);
};

export default useReorderCourse;
