import React from 'react';
import cx from 'classnames';

import styles from './ResourceEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import TextAreaField, { TextAreaFieldProps } from '../../molecules/TextAreaField';
import InputWithStepperField, { InputWithStepperFieldProps } from '../../molecules/InputWithStepperField';
import RadioButtonList, { RadioButtonListProps } from '../../organisms/RadioButtonList';
import ResourceUploadSection, { ResourceUploadSectionProps } from '../../organisms/ResourceUploadSection';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';

export const defaultProps = {
  blockHeader: {
    type: 'Default',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Resource',
    },
  } as BlockHeaderProps,
  name: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Resource name',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Resource',
    },
  } as InputFieldProps,
  description: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Description',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
  resourceType: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Resource type',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input Text',
    },
  } as InputFieldProps,
  estimatedTime: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Estimated time (In minutes)',
    },
    inputWithStepper: {
      input: {
        style: 'Grey',
        textPlaceholder: '00',
      },
      stepDown: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronUp',
          style: 'Dark',
        },
      },
      stepUp: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
    },
  } as InputWithStepperFieldProps,
  radioButtonList: {
    radioButtons: [
        {
          icon: {
            asset: 'ExternalLink',
            style: 'Blue',
          },
          text: {
            type: 'Paragraph2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'External link',
          },
        },
        {
          icon: {
            asset: 'Upload',
            style: 'Blue',
          },
          text: {
            type: 'Paragraph2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Upload file',
          },
        },
        {
          icon: {
            asset: 'Upload',
            style: 'Blue',
          },
          text: {
            type: 'Paragraph2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Upload',
          },
        },
    ],
  } as RadioButtonListProps,
  buttonLink: {
    type: 'Default',
    videoUrl: {
      state: 'Default',
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Resource link',
      },
      input: {
        style: 'Grey',
        textPlaceholder: 'Input text',
      },
    },
  } as ResourceUploadSectionProps,
  buttonText: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Button text',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input Text',
    },
  } as InputFieldProps,
  buttonSection: {
    state: 'WithDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
    tertiary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonSectionProps,
};

export type ResourceEditBlockProps = {
  blockHeader?: BlockHeaderProps;
  name?: InputFieldProps;
  description?: TextAreaFieldProps;
  resourceType?: InputFieldProps;
  estimatedTime?: InputWithStepperFieldProps;
  radioButtonList?: RadioButtonListProps;
  buttonLink?: ResourceUploadSectionProps;
  buttonText?: InputFieldProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
};

const ResourceEditBlock: React.FC<ResourceEditBlockProps> = ({
  blockHeader,
  name,
  description,
  resourceType,
  estimatedTime,
  radioButtonList,
  buttonLink,
  buttonText,
  buttonSection,
  className,
}) => {
  return (
    <div className={cx(styles.resourceEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <InputField
          className={styles.name}
          {...name} />
        <TextAreaField
          className={styles.description}
          {...description} />
        <InputField
          className={styles.resourceType}
          {...resourceType} />
        <InputWithStepperField
          className={styles.estimatedTime}
          {...estimatedTime} />
        <RadioButtonList
          className={styles.radioButtonList}
          {...radioButtonList} />
        <ResourceUploadSection
          className={styles.buttonLink}
          {...buttonLink} />
        <InputField
          className={styles.buttonText}
          {...buttonText} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
      </div>
    </div>
  );
};

ResourceEditBlock.defaultProps = defaultProps;

export default ResourceEditBlock;
