import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../common/types';
import { Answer, CreateAnswerPayload } from './types';

export const postAnswer = async (payload: CreateAnswerPayload): Promise<Answer> => {
  try {
    const { questionId, answer } = payload;
    const { data } = await axios.post<Answer>(
      `${getServerUrl()}/questions/${questionId}/answers`,
      answer,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderAnswer = async (
  payload: ReorderEntityPayload,
): Promise<void> => {
  const { id, order } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/answers/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderAnswers = async (
  payload: ReorderEntitiesPayload,
): Promise<void> => {
  const { ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/answers/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
