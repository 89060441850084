import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { updateCourse } from './api';
import { Course, UpdateCoursePayload, UseUpdateCourseResult } from './types';

const useUpdateCourse = (id: number): UseUpdateCourseResult => {
  const update = useCallback((
    payload: UpdateCoursePayload,
  ): Promise<Course> => updateCourse(id, payload), [id]);
  return useUpdate(update);
};

export default useUpdateCourse;
