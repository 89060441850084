declare global {
  interface Window {
    env: any;
  }
}
const windowEnv = window.env || {};
// eslint-disable-next-line import/prefer-default-export
export const {
  REACT_APP_LMS_API_URL: LMS_API_URL = windowEnv.REACT_APP_LMS_API_URL,

  REACT_APP_ADMIN_URL: ADMIN_URL = windowEnv.REACT_APP_ADMIN_URL,
  REACT_APP_ADMIN_LOGIN_URL: ADMIN_LOGIN_URL = windowEnv.REACT_APP_ADMIN_LOGIN_URL,
  REACT_APP_MEDIA_API_URL: MEDIA_API_URL = windowEnv.REACT_APP_MEDIA_API_URL,
  REACT_APP_ADMIN_URL_TEMPLATE: ADMIN_URL_TEMPLATE = windowEnv.REACT_APP_ADMIN_URL_TEMPLATE || 'https://app.{appName}.spindl.dev',
} = process.env;

export const MEDIA_IMAGE_FIELD_NAME = 'image_upload';
export const MEDIA_FILE_FIELD_NAME = 'file_upload';

export const IS_DEV = ADMIN_LOGIN_URL === '' || ADMIN_LOGIN_URL === undefined;
