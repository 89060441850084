import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../common/types';
import {
  CreateUnitPayload, Unit, UpdateUnitPayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const getUnits = async (id: number): Promise<Unit[]> => {
  try {
    const { data } = await axios.get<Unit[]>(
      `${getServerUrl()}/lessons/${id}/units`,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const postUnit = async (
  lessonId: number, payload: CreateUnitPayload,
): Promise<Unit> => {
  try {
    const { data } = await axios.post<Unit>(
      `${getServerUrl()}/lessons/${lessonId}/units`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const updateUnit = async (
  id: number, payload: UpdateUnitPayload,
): Promise<Unit> => {
  try {
    const { data } = await axios.patch<Unit>(
      `${getServerUrl()}/units/${id}`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const deleteUnit = async (
  id: number,
): Promise<void> => {
  try {
    await axios.delete<void>(
      `${getServerUrl()}/units/${id}`,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderUnit = async (
  payload: ReorderEntityPayload,
): Promise<void> => {
  const { id, order } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/units/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderUnits = async (
  payload: ReorderEntitiesPayload,
): Promise<void> => {
  const { ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/units/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
