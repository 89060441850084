import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postLesson } from './api';
import { Lesson, CreateLessonPayload, UseCreateLessonResult } from './types';

const useCreateLesson = (courseId: number): UseCreateLessonResult => {
  const post = useCallback((
    payload: CreateLessonPayload,
  ): Promise<Lesson> => postLesson(courseId, payload), [courseId]);
  return usePost(post);
};

export default useCreateLesson;
