import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postQuestion } from './api';
import { Question, CreateQuestionPayload, UseCreateQuestionResult } from './types';

const useCreateQuestion = (): UseCreateQuestionResult => {
  const post = useCallback((
    payload: CreateQuestionPayload,
  ): Promise<Question> => postQuestion(payload), []);
  return usePost(post);
};

export default useCreateQuestion;
