import React from 'react';
import cx from 'classnames';

import styles from './MainLayout.module.scss';

import Header, { HeaderProps } from '../../organisms/Header';
import MainMenu, { MainMenuProps } from '../../blocks/MainMenu';
import CoursesPage, { CoursesPageProps } from '../../pages/CoursesPage';
import { Route } from 'react-router-dom';
import TagsPage, { TagsPageProps } from '../../pages/TagsPage';

export const defaultProps = {
  header: {
    logo: {
    },
    headerContent: {
      appsIcon: {
        asset: 'MoreApps',
        style: 'Dark',
      },
      notificationIcon: {
        asset: 'BellDot',
        style: 'Dark',
      },
      userProfile: {
        userIcon: {
          type: 'Image',
        },
        arrowIcon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
    },
  } as HeaderProps,
  mainMenu: {
    mainMenuHeader: {
      text: {
        type: 'Subtitle1',
        style: 'Dark',
        size: 'Medium',
        align: 'Left',
        value: 'Vericent LMS',
      },
    },
    divider: {
      style: 'Horizontal',
    },
    mainMenuItemList: {
      mainMenuItems: [
          {
            type: 'Default',
            icon: {
              asset: 'Grid',
              style: 'Dark',
            },
            text: {
              type: 'Subtitle2',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Courses',
            },
          },
          {
            type: 'Selected',
            icon: {
              asset: 'Grid',
              style: 'Dark',
            },
            text: {
              type: 'Subtitle2',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Courses',
            },
          },
          {
            type: 'Default',
            icon: {
              asset: 'Grid',
              style: 'Dark',
            },
            text: {
              type: 'Subtitle2',
              style: 'Dark',
              size: 'Medium',
              align: 'Left',
              value: 'Courses',
            },
          },
      ],
    },
  } as MainMenuProps,
  page: {
    coursesBlock: {
      blockHeader: {
        type: 'WithButton',
        text: {
          type: 'Title1',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Block header',
        },
        button: {
          type: 'TextButton',
          size: 'Large',
          fill: 'Colour',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'White',
            size: 'Medium',
            align: 'Center',
            value: 'Create Course',
          },
        },
      },
      courseCardList: {
        courseCards: [
            {
              title: {
                type: 'Title3',
                style: 'Dark',
                size: 'Medium',
                align: 'Left',
                value: 'How to Build a Compelling Competitve Strategy',
              },
              units: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '5 Lessons | 2 Quiz | 2 Videos',
              },
              estimatedTime: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '4 Hours',
              },
              description: {
                type: 'Paragraph3',
                style: 'Dark',
                size: 'Small',
                align: 'Left',
                value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
              },
              button: {
                type: 'TextButton',
                size: 'Medium',
                fill: 'White',
                colour: 'Brand',
                text: {
                  type: 'Paragraph3',
                  style: 'Brand',
                  size: 'Small',
                  align: 'Center',
                  value: 'Open course',
                },
              },
            },
            {
              title: {
                type: 'Title3',
                style: 'Dark',
                size: 'Medium',
                align: 'Left',
                value: 'How to Build a Compelling Competitve Strategy',
              },
              units: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '5 Lessons | 2 Quiz | 2 Videos',
              },
              estimatedTime: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '4 Hours',
              },
              description: {
                type: 'Paragraph3',
                style: 'Dark',
                size: 'Small',
                align: 'Left',
                value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
              },
              button: {
                type: 'TextButton',
                size: 'Medium',
                fill: 'White',
                colour: 'Brand',
                text: {
                  type: 'Paragraph3',
                  style: 'Brand',
                  size: 'Small',
                  align: 'Center',
                  value: 'Open course',
                },
              },
            },
            {
              title: {
                type: 'Title3',
                style: 'Dark',
                size: 'Medium',
                align: 'Left',
                value: 'How to Build a Compelling Competitve Strategy',
              },
              units: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '5 Lessons | 2 Quiz | 2 Videos',
              },
              estimatedTime: {
                type: 'Paragraph3',
                style: 'Grey',
                size: 'Small',
                align: 'Left',
                value: '4 Hours',
              },
              description: {
                type: 'Paragraph3',
                style: 'Dark',
                size: 'Small',
                align: 'Left',
                value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
              },
              button: {
                type: 'TextButton',
                size: 'Medium',
                fill: 'White',
                colour: 'Brand',
                text: {
                  type: 'Paragraph3',
                  style: 'Brand',
                  size: 'Small',
                  align: 'Center',
                  value: 'Open course',
                },
              },
            },
        ],
      },
    },
  } as CoursesPageProps,
};

export type MainLayoutProps = {
  header?: HeaderProps;
  mainMenu?: MainMenuProps;
  coursesPage?: CoursesPageProps;
  tagsPage?: TagsPageProps;
  className?: string;
};

const MainLayout: React.FC<MainLayoutProps> = ({
  header,
  mainMenu,
  coursesPage,
  tagsPage,
  className,
}) => {
  return (
    <div className={cx(styles.mainLayout, className)}>
      <Header
        className={styles.header}
        {...header} />
      <div className={styles.body}>
        <MainMenu
          className={styles.mainMenu}
          {...mainMenu} />
        <div className={styles.content}>
          <Route exact path='/:appId/:serviceName/tags/'>
            <TagsPage
              className={styles.page}
              {...tagsPage} />
          </Route>
          <Route exact path='/:appId/:serviceName/'>
            <CoursesPage
              className={styles.page}
              {...coursesPage} />
          </Route>
        </div>
      </div>
    </div>
  );
};

MainLayout.defaultProps = defaultProps;

export default MainLayout;
