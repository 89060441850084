import React from 'react';
import cx from 'classnames';

import styles from './AnswerOptionList.module.scss';

import AnswerOption, { AnswerOptionProps } from '../AnswerOption';

export const defaultProps = {
  answerOptions: [
    {
      state: 'Edit',
      checkbox: {
        state: 'Unchecked',
        icon: {
          asset: 'Square',
          style: 'Dark',
        },
      },
      input: {
        style: 'White',
        textPlaceholder: 'Input text',
      },
      button: {
        type: 'IconButton',
        size: 'Medium',
        fill: 'White',
        colour: 'Danger',
        icon: {
          asset: 'MoreVertical',
          style: 'Dark',
        },
      },
    },
    {
      state: 'Edit',
      checkbox: {
        state: 'Unchecked',
        icon: {
          asset: 'Square',
          style: 'Dark',
        },
      },
      input: {
        style: 'White',
        textPlaceholder: 'Input text',
      },
      button: {
        type: 'IconButton',
        size: 'Medium',
        fill: 'White',
        colour: 'Danger',
        icon: {
          asset: 'MoreVertical',
          style: 'Dark',
        },
      },
    },
    {
      state: 'Edit',
      checkbox: {
        state: 'Unchecked',
        icon: {
          asset: 'Square',
          style: 'Dark',
        },
      },
      input: {
        style: 'White',
        textPlaceholder: 'Input text',
      },
      button: {
        type: 'IconButton',
        size: 'Medium',
        fill: 'White',
        colour: 'Danger',
        icon: {
          asset: 'MoreVertical',
          style: 'Dark',
        },
      },
    },
  ] as AnswerOptionProps[],
};

export type AnswerOptionListProps = {
  answerOptions?: AnswerOptionProps[];
  className?: string;
};

const AnswerOptionList: React.FC<AnswerOptionListProps> = ({
  answerOptions,
  className,
}) => {
  const answerOptionArray = answerOptions?.map((answerOption) => (
    <AnswerOption
      key={answerOption.id}
      className={styles.first}
      {...answerOption} />
  ));

  return (
    <div className={cx(styles.answerOptionList, className)}>
      {answerOptionArray}
    </div>
  );
};

AnswerOptionList.defaultProps = defaultProps;

export default AnswerOptionList;
