import React, { useState } from 'react';

export type CourseEditContextValue = {
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultValue = {
  isEditing: false,
  setIsEditing: () => {},
};

export const CourseEditContext = React.createContext<CourseEditContextValue>(
  defaultValue,
);

const CourseEditContextProvider: React.FC<{}> = (props) => {
  const { children } = props;

  const [isEditing, setIsEditing] = useState(false);

  return (
    <CourseEditContext.Provider
      value={{
        isEditing,
        setIsEditing,
      }}
    >
      {children}
    </CourseEditContext.Provider>
  );
};

export default CourseEditContextProvider;
