import React from 'react';
import { useParams } from 'react-router-dom';
import useCourseDetails from '../../../../modules/course/useCourseDetails';
import { CourseDetails } from '../../../../modules/types';

export type CourseDetailsContextValue = {
  courseState: {
    loading: boolean;
    error: Error | undefined;
    data: CourseDetails | null;
    refetch: () => void;
  };
};

const defaultValue = {
  courseState: {
    loading: false,
    error: undefined,
    data: null,
    refetch: (): void => {},
  },
};

export const CourseDetailsContext = React.createContext<CourseDetailsContextValue>(
  defaultValue,
);

const CourseDetailsContextProvider: React.FC<{}> = (props) => {
  const { children } = props;
  const { courseId } = useParams<{ courseId: string }>();
  const courseState = useCourseDetails(Number(courseId));
  const value = {
    courseState,
  };

  return (
    <CourseDetailsContext.Provider value={value}>
      {children}
    </CourseDetailsContext.Provider>
  );
};

export default CourseDetailsContextProvider;
