import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { CourseEditBlockProps, defaultProps } from './CourseEditBlock';
import {
  Course, CourseResponse, UpdateCoursePayload, UpdateCourseTagPayload,
} from '../../../modules/course/types';
import { MEDIA_IMAGE_FIELD_NAME } from '../../../lib/config';
import UploadField from '../../molecules/UploadField';
import { ApiResponse } from '../../../lib/api/types';
import { getBasePath, getMediaServiceResourceUrl } from '../../../lib/api/utils';
import { setNavigateAwayConfirmationModal, toBase64 } from '../../../lib/utils';
import { ImageMetadata } from '../../../modules/common/types';
import { TagSectionItemProps, defaultProps as defaultTagSectionProps } from '../../organisms/TagSectionItem/TagSectionItem';
import { InputChipsFieldProps, defaultProps as defaultInputChipsFieldProps, OptionItem } from '../../molecules/InputChipsField/InputChipsField';
import { CourseDetails, Tag } from '../../../modules/types';
import { groupTags } from '../../../modules/tags/utils';
import { QUILL_EMPTY_FIELD } from '../../molecules/HtmlEditor/HtmlEditor';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';

export type CourseEditBlockPresenterProps = CourseEditBlockProps & {
  data: CourseDetails | null;
  allTags: Tag[] | null;
  updateCourse: (payload: UpdateCoursePayload) => Promise<CourseResponse>;
  deleteCourse: (id: number) => Promise<ApiResponse<void>>;
  uploadImage: (payload: FormData) => Promise<{ data: ImageMetadata | null; error?: Error }>;
  addCourseTag: (payload: UpdateCourseTagPayload) => Promise<ApiResponse<void>>;
  removeCourseTag: (payload: UpdateCourseTagPayload) => Promise<ApiResponse<void>>;
  refetchCourse: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
};

type CourseState = {
  title?: string;
  description?: string;
  imageUrl?: string;
  html?: string;
  tagIds?: number[];
};

const isCourseModified = (originalCourse: CourseState, newCourse: CourseState): boolean => {
  return !deepEqual(originalCourse, newCourse);
};

const getTagIds = (tags: Tag[] | undefined): number[] => {
  if (tags) {
    return tags.map((tag) => tag.id);
  }
  return [];
};

const toCourseState = (data: CourseDetails | null): CourseState => {
  return {
    title: data?.title || '',
    description: data?.description || '',
    imageUrl: data?.imageUrl || '',
    html: data?.html,
    tagIds: getTagIds(data?.tags),
  };
};

const withPresenter = (
  View: React.ForwardRefExoticComponent<
  CourseEditBlockProps & React.RefAttributes<HTMLInputElement>
  >,
): React.FC<CourseEditBlockPresenterProps> => {
  const Presenter: React.FC<CourseEditBlockPresenterProps> = (props) => {
    const {
      data,
      allTags,
      updateCourse,
      setError,
      setConfirmation,
      refetchCourse,
      uploadImage,
      deleteCourse,
      addCourseTag,
      removeCourseTag,
      setIsEditing,
    } = props;
    const history = useHistory();
    const course = useMemo(() => cloneDeep(data), [data]);

    const ref = useRef<HTMLInputElement>(null);

    const [courseState, setCourseState] = useState<CourseState>(toCourseState(course));
    const [image, setImage] = useState<File>();
    const [imageSrc, setImageSrc] = useState(course?.imageUrl);

    const courseModified = isCourseModified(toCourseState(data), courseState)

    const { t } = useTranslation();
    const { title, description, tagIds } = courseState;
    const tagGroups = groupTags(allTags || []);

    const areChanges = (!!(course && isCourseModified(toCourseState(data), courseState)) || !!image);

    useEffect(() => {
      // When data is loaded, update course state and set image src for preview field
      setCourseState(toCourseState(course));
      setImageSrc(course?.imageUrl);
    }, [course]);

    useEffect(() => {
      // When new image selected, encode the image and set image src for preview field
      if (image) {
        toBase64(image).then((url) => {
          setImageSrc(url);
        }).catch();
      }
    }, [image]);

    const handleResetImage = (): void => {
      if (ref.current) {
        ref.current.value = '';
        setImage(undefined);
      }
    };

    const handleUploadImage = (): void => {
      if (ref.current) {
        ref.current.click();
      }
    };

    useEffect(() => {
      setIsEditing(courseModified)
    }, [courseModified])

    // When image selected from file input, save image to state
    const handleChangeImage = (event?: React.ChangeEvent<HTMLInputElement>): void => {
      if (event) {
        const { files } = event?.currentTarget;
        if (files) {
          setImage(files[0]);
        }
      }
    };

    /**
     * When delete button is pressed, set image state to undefined
     * Course state image url must be empty string so that the url gets overwritten in the database
     */
    const handleDeleteImage = (): void => {
      setImage(undefined);
      setImageSrc(undefined);
      setCourseState({ ...courseState, imageUrl: '' });
      if (ref.current) {
        ref.current.value = '';
      }
    };

    // Make a request to save the image in the media service
    const handleSaveImage = async (imageFile: File): Promise<ImageMetadata> => {
      const formData = new FormData();
      formData.append(MEDIA_IMAGE_FIELD_NAME, imageFile);
      formData.append('public', 'true');
      const imageMetadata = (await uploadImage(formData)).data;
      if (!imageMetadata) {
        throw new Error();
      }
      return imageMetadata;
    };

    // Make a request to save course metadata in lms backend
    const handleSaveCourse = async (payload: UpdateCoursePayload): Promise<Course | null> => {
      const { data: courseResponse } = await updateCourse(payload);
      if (!courseResponse) {
        throw new Error();
      }
      return courseResponse;
    };

    const handleUpdateCourseTags = async (): Promise<void> => {
      if (tagIds && allTags) {
        const currentTagIds = getTagIds(course?.tags);
        const newTags = allTags.filter((tag) => {
          return tag.id && tagIds.includes(tag.id) && !currentTagIds.includes(tag.id);
        });

        for (let i = 0; i < newTags.length; i += 1) {
          await addCourseTag({ tagId: newTags[i].id });
        }

        const deletedTagIds = currentTagIds.filter((tagId) => !tagIds.includes(tagId));

        for (let i = 0; i < deletedTagIds.length; i += 1) {
          await removeCourseTag({ tagId: deletedTagIds[i] });
        }
      }
    };

    const handleUpdateCourse = async (): Promise<void> => {
      let imageUrl;
      try {
        if (image) {
          // If file added, upload file to S3
          imageUrl = (await handleSaveImage(image)).url;
        } else if (imageSrc !== course?.imageUrl) {
          // If the image has been deleted, set imageUrl to empty string
          imageUrl = '';
        }
        handleResetImage();
        // Update course with image url from media service
        await handleSaveCourse({ ...courseState, imageUrl });
        await handleUpdateCourseTags();
        refetchCourse();
        setError(undefined);
      } catch (err) {
        // Dispatch error modal
        setError({
          error: err,
          title: t('error.modal.update_course.title'),
          description: t('error.modal.update_course.description'),
          primaryButton: {
            text: t('error.modal.update_course.button.primary'),
            onClicked: handleUpdateCourse,
          },
        });
      }
    };

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_course.description', { courseName: course?.title }),
        primaryButton: {
          onClicked: handleDeleteCourse
        }
      })
    };

    const handleDeleteCourse = async (): Promise<void> => {
      if (!course) {
        return;
      }
      const { error } = await deleteCourse(course.id);
      if (error) {
        // Dispatch error modal
        setConfirmation(undefined);
        setError({
          error,
          title: t('error.modal.delete_course.title'),
          description: t('error.modal.delete_course.description'),
          primaryButton: {
            text: t('error.modal.delete_course.button.primary'),
            onClicked: handleDeleteCourse,
          },
        });
      } else {
        setConfirmation(undefined);
        history.replace(`${getBasePath()}`);
      }
    };

    // Reset course/image state
    const discardChanges = (): void => {
      setCourseState(toCourseState(data));
      if (image) {
        setImage(undefined);
        setImageSrc(course?.imageUrl);
        if (ref.current) {
          ref.current.value = '';
        }
      }
    };

    // Get change handler for course input fields
    const getChangeHandler = (property: keyof CourseState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setCourseState({
          ...courseState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const handleUpdateHtml = (value: string): void => {
      /**
       * If the input is empty we will set state to empty string instead of ReactQuill's default
       * If the update is coming as a result of a discard, i.e. the value is the original value
       * or an empty input and the unit is currently updated
       */
      if (!((value === data?.html || value === QUILL_EMPTY_FIELD) && courseModified)) {
        setCourseState({ ...courseState, html: value === QUILL_EMPTY_FIELD ? '' : value });
      }
    };

    const handleChangeTags = (
      tagGroupName: string, tagTypeName: string, selectedOptions: OptionItem[],
    ): void => {
      const otherTagIds = allTags?.filter((
        tag,
      ) => {
        return (tag.tagGroup !== tagGroupName || tag.tagType !== tagTypeName)
          && tagIds?.includes(tag.id);
      }).map((tag) => tag.id) || [];

      const newTagIds = selectedOptions.map((option) => option.id || -1);

      setCourseState({
        ...courseState,
        tagIds: [...otherTagIds, ...newTagIds],
      });
    };

    const remappedImgSrc = getMediaServiceResourceUrl(imageSrc);

    let tagSectionItems: TagSectionItemProps[] = [];
    if (tagGroups) {
      tagSectionItems = Object.keys(tagGroups).map((tagGroupName): TagSectionItemProps => {
        const tagGroup = tagGroups[tagGroupName];
        const tagFields = Object.keys(tagGroup).map((tagTypeName): InputChipsFieldProps => {
          const options = tagGroup[tagTypeName]
            .filter((tag) => {
              return tag.tagValue;
            })
            .map((tag) => {
              return {
                id: tag.id,
                value: tag.tagValue || '',
              };
            });
          const selectedOptions = options
            .filter((option) => {
              return tagIds && option.id && tagIds?.includes(option.id);
            });

          return {
            ...defaultInputChipsFieldProps,
            allowNew: false,
            multiSelect: true,
            options,
            selectedOptions,
            onOptionSelected: (selected): void => {
              handleChangeTags(tagGroupName, tagTypeName, selected);
            },
            label: {
              ...defaultInputChipsFieldProps.label,
              value: tagTypeName,
            },
          };
        });
        return {
          ...defaultTagSectionProps,
          sectionHeader: {
            ...defaultTagSectionProps.sectionHeader,
            text: {
              ...defaultTagSectionProps.sectionHeader.text,
              value: tagGroupName,
            },
          },
          tagTypeList: {
            ...defaultTagSectionProps.tagTypeList,
            chipsFields: tagFields,
          },
        };
      });
    }

    const buttonsDisabled = !(course && courseModified) && !image;

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateCourse, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const viewProps: CourseEditBlockProps = {
      ...defaultProps,
      ...props,
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      uploadImage: {
        ...defaultProps.uploadImage,
        uploadButton: {
          ...defaultProps.uploadImage.uploadButton,
          onUploadButtonClicked: handleUploadImage,
        },
        uploadedImage: remappedImgSrc,
        state: imageSrc ? 'Uploaded' : 'Default',
        buttonGroup: {
          ...UploadField.defaultProps?.buttonGroup,
          primary: {
            ...UploadField.defaultProps?.buttonGroup?.primary,
            onButtonClicked: handleUploadImage,
          },
          secondary: {
            ...UploadField.defaultProps?.buttonGroup?.secondary,
            onButtonClicked: handleDeleteImage,
          },
        },
      },
      description: {
        ...defaultProps.description,
        textArea: { textValue: description, onTextChanged: getChangeHandler('description') },
      },
      tagSectionList: {
        ...defaultProps.tagSectionList,
        tagSectionItems,
      },
      htmlEditor: {
        ...defaultProps.htmlEditor,
        onTextChanged: handleUpdateHtml,
        textValue: courseState.html,
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateCourse,
            disabled: buttonsDisabled,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: buttonsDisabled,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
      onChangeImage: handleChangeImage,
    };

    return (
      <>
        <Prompt when={areChanges} message={handleBlockedNavigation} />
        <View {...viewProps} ref={ref} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
