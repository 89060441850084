import React from 'react';
import cx from 'classnames';
import styles from './CourseCard.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import useDragAndDrop from '../../../lib/hooks/useDragAndDrop';

export const defaultProps = {
  status: {
    type: 'Caption1',
    style: 'Danger',
    size: 'Small',
    align: 'Left',
    value: 'Unpublished',
  } as TextProps,
  title: {
    type: 'Title3',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'How to Build a Compelling Competitve Strategy',
  } as TextProps,
  units: {
    type: 'Paragraph3',
    style: 'Grey',
    size: 'Small',
    align: 'Left',
    value: '5 Lessons | 2 Quiz | 2 Videos',
  } as TextProps,
  estimatedTime: {
    type: 'Paragraph3',
    style: 'Grey',
    size: 'Small',
    align: 'Left',
    value: '4 Hours',
  } as TextProps,
  description: {
    type: 'Paragraph3',
    style: 'Dark',
    size: 'Small',
    align: 'Left',
    value:
      'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
  } as TextProps,
  button: {
    type: 'TextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Brand',
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Open course',
    },
  } as ButtonProps,
};

export type CourseCardProps = {
  title?: TextProps;
  units?: TextProps;
  estimatedTime?: TextProps;
  description?: TextProps;
  button?: ButtonProps;
  className?: string;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  index?: number;
  id?: number;
  status?: TextProps;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CourseCard: React.FC<CourseCardProps> = ({
  title,
  units,
  estimatedTime,
  description,
  button,
  className,
  onDrag,
  onDrop,
  index,
  id,
  status,
  onClick,
}) => {
  const { ref, isDragging, handlerId } = useDragAndDrop<HTMLDivElement>({
    id,
    index,
    direction: 'horizontal',
    type: 'CourseCard',
    onDrop,
    onDrag,
  });

  const opacity = isDragging ? 0 : 1;

  return (
    <div
      ref={ref}
      className={cx(styles.courseCard, className)}
      style={{ opacity }}
      data-handler-id={handlerId}
      onClick={onClick}
    >
      <div className={styles.container}>
        <Text 
          className={styles.status} 
          {...status} />
        <Text
          className={styles.title}
          {...title} />
        <div className={styles.metadataContainer}>
          <Text
            className={styles.units}
            {...units} />
          <Text
            className={styles.estimatedTime}
            {...estimatedTime} />
        </div>
        <Text
          className={styles.description}
          {...description} />
        <Button
          className={styles.button}
          {...button} />
      </div>
    </div>
  );
};

CourseCard.defaultProps = defaultProps;

export default CourseCard;
