import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EditCourseMenuProps } from './EditCourseMenu';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import { EditCourseMenuPresenterProps } from './EditCourseMenu.presenter';
import useCreateLesson from '../../../modules/lesson/useCreateLesson';
import useReorderLesson from '../../../modules/lesson/useReorderLesson';
import useReorderLessons from '../../../modules/lesson/useReorderLessons';

const withInteractor = (
  Presenter: React.FC<EditCourseMenuPresenterProps>,
): React.FC<EditCourseMenuProps> => {
  const Interactor: React.FC<EditCourseMenuProps> = (props) => {
    const { courseState } = useContext(CourseDetailsContext);
    const {
      courseId,
    } = useParams<{ courseId: string }>();
    const [createLessonState, createLesson] = useCreateLesson(Number(courseId));
    const [reorderLessonState, reorderLesson] = useReorderLesson();
    // TODO: Remove this
    const [, reorderLessons] = useReorderLessons();

    const loading = courseState.loading || createLessonState.loading || reorderLessonState.loading;
    const error = courseState.error || createLessonState.error || reorderLessonState.error;

    return <Presenter
      {...props}
      loading={loading}
      error={error}
      courseDetails={courseState.data}
      createLesson={createLesson}
      reorderLesson={reorderLesson}
      reorderLessons={reorderLessons}
      refetchCourse={courseState.refetch} />;
  };

  return Interactor;
};

export default withInteractor;
