import React, { useContext } from 'react';
import { CoursesBlockProps } from './CoursesBlock';
import useCreateCourse from '../../../modules/course/useCreateCourse';
import { CoursesBlockPresenterProps } from './CoursesBlock.presenter';
import { ErrorContext } from '../../organisms/ModalError/context';
import { MainContext } from '../../layout/MainLayout/context';
import useReorderCourse from '../../../modules/course/useReorderCourse';
import useReorderCourses from '../../../modules/course/useReorderCourses';

const withInteractor = (
  Presenter: React.FC<CoursesBlockPresenterProps>,
): React.FC<CoursesBlockProps> => {
  const Interactor: React.FC<CoursesBlockProps> = (props) => {
    const [createCourseState, createCourse] = useCreateCourse();
    const { setError } = useContext(ErrorContext);
    const { courseState } = useContext(MainContext);
    const [reorderCourseState, reorderCourse] = useReorderCourse();
    // TODO: Remove this hook
    const [, reorderCourses] = useReorderCourses();

    const loading = courseState.loading || createCourseState.loading || reorderCourseState.loading;

    return (
      <Presenter
        {...props}
        courseDetails={courseState.data || []}
        createCourse={createCourse}
        reorderCourse={reorderCourse}
        refetchCourses={courseState.refetch}
        loading={loading}
        error={courseState.error}
        setError={setError}
        reorderCourses={reorderCourses}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
