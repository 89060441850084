import { ADMIN_LOGIN_URL } from "../../lib/config";

export const redirectToLogin = (options?: { readonly returnUrl: string }): void => {
    let returnUrl: string = options?.returnUrl ?? window.location.href;
  
    // Check if URL is relative
    if (returnUrl.startsWith('/')) {
      // See https://developer.mozilla.org/en-US/docs/Web/API/Location/origin
      returnUrl = `${window.location.origin}${returnUrl}`;
    }
  
    window.location.assign(`${ADMIN_LOGIN_URL}?redirectTo=${returnUrl}`);
};