import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import axios from '../../../lib/api/axios';
import { VideoEditBlockProps, defaultProps } from './VideoEditBlock';
import { UnitResponse, UpdateUnitPayload } from '../../../modules/unit/types';
import { Unit, Lesson, VideoUnit } from '../../../modules/types';
import { RadioButtonProps, defaultProps as radioButtonDefaultProps } from '../../atoms/RadioButton/RadioButton';
import { defaultProps as defaultUploadFieldProps, UploadFieldStateType } from '../../molecules/UploadField/UploadField';
import { ApiResponse } from '../../../lib/types';
import { getBasePath, getMediaServiceResourceUrl, isMediaServiceResource } from '../../../lib/api/utils';
import { defaultProps as uploadImageFieldDefaultProps } from '../../molecules/UploadImageField/UploadImageField';
import { setNavigateAwayConfirmationModal, toBase64 } from '../../../lib/utils';
import { MEDIA_IMAGE_FIELD_NAME } from '../../../lib/config';

import { FileMetadata, ImageMetadata } from '../../../modules/common/types';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';

type UnitState = {
  title?: string;
  description?: string;
  canSkip?: boolean;
  estimatedTime?: number;
  videoType?: string;
  videoLength?: number;
};

export type VideoEditBlockPresenterProps = VideoEditBlockProps & {
  loading?: boolean;
  error?: Error;
  data: Lesson | null;
  updateUnit: (payload: UpdateUnitPayload) => Promise<UnitResponse>;
  deleteUnit: (id: number) => Promise<ApiResponse<void>>;
  uploadImage: (payload: FormData) => Promise<ApiResponse<ImageMetadata>>;
  refetchCourse: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
  uploadingVideoFile?: boolean;
  uploadVideoFile: (file: File) => Promise<ApiResponse<FileMetadata>>;
};

const isUnitModified = (originalUnit: UnitState | null, newUnit: UnitState): boolean => {
  return (
    originalUnit?.description !== newUnit.description
    || originalUnit?.title !== newUnit.title
    || originalUnit?.canSkip !== newUnit.canSkip
    || originalUnit?.estimatedTime !== newUnit.estimatedTime
    || originalUnit?.videoType !== newUnit.videoType
    || originalUnit?.videoLength !== newUnit.videoLength
  );
};

const withPresenter = (
  View: React.ForwardRefExoticComponent<
  VideoEditBlockProps & React.RefAttributes<HTMLInputElement>
  >,
): React.FC<VideoEditBlockPresenterProps> => {
  const Presenter: React.FC<VideoEditBlockPresenterProps> = (props) => {
    const {
      data: lesson,
      updateUnit,
      deleteUnit,
      setError,
      setConfirmation,
      refetchCourse,
      uploadImage,
      uploadingVideoFile,
      uploadVideoFile,
    } = props;

    const history = useHistory();
    const {
      unitId, lessonId, courseId,
    } = useParams<{ unitId: string; lessonId: string; courseId: string}>();

    const unit: VideoUnit | null = lesson?.units.find((item) => {
      return `${item.id}` === unitId;
    }) || null;

    const ref = useRef<HTMLInputElement>(null);

    const [image, setImage] = useState<File>();
    const [imageSrc, setImageSrc] = useState(unit?.thumbnailUrl);

    const [videoFilename, setVideoFilename] = useState('');
    const [videoSrc, setVideoSrc] = useState(unit?.videoUrl);
    const [
      isVideoFileUploaded, setVideoFileUploaded,
    ] = useState(isMediaServiceResource(unit?.videoUrl));
    const [videoFile, setVideoFile] = useState<File>();

    const [unitState, setUnitState] = useState<UnitState>({
      title: unit?.title || '',
      description: unit?.description || '',
      canSkip: unit?.canSkip || true,
      estimatedTime: unit?.estimatedTime || 0,
      videoType: unit?.videoType || '',
      videoLength: unit?.videoLength || 0,
    });

    const isUnitUpdated = isUnitModified(unit, unitState) || !!image || unit?.videoUrl !== videoSrc;

    const discardVideoChanges = useCallback((): void => {
      setVideoFile(undefined);
      setVideoSrc(unit?.videoUrl);
      setVideoFileUploaded(isMediaServiceResource(unit?.videoUrl));
    }, [unit]);

    // Reset unit/image state
    const discardChanges = useCallback((): void => {
      setUnitState({ ...unit });
      setImage(undefined);
      setImageSrc(unit?.thumbnailUrl);

      discardVideoChanges();

      if (ref.current) {
        ref.current.value = '';
      }
    }, [discardVideoChanges, unit]);

    useEffect(() => {
      if (isUnitUpdated) {
        discardChanges();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unitId, discardChanges]);

    const { t } = useTranslation();
    const {
      title,
      description,
      estimatedTime,
      videoType,
    } = unitState;

    const onDrop = useCallback((files) => {
      // When file selected from file input, save file to state
      if (files && files[0]) {
        setVideoFile(files[0]);
      }
    }, []);

    // Make a request to save the video in the media service
    const handleSaveVideoFile = useCallback(async (file: File): Promise<void> => {
      const { data: videoMetadata, error } = await uploadVideoFile(file);
      if (videoMetadata) {
        setVideoSrc(videoMetadata.url);
        setVideoFileUploaded(true);
      } else {
        discardVideoChanges();
        setVideoFile(undefined);
        setError({
          error,
          description: t('error.modal.upload_file.description'),
        });
      }
      // TODO: Figure out why uploadVideoFile is changing on each render
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (videoFile) {
        handleSaveVideoFile(videoFile);
      }
    }, [handleSaveVideoFile, videoFile]);

    useEffect(() => {
      // When data is loaded, update course state and set image src for preview field
      setUnitState({ ...unit });
      setImageSrc(unit?.thumbnailUrl);
    }, [unit]);

    useEffect(() => {
      // Retrieve file metadata for resource
      if (videoSrc && isVideoFileUploaded) {
        const resourceUrl = getMediaServiceResourceUrl(`${videoSrc}/metadata`);
        if (resourceUrl) {
          axios.get<FileMetadata>(resourceUrl)
            .then((res) => {
              setVideoFilename(res.data.filename || '');
            })
            .catch(() => setVideoFilename('Could not retrieve video file metadata'));
        }
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoSrc, isVideoFileUploaded]);

    useEffect(() => {
      // When new image selected, encode the image and set image src for preview field
      if (image) {
        toBase64(image).then((url) => {
          setImageSrc(url);
        }).catch();
      }
    }, [image]);

    const handleResetImage = (): void => {
      if (ref.current) {
        ref.current.value = '';
        setImage(undefined);
      }
    };

    const handleUploadImage = (): void => {
      if (ref.current) {
        ref.current.click();
      }
    };

    // When image selected from file input, save image to state
    const handleChangeImage = (event?: React.ChangeEvent<HTMLInputElement>): void => {
      if (event) {
        const { files } = event?.currentTarget;
        if (files) {
          setImage(files[0]);
        }
      }
    };

    /**
     * When delete button is pressed, set image state to undefined
     * Course state image url must be empty string so that the url gets overwritten in the database
     */
    const handleDeleteImage = (): void => {
      setImage(undefined);
      setImageSrc(undefined);
      setUnitState({ ...unitState });
      if (ref.current) {
        ref.current.value = '';
      }
    };

    // Make a request to save the image in the media service
    const handleSaveImage = async (imageFile: File): Promise<ImageMetadata> => {
      const formData = new FormData();
      formData.append(MEDIA_IMAGE_FIELD_NAME, imageFile);
      const imageMetadata = (await uploadImage(formData)).data;
      if (!imageMetadata) {
        throw new Error();
      }
      return imageMetadata;
    };

    // When delete button is pressed, set file state to undefined and contentUrl to empty string
    const handleDeleteVideoFile = (): void => {
      setVideoFile(undefined);
      setVideoSrc('');
      setVideoFileUploaded(false);
    };

    // Make a request to save unit metadata in lms backend
    const handleSaveUnit = async (payload: UpdateUnitPayload): Promise<Unit | null> => {
      const { data } = await updateUnit(payload);
      if (!data) {
        throw new Error();
      }
      return data;
    };

    const handleUpdateUnit = async (): Promise<void> => {
      let thumbnailUrl;
      try {
        if (image) {
          // If file added, upload file to S3
          thumbnailUrl = (await handleSaveImage(image)).url;
        } else if (imageSrc !== unit?.thumbnailUrl) {
          // If the image has been deleted, set thumbnailUrl to empty string
          thumbnailUrl = '';
        }
        handleResetImage();
        // Update course with image url from media service
        await handleSaveUnit({
          ...unitState, thumbnailUrl, videoUrl: videoSrc, unitType: 'video',
        });
        refetchCourse();
        setError(undefined);
      } catch (err) {
        // Dispatch error modal
        setError({
          error: err,
          description: t('error.modal.update_unit.description'),
          primaryButton: {
            text: t('error.modal.update_unit.button.primary'),
            onClicked: handleUpdateUnit,
          },
        });
      }
    };

    const handleDeleteUnit = async (): Promise<void> => {
      if (!unit) {
        return;
      }
      const { error } = await deleteUnit(unit.id);

      setConfirmation(undefined);

      if (error) {
        // Dispatch Error Modal
        setError({
          error,
          description: t('error.modal.delete_unit.description'),
          primaryButton: {
            text: t('error.modal.delete_unit.button.primary'),
            onClicked: handleDeleteUnit,
          },
        });
      } else if (lesson) {
        const currentUnitIndex = lesson.units.findIndex((item) => item.id === Number(unitId));

        let redirectIndex = -1;
        if (currentUnitIndex !== -1) {
          if (lesson.units[currentUnitIndex + 1]) {
            redirectIndex = currentUnitIndex + 1;
          } else if (lesson.units[currentUnitIndex - 1]) {
            redirectIndex = currentUnitIndex - 1;
          }
        }
        const baseRedirectPath = `${getBasePath()}/course/${courseId}/lesson/${lessonId}`;
        if (redirectIndex !== -1) {
          const redirectUnit = lesson.units[redirectIndex];
          history.replace(`${baseRedirectPath}/${redirectUnit.unitType}/${redirectUnit.id}`);
        } else {
          history.replace(`${baseRedirectPath}`);
        }
        refetchCourse();
      }
    };

    // Get change handler for unit input fields
    const getChangeHandler = (property: keyof UnitState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setUnitState({
          ...unitState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const remappedImgSrc = getMediaServiceResourceUrl(imageSrc);

    const radioButtons: RadioButtonProps[] = [
      {
        ...radioButtonDefaultProps,
        state: unitState.videoType === 'Youtube' ? 'Selected' : 'Default',
        icon: {
          ...radioButtonDefaultProps.icon,
          asset: 'Youtube',
        },
        text: {
          ...radioButtonDefaultProps.text,
          value: 'Youtube',
        },
        onRadioButtonClicked: (): void => {
          setUnitState({
            ...unitState,
            videoType: 'Youtube',
          });
        },
      },
      {
        ...radioButtonDefaultProps,
        state: unitState.videoType === 'Vimeo' ? 'Selected' : 'Default',
        icon: {
          ...radioButtonDefaultProps.icon,
          asset: 'Vimeo',
        },
        text: {
          ...radioButtonDefaultProps.text,
          value: 'Vimeo',
        },
        onRadioButtonClicked: (): void => {
          setUnitState({
            ...unitState,
            videoType: 'Vimeo',
          });
        },
      },
      {
        ...radioButtonDefaultProps,
        state: unitState.videoType === 'Upload' ? 'Selected' : 'Default',
        icon: {
          ...radioButtonDefaultProps.icon,
          asset: 'Upload',
        },
        text: {
          ...radioButtonDefaultProps.text,
          value: 'Upload',
        },
        onRadioButtonClicked: (): void => {
          setUnitState({
            ...unitState,
            videoType: 'Upload',
          });
        },
      },
    ];

    let videoUploadState: UploadFieldStateType = 'Default';
    if (uploadingVideoFile) {
      videoUploadState = 'Uploading';
    } else if (isVideoFileUploaded) {
      videoUploadState = 'Uploaded';
    }

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateUnit, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_unit.description', { unitName: unit?.title }),
        primaryButton: {
          onClicked: handleDeleteUnit
        }
      })
    };

    const viewProps: VideoEditBlockProps = {
      ...defaultProps,
      ...props,
      onChangeImage: handleChangeImage,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: title,
        },
      },
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      description: {
        ...defaultProps.description,
        textArea: { textValue: description, onTextChanged: getChangeHandler('description') },
      },
      radioButtonList: {
        ...defaultProps.radioButtonList,
        radioButtons,
      },
      videoUploadSection: {
        ...defaultProps.videoUploadSection,
        type: videoType === 'Upload' ? 'Upload' : 'Default',
        onDrop,
        onDeleteFile: handleDeleteVideoFile,
        fileTypes: ['video/*'],
        videoThumbnail: {
          ...uploadImageFieldDefaultProps,
          uploadButton: {
            ...uploadImageFieldDefaultProps.uploadButton,
            onUploadButtonClicked: handleUploadImage,
          },
          uploadedImage: remappedImgSrc,
          label: {
            ...uploadImageFieldDefaultProps.label,
            value: 'Thumbnail',
          },
          state: imageSrc ? 'Uploaded' : 'Default',
          buttonGroup: {
            ...uploadImageFieldDefaultProps.buttonGroup,
            primary: {
              ...uploadImageFieldDefaultProps.buttonGroup?.primary,
              onButtonClicked: handleUploadImage,
            },
            secondary: {
              ...uploadImageFieldDefaultProps.buttonGroup?.secondary,
              onButtonClicked: handleDeleteImage,
            },
          },
        },
        uploadField: {
          ...defaultProps.videoUploadSection.uploadField,
          state: videoUploadState,
          videoName: {
            ...defaultUploadFieldProps.videoName,
            value: videoFile?.name || videoFilename || '',
          },
        },
        videoUrl: {
          ...defaultProps.videoUploadSection.videoUrl,
          input: {
            ...defaultProps.videoUploadSection.videoUrl?.input,
            textValue: videoSrc,
            onTextChanged: (event) => {
              setVideoSrc(event.currentTarget.value);
            },
          },
        },
      },
      estimatedTime: {
        ...defaultProps.estimatedTime,
        inputWithStepper: {
          ...defaultProps.estimatedTime.inputWithStepper,
          input: { textValue: `${estimatedTime}`, onTextChanged: getChangeHandler('estimatedTime') },
        },
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateUnit,
            disabled: !isUnitUpdated,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: !isUnitUpdated,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
    };

    return (
      <>
        <Prompt when={isUnitUpdated} message={handleBlockedNavigation} />
        <View {...viewProps} ref={ref} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
