import React from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';

import styles from './VideoUploadSection.module.scss';

import InputField, { InputFieldProps } from '../../molecules/InputField';
import UploadField, { UploadFieldProps, defaultProps as defaultUploadFieldProps } from '../../molecules/UploadField/UploadField';
import UploadImageField, { UploadImageFieldProps } from '../../molecules/UploadImageField';

export type VideoUploadSectionTypeType = 'Default' | 'Upload';

export const defaultProps = {
  type: 'Upload' as VideoUploadSectionTypeType,
  videoUrl: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Video url',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'Input text',
    },
  } as InputFieldProps,
  uploadField: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Upload Video',
    },
    icon: {
      asset: 'VideoReel',
      style: 'Dark',
    },
    button: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Select video',
      },
    },
  } as UploadFieldProps,
  videoThumbnail: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Video thumbnail',
    },
    uploadButton: {
      icon: {
        asset: 'UploadImage',
        style: 'Blue',
      },
      text: {
        type: 'Paragraph2',
        style: 'Grey',
        size: 'Medium',
        align: 'Left',
        value: 'Upload picture',
      },
    },
  } as UploadImageFieldProps,
};

export type VideoUploadSectionProps = {
  type?: VideoUploadSectionTypeType;
  videoUrl?: InputFieldProps;
  className?: string;
  uploadField?: UploadFieldProps;
  videoThumbnail?: UploadImageFieldProps;
  onDeleteFile?: () => void;
  onDrop?: (files: File[]) => void;
  fileTypes?: string[],
};

const VideoUploadSection: React.FC<VideoUploadSectionProps> = ({
  type,
  videoUrl,
  className,
  uploadField,
  videoThumbnail,
  onDeleteFile,
  onDrop,
  fileTypes,
}) => {
  const {
    getRootProps, getInputProps, open,
  } = useDropzone({
    onDrop, 
    maxFiles: 1, 
    noClick: true, 
    noKeyboard: true,
    accept: fileTypes,
  });
  const currentStyle = styles[`videoUploadSection${type}`];

  let videoUrlView;
  let uploadFieldView;
  let videoThumbnailView;
  
  const uploadFieldProps: UploadFieldProps = {
    ...defaultUploadFieldProps,
    ...uploadField,
    button: {
      ...defaultUploadFieldProps.button,
      onButtonClicked: open,
    },
    cancelButton: {
      ...defaultUploadFieldProps.cancelButton,
      // TODO add functionality of canceling
      // onButtonClicked: onCancelFile,
    },
    buttonGroup: {
      primary: {
        ...defaultUploadFieldProps.buttonGroup?.primary,
        onButtonClicked: open,
      },
      secondary: {
        ...defaultUploadFieldProps.buttonGroup?.secondary,
        onButtonClicked: onDeleteFile,
      },
    },
    dropzone: {
      getInputProps,
      getRootProps,
    },
  };

  switch (type) {
    case 'Default':
      videoUrlView = (
        <InputField
          className={styles.videoUrl}
          {...videoUrl} />
      );
      break;
    case 'Upload':
      uploadFieldView = (
        <UploadField
          className={styles.uploadField}
          {...uploadFieldProps} />
      );
      videoThumbnailView = (
        <UploadImageField
          className={styles.videoThumbnail}
          {...videoThumbnail} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {videoUrlView}
      {uploadFieldView}
      {videoThumbnailView}
    </div>
  );
};

VideoUploadSection.defaultProps = defaultProps;

export default VideoUploadSection;
