import React from 'react';
import cx from 'classnames';

import styles from './MenuCourse.module.scss';

import Text, { TextProps } from '../Text';

export type MenuCourseMenuCourseTypeType = 'button' | 'submit' | 'reset';
export type MenuCourseTypeType = 'Default' | 'Selected';

export const defaultProps = {
  type: 'Selected' as MenuCourseTypeType,
  text: {
    type: 'Subtitle1',
    style: 'Dark',
    size: 'Medium',
    align: 'Left',
    value: 'Course 1',
  } as TextProps,
};

export type MenuCourseProps = {
  type?: MenuCourseTypeType;
  menuCourseType?: MenuCourseMenuCourseTypeType;
  onMenuCourseClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: TextProps;
  className?: string;
};

const MenuCourse: React.FC<MenuCourseProps> = ({
  type,
  menuCourseType,
  onMenuCourseClicked,
  text,
  className,
}) => {

  const currentStyle = styles[`menuCourse${type}`];

  const contentView = (
    <div className={styles.content}>
      <Text
        className={styles.text}
        {...text} />
    </div>
  );
  
  let selectorView;
  
  switch (type) {
    case 'Default':
      break;
    case 'Selected':
      selectorView = (
        <div className={styles.selector}/>
      );
      break;
  }

  return (
    <button
      type={menuCourseType}
      onClick={onMenuCourseClicked}
      className={cx(currentStyle, className)}>
      {selectorView}
      {contentView}
    </button>
  );
};

MenuCourse.defaultProps = defaultProps;

export default MenuCourse;
