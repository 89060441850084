import React from 'react';
import cx from 'classnames';

import styles from './CourseHeader.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  button: {
    type: 'TextButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Basic',
    text: {
      type: 'Paragraph3',
      style: 'Brand',
      size: 'Small',
      align: 'Center',
      value: 'Exit',
    },
  } as ButtonProps,
  publishButton: {
    type: 'TextButton',
    size: 'Large',
    fill: 'Colour',
    colour: 'Brand',
    text: {
      type: 'Subtitle1',
      style: 'White',
      size: 'Medium',
      align: 'Center',
      value: 'Publish',
    },
  } as ButtonProps,
};

export type CourseHeaderProps = {
  button?: ButtonProps;
  publishButton?: ButtonProps;
  className?: string;
};

const CourseHeader: React.FC<CourseHeaderProps> = ({
  button,
  publishButton,
  className,
}) => {
  return (
    <div className={cx(styles.courseHeader, className)}>
      <Button className={styles.button} {...button} />
      <Button className={styles.publishButton} {...publishButton} />
    </div>
  );
};

CourseHeader.defaultProps = defaultProps;

export default CourseHeader;
