export type Course = {
  id: number;
  uuid: string;
  order: number;
  title: string;
  description: string;
  imageUrl?: string;
  html?: string;
  status: CourseStatus;
  createdAt: Date;
  updatedAt: Date;
};

export enum CourseStatus {
  PUBLISH = "published",
  UNPUBLISH = "unpublished",
}

export type Tag = {
  id: number;
  uuid: string;
  tagGroup: string;
  tagType: string;
  tagValue: string;
  createdAt: Date;
  updatedAt: Date;
  order: number;
};

export type Answer = {
  id: number;
  uuid: string;
  order: number;
  answer: string;
  correct: boolean;
  createdAt: Date;
  updatedAt: Date;
  question: Question;
};

export type Question = {
  id: number;
  uuid: string;
  order: number;
  questionType: string;
  question: string;
  answers: Answer[];
  correctMessage?: string;
  incorrectMessage?: string;
  showMessages: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type Lesson = {
  id: number;
  uuid: string;
  order: number;
  title: string;
  description: string;
  units: Unit[];
  resources: AdditionalResource[];
  createdAt: Date;
  updatedAt: Date;
};

export type LessonWithEstimatedTime = Lesson & {
  estimatedTime: number;
};

export type Exam = {
  id: number;
  uuid: string;
  duration: number;
  commonDuration: number;
  passingGrade: number;
  delayAttempt: number;
  questions: Question[];
  createdAt: Date;
  updatedAt: Date;
};

export type User = {
  id: number;
  uuid: string;
  accountId: number;
};

export type Instructor = {
  id: number;
  uuid: string;
  firstName: string;
  lastName: string;
  biography: string;
  createdAt: Date;
  updatedAt: Date;
};

type BaseUnit = {
  id: number;
  uuid: string;
  order: number;
  title: string;
  description: string;
  canSkip: boolean;
  unitType: string;
  estimatedTime: number;
  thumbnailUrl?: string;
  createdAt: Date;
  updatedAt: Date;
};

export type QuizUnit = BaseUnit & {
  questions?: Question[];
};

export type ResourceUnit = BaseUnit & {
  contentType?: string;
  contentButton?: string;
  contentUrl?: string;
};

export type VideoUnit = BaseUnit & {
  videoType?: string;
  videoUrl?: string;
  videoLength?: number;
  thumbnailUrl?: string;
};

export type HtmlUnit = BaseUnit & {
  text?: string;
};

export type Unit = ResourceUnit | VideoUnit | QuizUnit | HtmlUnit;

export type CourseDetails = Course & {
  estimatedTime: number;
  lessons: LessonWithEstimatedTime[];
  instructors: Instructor[];
  tags: Tag[];
  exam?: Exam;
  order: number;
};

export type AdditionalResource = {
  id: number;
  uuid: string;
  name: string;
  type: string;
  url: string;
  fileName?: string;
  createdAt: Date;
  updatedAt: Date;
};
