import React from 'react';
import cx from 'classnames';

import styles from './ContextualMenuItem.module.scss';

import Text, { TextProps } from '../Text';

export type ContextualMenuItemStyleType = 'Default' | 'Danger';
export type ContextualMenuItemContextualMenuItemTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  style: 'Danger' as ContextualMenuItemStyleType,
  text: {
    type: 'Paragraph3',
    style: 'Danger',
    size: 'Small',
    align: 'Left',
    value: 'Menu option',
  } as TextProps,
};

export type ContextualMenuItemProps = {
  style?: ContextualMenuItemStyleType;
  contextualMenuItemType?: ContextualMenuItemContextualMenuItemTypeType;
  onContextualMenuItemClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  text?: TextProps;
  className?: string;
};

const ContextualMenuItem: React.FC<ContextualMenuItemProps> = ({
  style,
  contextualMenuItemType,
  onContextualMenuItemClicked,
  text,
  className,
}) => {

  const currentStyle = styles[`contextualMenuItem${style}`];

  return (
    <button
      type={contextualMenuItemType}
      onClick={onContextualMenuItemClicked}
      className={cx(currentStyle, className)}>
      <div className={styles.content}>
        <Text
          className={styles.text}
          {...text} />
      </div>
    </button>
  );
};

ContextualMenuItem.defaultProps = defaultProps;

export default ContextualMenuItem;
