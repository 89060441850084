import React from 'react';
import { CourseDetailsProvider, CourseEditProvider } from './context';
import CourseEditLayoutView, {
  CourseEditLayoutProps,
} from './CourseEditLayout';
import withInteractor from './CourseEditLayout.interactor';
import withPresenter from './CourseEditLayout.presenter';

export type { CourseEditLayoutProps };

const CourseEditLayoutInteractor = withInteractor(
  withPresenter(CourseEditLayoutView),
);

const CourseEditLayout: React.FC<CourseEditLayoutProps> = (props) => {
  return (
    <CourseDetailsProvider>
      <CourseEditProvider>
        <CourseEditLayoutInteractor {...props} />
      </CourseEditProvider>
    </CourseDetailsProvider>
  );
};

export default CourseEditLayout;
