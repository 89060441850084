import React from 'react';
import cx from 'classnames';

import styles from './AnswerOption.module.scss';

import Checkbox, { CheckboxProps } from '../../atoms/Checkbox';
import Input, { InputProps } from '../../atoms/Input';
import Button, { ButtonProps } from '../../atoms/Button';
import Text, { TextProps } from '../../atoms/Text';
import useDragAndDrop from '../../../lib/hooks/useDragAndDrop';

export type AnswerOptionStateType = 'Edit' | 'View';

export const defaultProps = {
  state: 'View' as AnswerOptionStateType,
  checkbox: {
    state: 'Unchecked',
    icon: {
      asset: 'Square',
      style: 'Dark',
    },
  } as CheckboxProps,
  input: {
    style: 'White',
    textPlaceholder: 'Input text',
  } as InputProps,
  button: {
    type: 'IconButton',
    size: 'Medium',
    fill: 'White',
    colour: 'Danger',
    icon: {
      asset: 'Close',
      style: 'Dark',
    },
  } as ButtonProps,
  text: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Option',
  } as TextProps,
};

export type AnswerOptionProps = {
  state?: AnswerOptionStateType;
  checkbox?: CheckboxProps;
  input?: InputProps;
  button?: ButtonProps;
  className?: string;
  text?: TextProps;
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  id?: number;
  index?: number;
  canDrag?: boolean;
};

const AnswerOption: React.FC<AnswerOptionProps> = ({
  state,
  checkbox,
  input,
  button,
  className,
  text,
  onDrag,
  onDrop,
  id,
  index,
  canDrag,
}) => {
  const currentStyle = styles[`answerOption${state}`];
  const { isDragging, ref, handlerId } = useDragAndDrop<HTMLDivElement>({
    id, index, onDrop, onDrag, direction: 'vertical', type: 'AnswerOption', canDrag,
  });

  const opacity = isDragging ? 0 : 1;

  const checkboxView = (
    <Checkbox
      className={styles.checkbox}
      {...checkbox} />
  );

  let inputView;
  let textView;
  let buttonView;

  switch (state) {
    case 'Edit':
      inputView = (
        <Input
          className={styles.input}
          {...input} />
      );
      buttonView = (
        <Button
          className={styles.button}
          {...button} />
      );
      break;
    case 'View':
      textView = (
        <Text
          className={styles.text}
          {...text} />
      );
      break;
  }

  return (
    <div
      className={cx(currentStyle, className)}
      ref={ref}
      data-handler-id={handlerId}
      style={{ opacity }}
    >
      {checkboxView}
      {inputView}
      {textView}
      {buttonView}
    </div>
  );
};

AnswerOption.defaultProps = defaultProps;

export default AnswerOption;
