import React from 'react';
import cx from 'classnames';

import styles from './ValidationMessage.module.scss';

import Checkbox, { CheckboxProps } from '../../atoms/Checkbox';
import Text, { TextProps } from '../../atoms/Text';
import Button, { ButtonProps } from '../../atoms/Button';
import TextAreaField, { TextAreaFieldProps } from '../TextAreaField';

export type ValidationMessageStateType = 'Collapsed' | 'Expanded';

export const defaultProps = {
  state: 'Expanded' as ValidationMessageStateType,
  checkbox: {
    state: 'Checked',
    icon: {
      asset: 'SquareCheck',
      style: 'Blue',
    },
  } as CheckboxProps,
  text: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Show validation messages',
  } as TextProps,
  button: {
    type: 'IconButton',
    size: 'Small',
    fill: 'White',
    colour: 'Basic',
    icon: {
      asset: 'CircleChevronUp',
      style: 'Grey',
    },
  } as ButtonProps,
  correctMessage: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Correct answer message',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
  incorrectMessage: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Incorrect answer message',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
};

export type ValidationMessageProps = {
  state?: ValidationMessageStateType;
  checkbox?: CheckboxProps;
  text?: TextProps;
  button?: ButtonProps;
  className?: string;
  correctMessage?: TextAreaFieldProps;
  incorrectMessage?: TextAreaFieldProps;
};

const ValidationMessage: React.FC<ValidationMessageProps> = ({
  state,
  checkbox,
  text,
  button,
  className,
  correctMessage,
  incorrectMessage,
}) => {

  const currentStyle = styles[`validationMessage${state}`];

  const topContentView = (
    <div className={styles.topContent}>
      <Checkbox
        className={styles.checkbox}
        {...checkbox} />
      <Text
        className={styles.text}
        {...text} />
      <Button
        className={styles.button}
        {...button} />
    </div>
  );
  
  let correctMessageView;
  let incorrectMessageView;
  
  switch (state) {
    case 'Collapsed':
      break;
    case 'Expanded':
      correctMessageView = (
        <TextAreaField
          className={styles.correctMessage}
          {...correctMessage} />
      );
      incorrectMessageView = (
        <TextAreaField
          className={styles.incorrectMessage}
          {...incorrectMessage} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {topContentView}
      {correctMessageView}
      {incorrectMessageView}
    </div>
  );
};

ValidationMessage.defaultProps = defaultProps;

export default ValidationMessage;
