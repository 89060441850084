import React from 'react';
import cx from 'classnames';

import { useDrop } from 'react-dnd';
import styles from './MenuLessonItemList.module.scss';

import MenuLessonItem, { MenuLessonItemProps } from '../MenuLessonItem';

export const defaultProps = {
  menuLessonItems: [
    {
      state: 'Collapsed',
      type: 'Default',
      menuLesson: {
        type: 'Default',
        state: 'Collapsed',
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Lesson 1',
        },
        collapsedIcon: {
          asset: 'ChevronUp',
          style: 'Dark',
        },
      },
    },
    {
      state: 'Expanded',
      type: 'Default',
      menuLesson: {
        type: 'Default',
        state: 'Expanded',
        text: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'Lesson 2',
        },
        expandedIcon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
      menuUnitList: {
        menuUnits: [
          {
            type: 'Default',
            icon: {
              asset: 'File',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Quiz',
            },
          },
          {
            type: 'Default',
            icon: {
              asset: 'File',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Video',
            },
          },
          {
            type: 'Default',
            icon: {
              asset: 'File',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Quiz',
            },
          },
        ],
      },
      addUnit: {
        type: 'AddItem',
        icon: {
          asset: 'PlusCircle',
          style: 'Blue',
        },
        text: {
          type: 'Subtitle2',
          style: 'Brand',
          size: 'Medium',
          align: 'Left',
          value: 'Add Unit',
        },
      },
    },
    {
      state: 'Expanded',
      type: 'Selected',
      menuLesson: {
        type: 'Selected',
        state: 'Expanded',
        text: {
          type: 'Paragraph3',
          style: 'Danger',
          size: 'Small',
          align: 'Left',
          value: 'Lesson 3',
        },
        selectedExpandedIcon: {
          asset: 'ChevronDown',
          style: 'Brand',
        },
      },
      menuUnitList: {
        menuUnits: [
          {
            type: 'Default',
            icon: {
              asset: 'Video',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Video',
            },
          },
          {
            type: 'Default',
            icon: {
              asset: 'Video',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Video',
            },
          },
          {
            type: 'Default',
            icon: {
              asset: 'File',
              style: 'Grey',
            },
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Quiz',
            },
          },
        ],
      },
      addUnit: {
        type: 'AddItem',
        icon: {
          asset: 'PlusCircle',
          style: 'Blue',
        },
        text: {
          type: 'Subtitle2',
          style: 'Brand',
          size: 'Medium',
          align: 'Left',
          value: 'Add Unit',
        },
      },
    },
  ] as MenuLessonItemProps[],
};

export type MenuLessonItemListProps = {
  menuLessonItems?: MenuLessonItemProps[];
  className?: string;
};

const MenuLessonItemList: React.FC<MenuLessonItemListProps> = ({
  menuLessonItems,
  className,
}) => {
  const menuLessonItemArray = menuLessonItems?.map((menuLessonItem) => (
    <MenuLessonItem
      key={menuLessonItem.id}
      className={styles.menuLessonItem}
      {...menuLessonItem} />
  ));

  const [,drop] = useDrop(() => ({ accept: 'MenuLesson' }));

  return (
    <div ref={drop} className={cx(styles.menuLessonItemList, className)}>
      {menuLessonItemArray}
    </div>
  );
};

MenuLessonItemList.defaultProps = defaultProps;

export default MenuLessonItemList;
