import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { putQuestions } from './api';
import { Question, UpdateQuestionsPayload, UseUpdateQuestionsResult } from './types';

const useUpdateQuestions = (): UseUpdateQuestionsResult => {
  const put = useCallback((
    payload: UpdateQuestionsPayload,
  ): Promise<Question[]> => putQuestions(payload), []);
  return useUpdate(put);
};

export default useUpdateQuestions;
