import React from 'react';
import cx from 'classnames';

import styles from './TagSectionItem.module.scss';

import SectionHeader, { SectionHeaderProps } from '../../molecules/SectionHeader';
import TagTypeList, { TagTypeListProps } from '../TagTypeList';

export const defaultProps = {
  sectionHeader: {
    state: 'WithDivider',
    text: {
      type: 'Title3',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Tag group',
    },
    divider: {
      style: 'Horizontal',
    },
  } as SectionHeaderProps,
  tagTypeList: {
    chipsFields: [
        {
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag type',
          },
        },
        {
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag type',
          },
        },
        {
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag type',
          },
        },
    ],
  } as TagTypeListProps,
};

export type TagSectionItemProps = {
  sectionHeader?: SectionHeaderProps;
  tagTypeList?: TagTypeListProps;
  className?: string;
};

const TagSectionItem: React.FC<TagSectionItemProps> = ({
  sectionHeader,
  tagTypeList,
  className,
}) => {
  return (
    <div className={cx(styles.tagSectionItem, className)}>
      <SectionHeader
        className={styles.sectionHeader}
        {...sectionHeader} />
      <TagTypeList
        className={styles.tagTypeList}
        {...tagTypeList} />
    </div>
  );
};

TagSectionItem.defaultProps = defaultProps;

export default TagSectionItem;
