import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getTags } from './api';
import { Tag, UseTagsResult } from './types';

const useTags = (): UseTagsResult => {
  const get = useCallback((): Promise<Tag[]> => getTags(), []);
  return useGet(get);
};

export default useTags;
