import React from 'react';
import cx from 'classnames';

import styles from './TagTypeItemList.module.scss';

import TagTypeItem, { TagTypeItemProps } from '../TagTypeItem';

export const defaultProps = {
  tagTypeItems: [
      {
        divider: {
          style: 'Horizontal',
        },
        tagTypeField: {
          state: 'Default',
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag type',
          },
          input: {
            style: 'Grey',
            textPlaceholder: 'Input text',
          },
        },
        tagValueField: {
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag values',
          },
          input: {
            style: 'Grey',
            textPlaceholder: 'Input text',
          },
        },
      },
      {
        divider: {
          style: 'Horizontal',
        },
        tagTypeField: {
          state: 'Default',
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag type',
          },
          input: {
            style: 'Grey',
            textPlaceholder: 'Input text',
          },
        },
        tagValueField: {
          state: 'Default',
          label: {
            type: 'Paragraph1',
            style: 'Dark',
            size: 'Large',
            align: 'Left',
            value: 'Tag values',
          },
          input: {
            style: 'Grey',
            textPlaceholder: 'Input text',
          },
        },
      },
  ] as TagTypeItemProps[],
};

export type TagTypeItemListProps = {
  tagTypeItems?: TagTypeItemProps[];
  className?: string;
};

const TagTypeItemList: React.FC<TagTypeItemListProps> = ({
  tagTypeItems,
  className,
}) => {
  const tagTypeItemArray = tagTypeItems?.map((tagTypeItem, index) => (
    <TagTypeItem
      key={index}
      className={styles.tagTypeItem}
      {...tagTypeItem} />
  ));
  
  return (
    <div className={cx(styles.tagTypeItemList, className)}>
      {tagTypeItemArray}
    </div>
  );
};

TagTypeItemList.defaultProps = defaultProps;

export default TagTypeItemList;
