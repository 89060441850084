import React, {
  createContext, Dispatch, SetStateAction, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import ModalError from '..';
import { ApiError } from '../../../../lib/api/types';
import { redirectToLogin } from '../../../../modules/auth/utils';
import { ModalErrorProps, defaultProps as modalErrorDefaultProps } from '../ModalError';

export type ErrorContextButton = {
  text?: string;
  onClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
};

export type ErrorContextState = {
  error?: Error;
  title?: string;
  description?: string;
  primaryButton?: ErrorContextButton;
  secondaryButton?: ErrorContextButton;
};

export type ErrorContextValue = {
  error: ErrorContextState | undefined;
  setError: Dispatch<SetStateAction<ErrorContextState | undefined>>;
};

const initialState: ErrorContextValue = {
  error: undefined,
  setError: () => { }, // noop default callback
};

export const ErrorContext = createContext<ErrorContextValue>(initialState);

const ErrorContextProvider: React.FC<{}> = ({ children }) => {
  const [error, setError] = useState<ErrorContextState | undefined>(initialState.error);
  const { t } = useTranslation();

  const handleCloseModal = (): void => {
    setError(undefined);
  };

  const errorProps: ModalErrorProps = {};
  if (error) {
    const {
      error: thrownError,
      title,
      description,
      primaryButton,
      secondaryButton,
    } = error;

    if (thrownError && thrownError instanceof ApiError) {
      if (thrownError.code === 401) {
        redirectToLogin();
        return <div>redirecting</div>;
      }
    }
    errorProps.show = true;
    errorProps.title = {
      ...modalErrorDefaultProps.title,
      value: title || t('error.modal.default.title'),
    };
    errorProps.description = {
      ...modalErrorDefaultProps.description,
      value: description || t('error.modal.default.description'),
    };
    errorProps.buttonGroup = {
      ...modalErrorDefaultProps.buttonGroup,
      primary: {
        ...modalErrorDefaultProps.buttonGroup.primary,
        text: {
          ...modalErrorDefaultProps.buttonGroup.primary?.text,
          value: primaryButton?.text || t('error.modal.default.button.primary'),
        },
        onButtonClicked: primaryButton?.onClicked || handleCloseModal,
      },
      secondary: {
        ...modalErrorDefaultProps.buttonGroup.secondary,
        text: {
          ...modalErrorDefaultProps.buttonGroup.secondary?.text,
          value: secondaryButton?.text || t('error.modal.default.button.secondary'),
        },
        onButtonClicked: secondaryButton?.onClicked || handleCloseModal,
      },
    };

    errorProps.onClose = handleCloseModal;
  }

  return (
    <ErrorContext.Provider value={{ error, setError }}>
      {children}
      <ModalError
        {...errorProps} />
    </ErrorContext.Provider>
  );
};

export default ErrorContextProvider;
