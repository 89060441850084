import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postUnit } from './api';
import { CreateUnitPayload, Unit, UseCreateUnitResult } from './types';

const useCreateUnit = (lessonId: number): UseCreateUnitResult => {
  const post = useCallback((
    payload: CreateUnitPayload,
  ): Promise<Unit> => postUnit(lessonId, payload), [lessonId]);
  return usePost(post);
};

export default useCreateUnit;
