import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { putAdditionalResources } from './api';
import { AdditionalResource, CreateOrUpdateAdditionalResourcesPayload, UseUpdateAdditionalResourceResult } from './types';

const useUpdateAdditionalResources = (lessonId: number): UseUpdateAdditionalResourceResult => {
  const put = useCallback((
    payload: CreateOrUpdateAdditionalResourcesPayload,
  ): Promise<AdditionalResource[]> => putAdditionalResources(payload, lessonId), [lessonId]);
  return useUpdate(put);
};

export default useUpdateAdditionalResources;
