import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../common/types';
import { CourseDetails, CourseStatus } from '../types';
import {
  Course, CreateCoursePayload, UpdateCoursePayload, UpdateCourseTagPayload,
} from './types';

export const getCourse = async (id: number): Promise<Course> => {
  try {
    const { data } = await axios.get<Course>(
      `${getServerUrl()}/courses/${id}`,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const getCourseDetails = async (id: number | string): Promise<CourseDetails> => {
  try {
    const { data } = await axios.get<CourseDetails>(
      `${getServerUrl()}/courseDetails/${id}`,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const getAllCourseDetails = async (): Promise<CourseDetails[]> => {
  try {
    const { data } = await axios.get<CourseDetails[]>(
      `${getServerUrl()}/courseDetails?status=${CourseStatus.UNPUBLISH}`,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const postCourse = async (payload: CreateCoursePayload): Promise<Course> => {
  try {
    const { data } = await axios.post<Course>(
      `${getServerUrl()}/courses`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const addCourseTag = async (
  courseId: number, payload: UpdateCourseTagPayload,
): Promise<void> => {
  try {
    await axios.patch<void>(
      `${getServerUrl()}/courses/${courseId}/actions/addTag`,
      payload,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const removeCourseTag = async (
  courseId: number, payload: UpdateCourseTagPayload,
): Promise<void> => {
  try {
    await axios.patch<void>(
      `${getServerUrl()}/courses/${courseId}/actions/removeTag`,
      payload,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const updateCourse = async (
  id: number, payload: UpdateCoursePayload,
): Promise<Course> => {
  try {
    const { data } = await axios.patch<Course>(
      `${getServerUrl()}/courses/${id}`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const deleteCourse = async (id: number): Promise<void> => {
  try {
    await axios.delete<void>(
      `${getServerUrl()}/courses/${id}`,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderCourse = async (
  payload: ReorderEntityPayload,
): Promise<void> => {
  const { id, order } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/courses/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderCourses = async (
  payload: ReorderEntitiesPayload,
): Promise<void> => {
  const { ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/courses/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
