import { useCallback } from 'react';
import useDelete from '../../lib/api/useDelete';
import { deleteUnit } from './api';
import { UseDeleteUnitResult } from './types';

const useDeleteUnit = (): UseDeleteUnitResult => {
  const onDelete = useCallback((id: number): Promise<void> => deleteUnit(id), []);
  return useDelete(onDelete);
};

export default useDeleteUnit;
