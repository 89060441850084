import React, { useContext } from 'react';
import useCreateTag from '../../../modules/tags/useCreateTag';
import useDeleteTag from '../../../modules/tags/useDeleteTag';
import useTags from '../../../modules/tags/useTags';
import useUpdateTag from '../../../modules/tags/useUpdateTag';
import { ErrorContext } from '../../organisms/ModalError/context';
import { TagsEditBlockProps } from './TagsEditBlock';
import { TagsEditBlockPresenterProps } from './TagsEditBlock.presenter';

const withInteractor = (
  Presenter: React.FC<TagsEditBlockPresenterProps>,
): React.FC<TagsEditBlockProps> => {
  const Interactor: React.FC<TagsEditBlockProps> = (props) => {
    const { setError } = useContext(ErrorContext);

    const tagsState = useTags();
    const [createTagState, createTag] = useCreateTag();
    const [updateTagState, updateTag] = useUpdateTag();
    const [deleteTagState, deleteTag] = useDeleteTag();

    return (
      <Presenter
        {...props}
        tags={tagsState.data }
        loading={tagsState.loading || createTagState.loading || updateTagState.loading || deleteTagState.loading }
        error={tagsState.error}
        setError={setError}
        refetchTags={tagsState.refetch}
        createTag={createTag}
        updateTag={updateTag}
        deleteTag={deleteTag}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
