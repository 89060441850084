import React from 'react';
import cx from 'classnames';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { DndProvider } from 'react-dnd';
import styles from './CoursesBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import CourseCardList, { CourseCardListProps } from '../../organisms/CourseCardList';

export const defaultProps = {
  blockHeader: {
    type: 'WithButton',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Block header',
    },
    button: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Create Course',
      },
    },
  } as BlockHeaderProps,
  courseCardList: {
    courseCards: [
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
    ],
  } as CourseCardListProps,
};

export type CoursesBlockProps = {
  error?: Error;
  loading?: boolean;
  blockHeader?: BlockHeaderProps;
  courseCardList?: CourseCardListProps;
  className?: string;
};

const CoursesBlock: React.FC<CoursesBlockProps> = ({
  blockHeader,
  courseCardList,
  className,
}) => (
  <div className={cx(styles.coursesBlock, className)}>
    <BlockHeader
      className={styles.blockHeader}
      {...blockHeader} />
    <DndProvider backend={HTML5Backend}>
      <CourseCardList
        className={styles.courseCardList}
        {...courseCardList} />
    </DndProvider>
  </div>
);

CoursesBlock.defaultProps = defaultProps;

export default CoursesBlock;
