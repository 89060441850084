import React from 'react';
import cx from 'classnames';

import styles from './ButtonGroup.module.scss';

import Button, { ButtonProps } from '../../atoms/Button';

export const defaultProps = {
  primary: {
    type: 'TextButton',
    size: 'Large',
    fill: 'Colour',
    colour: 'Brand',
    text: {
      type: 'Subtitle1',
      style: 'White',
      size: 'Medium',
      align: 'Center',
      value: 'Large',
    },
  } as ButtonProps,
  secondary: {
    type: 'TextButton',
    size: 'Large',
    fill: 'Basic',
    colour: 'Basic',
    text: {
      type: 'Subtitle1',
      style: 'Dark',
      size: 'Medium',
      align: 'Center',
      value: 'Large',
    },
  } as ButtonProps,
};

export type ButtonGroupProps = {
  primary?: ButtonProps;
  secondary?: ButtonProps;
  className?: string;
};

const ButtonGroup: React.FC<ButtonGroupProps> = ({
  primary,
  secondary,
  className,
}) => {
  return (
    <div className={cx(styles.buttonGroup, className)}>
      <Button
        className={styles.primary}
        {...primary} />
      <Button
        className={styles.secondary}
        {...secondary} />
    </div>
  );
};

ButtonGroup.defaultProps = defaultProps;

export default ButtonGroup;
