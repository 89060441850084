import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { MainLayoutProps, defaultProps } from './MainLayout';
import { defaultProps as menuItemDefaultProps } from '../../atoms/MainMenuItem/MainMenuItem';
import { AppDetails } from '../../../modules/auth/types';

export type MainLayoutPresenterProps = MainLayoutProps & {
  data: AppDetails | null;
};

const withPresenter = (
  View: React.FC<MainLayoutProps>,
): React.FC<MainLayoutPresenterProps> => {
  const Presenter: React.FC<MainLayoutPresenterProps> = (props) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const {
      appId, serviceName,
    } = useParams<{ appId: string; serviceName: string }>();
    const { data } = props;

    const coursesPagePath = `/${appId}/${serviceName}`;
    const tagsPagePath = `/${appId}/${serviceName}/tags`;

    const mainMenu = { ...defaultProps.mainMenu };
    mainMenu.mainMenuHeader = {
      ...mainMenu.mainMenuHeader,
      text: {
        ...mainMenu.mainMenuHeader?.text,
        value: data?.name,
      },
    };
    mainMenu.mainMenuItemList = {
      ...mainMenu.mainMenuItemList,
      mainMenuItems: [
        {
          ...menuItemDefaultProps,
          text: {
            ...menuItemDefaultProps.text,
            value: 'Courses',
          },
          type: pathname === coursesPagePath ? 'Selected' : 'Default',
          onMainMenuItemClicked: (): void => {
            if (pathname !== coursesPagePath) {
              history.push(coursesPagePath);
            }
          },
        },
        {
          ...menuItemDefaultProps,
          icon: {
            ...menuItemDefaultProps.icon,
            asset: 'PriceTag',
          },
          text: {
            ...menuItemDefaultProps.text,
            value: 'Tags',
          },
          type: pathname === tagsPagePath ? 'Selected' : 'Default',
          onMainMenuItemClicked: (): void => {
            if (pathname !== tagsPagePath) {
              history.push(tagsPagePath);
            }
          },
        },
      ],
    };

    return (
      <View
        {...props}
        mainMenu={mainMenu}
      />
    );
  };

  return Presenter;
};

export default withPresenter;
