import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import update from 'immutability-helper';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { LessonEditBlockProps, defaultProps } from './LessonEditBlock';
import { ConfirmationModalProps, defaultProps as defaultConfirmationModalProps } from '../../organisms/ConfirmationModal/ConfirmationModal';
import { LessonResponse, UpdateLessonPayload } from '../../../modules/lesson/types';
import { ApiResponse } from '../../../lib/api/types';
import { AdditionalResource, CourseDetails, LessonWithEstimatedTime } from '../../../modules/types';
import { getBasePath } from '../../../lib/api/utils';
import {
  TableItemProps, defaultProps as defaultTableItemProps, TableItemStateType,
} from '../../molecules/TableItem/TableItem';

import {
  AdditionalResourceResponse,
  AdditionalResourcesResponse,
  AdditionalResourcePayload,
  CreateOrUpdateAdditionalResourcesPayload,
  ReorderAdditionalResourcePayload,
  ReorderAdditionalResourcesPayload,
} from '../../../modules/additionalResource/types';
import { defaultProps as menuUnitDefaultProps, MenuUnitProps } from '../../atoms/MenuUnit/MenuUnit';
import { FileMetadata } from '../../../modules/common/types';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';
import { setNavigateAwayConfirmationModal } from '../../../lib/utils';

type FileWithIndex = {file: File; index: number };

type LessonState = {
  title?: string;
  description?: string;
  estimatedTime?: number;
  resources: AdditionalResource[];
};

export type LessonEditBlockPresenterProps = LessonEditBlockProps & {
  loading?: boolean;
  error?: Error;
  data: CourseDetails | null;
  updateLesson: (payload: UpdateLessonPayload) => Promise<LessonResponse>;
  deleteLesson: (id: number) => Promise<ApiResponse<void>>;
  createResource: (payload: AdditionalResourcePayload) => Promise<AdditionalResourceResponse>;
  updateResources: (
    payload: CreateOrUpdateAdditionalResourcesPayload
  ) => Promise<AdditionalResourcesResponse>;
  refetchCourse: () => void;
  uploadFile: (payload: File) => Promise<{ data: FileMetadata | null; error?: Error}>;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
  reorderResource: (payload: ReorderAdditionalResourcePayload) => Promise<ApiResponse<void>>;
  reorderResources: (payload: ReorderAdditionalResourcesPayload) => Promise<ApiResponse<void>>;
};

const isLessonModified = (originalLesson: LessonState | null, newLesson: LessonState): boolean => {
  return (
    originalLesson?.description !== newLesson.description
    || originalLesson?.title !== newLesson.title
  );
};

const areFilesModified = (files: FileWithIndex[]): boolean => {
  return (
    files.length > 0
  );
};

const isResourceModified = (
  originalResource: AdditionalResource,
  newResource: AdditionalResource,
): boolean => {
  return (
    originalResource.name !== newResource.name
    || originalResource.url !== newResource.url
    || originalResource.fileName !== newResource.fileName
  );
};

const areResourcesModified = (
  originalResources: AdditionalResource[] | undefined,
  newResources: AdditionalResource[],
): boolean => {
  if (!originalResources) {
    return false;
  }
  if (originalResources.length !== newResources.length) {
    return true;
  }
  for (let i = 0; i < originalResources.length; i += 1) {
    const matchingResource = newResources.find((
      resource,
    ) => resource.id === originalResources[i].id);
    if (matchingResource && isResourceModified(originalResources[i], matchingResource)) {
      return true;
    }
  }
  return false;
};

const withPresenter = (
  View: React.FC<LessonEditBlockProps>,
): React.FC<LessonEditBlockPresenterProps> => {
  const Presenter: React.FC<LessonEditBlockPresenterProps> = (props) => {
    const {
      data: course,
      loading,
      updateLesson,
      updateResources,
      setError,
      setConfirmation,
      refetchCourse,
      uploadFile,
      deleteLesson,
      reorderResources,
      createResource,
    } = props;

    const history = useHistory();
    const { lessonId, courseId } = useParams<{ lessonId: string; courseId: string }>();

    const lesson: LessonWithEstimatedTime | null = course?.lessons.find((item) => {
      return item.id === Number(lessonId);
    }) || null;

    const lessonResources = useMemo(
      () => (lesson ? cloneDeep(lesson).resources : []), [lesson],
    );

    const [lessonState, setLessonState] = useState<LessonState>({
      title: lesson?.title || '',
      description: lesson?.description || '',
      estimatedTime: lesson?.estimatedTime,
      resources: lessonResources,
    });
    const [files, setFiles] = useState<FileWithIndex[]>();

    const isLessonUpdated = isLessonModified(lesson, lessonState);
    const areResourcesUpdated = areResourcesModified(lesson?.resources, lessonState.resources);
    const areFilesUpdated = areFilesModified(files || []);
    const areChanges = isLessonUpdated || areResourcesUpdated || areFilesUpdated;

    const { t } = useTranslation();
    const {
      title, description, estimatedTime, resources,
    } = lessonState;

    useEffect(() => {
      setLessonState({
        ...lesson,
        resources: lessonResources || [],
      });
    }, [lesson, lessonResources]);

    const handleDragResource = useCallback(
      // Callback
      (dragIndex: number, hoverIndex: number): void => {
        const dragCard = lessonState.resources[dragIndex];
        if (!dragCard) return;

        const newState = cloneDeep(lessonState);
        const updatedResources = update(lessonState.resources, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        });
        newState.resources = updatedResources;
        setLessonState(newState);
      },
      // Dependencies
      [lessonState],
    );

    // TODO: Update function to use reorder single resource
    const handleReorderResource = useCallback(
      async (dragIndex: number): Promise<void> => {
        const dragCard = lessonState.resources[dragIndex];
        if (!dragCard) return;
        const resourceIds = lessonState.resources.map((item) => item.id);

        const reorder = async (): Promise<ApiResponse<void>> => {
          const response = await reorderResources({
            ids: resourceIds, lessonId: Number(lessonId),
          });
          if (!response.error) {
            setError(undefined);
          }
          return response;
        };

        const { error } = await reorder();

        if (error) {
          setError({
            error,
            description: t('error.modal.reorder.description'),
            primaryButton: {
              text: t('error.modal.reorder.button.primary'),
              onClicked: reorder,
            },
          });
        }
      },
      [lessonId, lessonState.resources, reorderResources, setError, t],
    );

    const removeFileIfExists = (index: number): void => {
      const updatedFiles = files || [];
      // gets the index of the file in the files array
      const fileIndex = updatedFiles.findIndex((file) => file.index === index);
      // if the file exists
      if (fileIndex !== -1) {
        // removes the file
        updatedFiles.splice(fileIndex, 1);
        setFiles(updatedFiles);
      }
    };

    const handleChangeFiles = (
      index: number,
      event?: React.ChangeEvent<HTMLInputElement>,
    ): void => {
      if (event) {
        const { files: upload } = event.currentTarget;
        if (upload) {
          const file = upload[0];
          const fileWithIndex = { file, index };
          const updatedFiles = [...files || []];
          // searches a file with the same index as updated file
          const fileIndex = updatedFiles.findIndex((f) => f.index === index);
          // if there is a file with the index
          if (fileIndex !== -1) {
            updatedFiles[fileIndex] = fileWithIndex;
            setFiles(updatedFiles);
          } else {
            updatedFiles.push(fileWithIndex);
            setFiles(updatedFiles);
          }
          const updatedResources = [...resources];
          updatedResources[index].fileName = file.name;
          setLessonState({
            ...lessonState,
            resources: updatedResources,
          });
        }
      }
    };

    const handleRemoveFile = (index: number): void => {
      if (index !== -1) {
        removeFileIfExists(index);
        const updatedResources = [...resources];
        // sets the url and fileName to an empty string
        updatedResources[index].url = '';
        updatedResources[index].fileName = '';
        // sets the state to update the info
        setLessonState({
          ...lessonState,
          resources: updatedResources,
        });
      }
    };

    const handleRemoveResource = (index: number): void => {
      if (index !== -1) {
        // removes the file if the resource had a file
        removeFileIfExists(index);
        const updatedResources = [...resources];
        // removes the resource
        updatedResources.splice(index, 1);
        setLessonState({
          ...lessonState,
          resources: updatedResources,
        });
      }
    };

    const handleSaveFile = async (file: File): Promise<FileMetadata> => {
      const fileMetadata = (await uploadFile(file)).data;
      if (!fileMetadata) {
        throw new Error();
      }
      return fileMetadata;
    };

    const handleSaveFiles = async (filesToSave: FileWithIndex[]): Promise<AdditionalResource[]> => {
      const updatedResources = [...resources];
      for (let i = 0; i < filesToSave.length; i += 1) {
        const currentFile = filesToSave[i];
        const { index } = currentFile;
        const savedFile = await handleSaveFile(currentFile.file);
        updatedResources[index].url = savedFile.url;
        updatedResources[index].fileName = currentFile.file.name;
      }
      return updatedResources;
    };

    const handleSaveResources = async (): Promise<AdditionalResourcesResponse> => {
      if (lessonState.resources) {
        let updatedResources = resources;
        if (files && files.length > 0) {
          updatedResources = await handleSaveFiles(files);
        }
        if (areResourcesModified(resources, updatedResources)) {
          setLessonState({
            ...lessonState,
            resources: updatedResources,
          });
        }
        return updateResources(updatedResources);
      }
      return { data: [] };
    };

    const handleUpdateLesson = async (): Promise<void> => {
      let err: Error | undefined;
      if (areResourcesUpdated) {
        const { error } = await handleSaveResources();
        if (error) {
          err = error;
        }
      }
      if (isLessonUpdated) {
        const { error } = await updateLesson({ ...lessonState });
        if (error) {
          err = error;
        }
      }
      if (!err) {
        refetchCourse();
        setError(undefined);
        setLessonState({
          ...lessonState,
          resources,
        });
        setFiles([]);
      } else {
        // Dispatch error modal
        setError({
          error: err,
          description: t('error.modal.update_lesson.description'),
          primaryButton: {
            text: t('error.modal.update_lesson.button.primary'),
            onClicked: handleUpdateLesson,
          },
        });
      }
    };

    const handleDeleteLesson = async (): Promise<void> => {
      if (!lesson) {
        return;
      }
      const { error } = await deleteLesson(lesson.id);

      setConfirmation(undefined);

      if (error) {
        // Dispatch error modal
        setError({
          error,
          description: t('error.modal.delete_lesson.description'),
          primaryButton: {
            text: t('error.modal.delete_lesson.button.primary'),
            onClicked: handleDeleteLesson,
          },
        });
      } else if (course) {
        const currentLessonIndex = course.lessons.findIndex((item) => item.id === Number(lessonId));

        let redirectIndex = -1;
        if (currentLessonIndex !== -1) {
          if (course.lessons[currentLessonIndex + 1]) {
            redirectIndex = currentLessonIndex + 1;
          } else if (course.lessons[currentLessonIndex - 1]) {
            redirectIndex = currentLessonIndex - 1;
          }
        }
        if (redirectIndex !== -1) {
          const redirectLesson = course.lessons[redirectIndex];
          history.replace(`${redirectLesson.id}`);
        } else {
          history.replace(`${getBasePath()}/course/${courseId}`);
        }
        refetchCourse();
      }
    };

    // Reset course/image state
    const discardChanges = (): void => {
      setLessonState({
        ...lesson,
        resources: cloneDeep(lesson?.resources) || [],
      });
      setFiles([]);
    };

    const handleAddResource = async (type: string): Promise<void> => {
      if (resources) {
        const newResource = {
          name: '',
          type,
          url: '',
        };
        const { data: resource, error } = await createResource(newResource);
        if (resource) {
          setLessonState({
            ...lessonState,
            resources: [
              ...resources,
              resource,
            ],
          });
          setError(undefined);
        } else if (error) {
          setError({
            error,
            description: t('error.modal.create_resource.description'),
            primaryButton: {
              text: t('error.modal.create_resource.button.primary'),
              onClicked: (): Promise<void> => handleAddResource(type),
            },
          });
        }
      }
    };

    const handleAddFileResource = (): Promise<void> => handleAddResource('FileUpload');

    const handleAddExternalLinkResource = (): Promise<void> => handleAddResource('ExternalLink');

    const handleChangeResourceName = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      index: number,
    ): void => {
      if (resources[index]) {
        const newResourceState = [...resources];
        newResourceState[index].name = event.target.value;
        setLessonState({
          ...lessonState,
          resources: newResourceState,
        });
      }
    };

    const handleChangeLink = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      index: number,
    ): void => {
      if (resources[index]) {
        const newResourceState = [...resources];
        newResourceState[index].url = event.target.value;
        setLessonState({
          ...lessonState,
          resources: newResourceState,
        });
      }
    };

    // Get change handler for lesson input fields
    const getChangeHandler = (property: keyof LessonState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setLessonState({
          ...lessonState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const tableItems: TableItemProps[] = resources.map((
      resource, index,
    ): TableItemProps => {
      const resourceType = resource.type === 'FileUpload' ? 'FileUpload' : 'ExternalLink';
      const readableResourceType = resourceType === 'FileUpload' ? 'File' : 'Link';

      let state: TableItemStateType | undefined;
      if (resourceType === 'FileUpload') {
        if (resource.fileName) {
          state = 'FileSelected';
        } else {
          state = 'NoFileSelected';
        }
      }

      const removeFile = (): void => handleRemoveFile(index);
      const removeResource = (): void => handleRemoveResource(index);

      return {
        ...defaultTableItemProps,
        state,
        index,
        onDrag: handleDragResource,
        onDrop: handleReorderResource,
        id: resource.id,
        type: resourceType,
        resourceTypeSelector: {
          ...defaultTableItemProps.resourceTypeSelector,
          text: {
            ...defaultTableItemProps.resourceTypeSelector.text,
            value: readableResourceType,
          },
        },
        resourceNameInput: {
          ...defaultTableItemProps.resourceNameInput,
          textValue: resource.name,
          onTextChanged: (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
          ): void => handleChangeResourceName(event, index),
        },
        selectFileItem: {
          ...defaultTableItemProps.selectFileItem,
          button: {
            ...defaultTableItemProps.selectFileItem.button,
          },
          text: {
            ...defaultTableItemProps.selectFileItem.text,
            value: resource.fileName,
          },
          onChangeFile: (event: React.ChangeEvent<HTMLInputElement>) => handleChangeFiles(index, event),
          onDeleteFile: removeFile,
        },
        linkInput: {
          ...defaultTableItemProps.linkInput,
          textValue: resource.url,
          onTextChanged: (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
          ): void => handleChangeLink(event, index),
        },
        deleteButton: {
          ...defaultTableItemProps.deleteButton,
          onButtonClicked: removeResource,
        },
      };
    });

    const addResourceList: MenuUnitProps[] = [
      {
        ...menuUnitDefaultProps,
        icon: {
          ...menuUnitDefaultProps.icon,
          asset: 'Upload',
        },
        text: {
          ...menuUnitDefaultProps.text,
          value: 'Upload file (DOCX, HTML, PPT, PDF)',
        },
        onMenuUnitClicked: handleAddFileResource,
      },
      {
        ...menuUnitDefaultProps,
        icon: {
          ...menuUnitDefaultProps.icon,
          asset: 'ExternalLink',
        },
        text: {
          ...menuUnitDefaultProps.text,
          value: 'External Link',
        },
        onMenuUnitClicked: handleAddExternalLinkResource,
      },
    ];

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateLesson, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_lesson.description', { lessonName: lesson?.title }),
        primaryButton: {
          onClicked: handleDeleteLesson
        }
      })
    };

    const buttonsDisabled = !(isLessonUpdated || areResourcesUpdated || areFilesUpdated);

    const viewProps: LessonEditBlockProps = {
      ...defaultProps,
      ...props,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: title,
        },
      },
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      description: {
        ...defaultProps.description,
        textArea: { textValue: description, onTextChanged: getChangeHandler('description') },
      },
      estimatedTime: {
        ...defaultProps.estimatedTime,
        inputWithStepper: {
          ...defaultProps.estimatedTime.inputWithStepper,
          input: {
            ...defaultProps.estimatedTime.inputWithStepper?.input,
            textValue: String(estimatedTime),
          },
        },
      },
      resourceTable: {
        ...defaultProps.resourceTable,
        tableItemList: {
          ...defaultProps.resourceTable.tableItemList,
          tableItems,
        },
        addResourceMenu: {
          ...defaultProps.resourceTable.addResourceMenu,
          menuUnits: addResourceList,
        },
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            loading,
            onButtonClicked: handleUpdateLesson,
            disabled: buttonsDisabled,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: buttonsDisabled,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
    };

    return (
      <>
        <Prompt when={areChanges} message={handleBlockedNavigation} />
        <View {...viewProps} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
