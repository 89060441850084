import React from 'react';
import cx from 'classnames';

import styles from './InputField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import Input, { InputProps } from '../../atoms/Input';

export type InputFieldStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Error' as InputFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  input: {
    style: 'Grey',
    textPlaceholder: 'Input text',
  } as InputProps,
  error: {
    type: 'Paragraph2',
    style: 'Danger',
    size: 'Medium',
    align: 'Left',
    value: 'Something went wrong',
  } as TextProps,
};

export type InputFieldProps = {
  state?: InputFieldStateType;
  label?: TextProps;
  input?: InputProps;
  className?: string;
  error?: TextProps;
};

const InputField: React.FC<InputFieldProps> = ({
  state,
  label,
  input,
  className,
  error,
}) => {

  const currentStyle = styles[`inputField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  const inputView = (
    <Input
      className={styles.input}
      {...input} />
  );
  
  let errorView;
  
  switch (state) {
    case 'Default':
      break;
    case 'Error':
      errorView = (
        <Text
          className={styles.error}
          {...error} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {inputView}
      {errorView}
    </div>
  );
};

InputField.defaultProps = defaultProps;

export default InputField;
