import React from 'react';
import useAllCourseDetails from '../../../../modules/course/useAllCourseDetails';
import { CourseDetails } from '../../../../modules/types';

export type MainContextValue = {
  courseState: {
    loading: boolean;
    error: Error | undefined;
    data: CourseDetails[] | null;
    refetch: () => void;
  };
};

const defaultValue = {
  courseState: {
    loading: false,
    error: undefined,
    data: null,
    refetch: (): void => {},
  },
};

export const MainContext = React.createContext<MainContextValue>(defaultValue);

const MainContextProvider: React.FC = ({ children }) => {
  const courseState = useAllCourseDetails();

  return (
    <MainContext.Provider value={{ courseState }}>
      {children}
    </MainContext.Provider>
  );
};

export default MainContextProvider;
