import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { IS_DEV } from '../../lib/config';
import { AppDetails } from './types';

const DEV_APP_DETAILS: AppDetails = {
  id: 1,
  uuid: '1',
  name: 'Local LMS',
  services: [{
    id: 1,
    uuid: '1',
    name: 'Local LMS',
    type: 'LMS',
  }],
  account: {
    id: 1,
    uuid: '1',
    firstName: 'John',
    lastName: 'Doe',
    email: 'john.doe@spindl.local',
    enabled: true,
  },
}

export const getAppDetails = async (): Promise<AppDetails> => {
  if (IS_DEV) {
    return DEV_APP_DETAILS;
  }
  try {
    const { data } = await axios.get<AppDetails>(
      `${getServerUrl()}/details`,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};