import React from 'react';
import cx from 'classnames';

import styles from './UploadButton.module.scss';

import Icon, { IconProps } from '../Icon';
import Text, { TextProps } from '../Text';

export type UploadButtonUploadButtonTypeType = 'button' | 'submit' | 'reset';

export const defaultProps = {
  icon: {
    asset: 'UploadImage',
    style: 'Blue',
  } as IconProps,
  text: {
    type: 'Paragraph2',
    style: 'Grey',
    size: 'Medium',
    align: 'Left',
    value: 'Upload picture',
  } as TextProps,
};

export type UploadButtonProps = {
  uploadButtonType?: UploadButtonUploadButtonTypeType;
  onUploadButtonClicked?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: IconProps;
  text?: TextProps;
  className?: string;
};

const UploadButton: React.FC<UploadButtonProps> = ({
  uploadButtonType,
  onUploadButtonClicked,
  icon,
  text,
  className,
}) => {
  return (
    <button
      type={uploadButtonType}
      onClick={onUploadButtonClicked}
      className={cx(styles.uploadButton, className)}>
      <Icon
        className={styles.icon}
        {...icon} />
      <Text
        className={styles.text}
        {...text} />
    </button>
  );
};

UploadButton.defaultProps = defaultProps;

export default UploadButton;
