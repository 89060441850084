import { useCallback } from 'react';
import usePatch from '../../lib/api/useUpdate';
import { ReorderEntityPayload } from '../common/types';
import { reorderAnswer } from './api';
import { UseReorderAnswerResult } from './types';

const useReorderQuestion = (): UseReorderAnswerResult => {
  const onReorder = useCallback((
    payload: ReorderEntityPayload,
  ): Promise<void> => reorderAnswer(payload), []);
  return usePatch(onReorder);
};

export default useReorderQuestion;
