import React from 'react';
import cx from 'classnames';

import styles from './TextAreaField.module.scss';

import Text, { TextProps } from '../../atoms/Text';
import TextArea, { TextAreaProps } from '../../atoms/TextArea';

export type TextAreaFieldStateType = 'Default' | 'Error';

export const defaultProps = {
  state: 'Error' as TextAreaFieldStateType,
  label: {
    type: 'Paragraph1',
    style: 'Dark',
    size: 'Large',
    align: 'Left',
    value: 'Label',
  } as TextProps,
  textArea: {
    textPlaceholder: 'Text area text',
  } as TextAreaProps,
  error: {
    type: 'Paragraph2',
    style: 'Danger',
    size: 'Medium',
    align: 'Left',
    value: 'Something went wrong',
  } as TextProps,
};

export type TextAreaFieldProps = {
  state?: TextAreaFieldStateType;
  label?: TextProps;
  textArea?: TextAreaProps;
  className?: string;
  error?: TextProps;
};

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  state,
  label,
  textArea,
  className,
  error,
}) => {

  const currentStyle = styles[`textAreaField${state}`];

  const labelView = (
    <Text
      className={styles.label}
      {...label} />
  );
  const textAreaView = (
    <TextArea
      className={styles.textArea}
      {...textArea} />
  );
  
  let errorView;
  
  switch (state) {
    case 'Default':
      break;
    case 'Error':
      errorView = (
        <Text
          className={styles.error}
          {...error} />
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {labelView}
      {textAreaView}
      {errorView}
    </div>
  );
};

TextAreaField.defaultProps = defaultProps;

export default TextAreaField;
