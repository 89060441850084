import React from 'react';
import cx from 'classnames';

import styles from './EditCourseMenu.module.scss';

import MenuCourse, { MenuCourseProps } from '../../atoms/MenuCourse';
import Divider, { DividerProps } from '../../atoms/Divider';
import MenuLessonItemList, { MenuLessonItemListProps } from '../../organisms/MenuLessonItemList';
import MenuUnit, { MenuUnitProps } from '../../atoms/MenuUnit';

export const defaultProps = {
  menuCourse: {
    type: 'Selected',
    text: {
      type: 'Subtitle1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'How to Build a Compelling Competitive Strategy',
    },
  } as MenuCourseProps,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  menuLessonItemList: {
    menuLessonItems: [
        {
          state: 'Collapsed',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Collapsed',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 1',
            },
            collapsedIcon: {
              asset: 'ChevronUp',
              style: 'Dark',
            },
          },
        },
        {
          state: 'Collapsed',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Collapsed',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 2',
            },
            collapsedIcon: {
              asset: 'ChevronUp',
              style: 'Dark',
            },
          },
        },
        {
          state: 'Expanded',
          type: 'Default',
          menuLesson: {
            type: 'Default',
            state: 'Expanded',
            text: {
              type: 'Paragraph3',
              style: 'Dark',
              size: 'Small',
              align: 'Left',
              value: 'Lesson 3',
            },
            expandedIcon: {
              asset: 'ChevronDown',
              style: 'Dark',
            },
          },
          menuUnitList: {
            menuUnits: [
                {
                  type: 'Default',
                  icon: {
                    asset: 'File',
                    style: 'Grey',
                  },
                  text: {
                    type: 'Paragraph3',
                    style: 'Dark',
                    size: 'Small',
                    align: 'Left',
                    value: 'Quiz',
                  },
                },
                {
                  type: 'Default',
                  icon: {
                    asset: 'File',
                    style: 'Grey',
                  },
                  text: {
                    type: 'Paragraph3',
                    style: 'Dark',
                    size: 'Small',
                    align: 'Left',
                    value: 'Video',
                  },
                },
                {
                  type: 'Default',
                  icon: {
                    asset: 'File',
                    style: 'Grey',
                  },
                  text: {
                    type: 'Paragraph3',
                    style: 'Dark',
                    size: 'Small',
                    align: 'Left',
                    value: 'Quiz',
                  },
                },
            ],
          },
          addUnit: {
            type: 'AddItem',
            icon: {
              asset: 'PlusCircle',
              style: 'Blue',
            },
            text: {
              type: 'Subtitle2',
              style: 'Brand',
              size: 'Medium',
              align: 'Left',
              value: 'Add Unit',
            },
          },
        },
    ],
  } as MenuLessonItemListProps,
  addLesson: {
    type: 'AddItem',
    icon: {
      asset: 'PlusCircle',
      style: 'Blue',
    },
    text: {
      type: 'Subtitle2',
      style: 'Brand',
      size: 'Medium',
      align: 'Left',
      value: 'Add Lesson',
    },
  } as MenuUnitProps,
};

export type EditCourseMenuProps = {
  loading?: boolean;
  error?: Error;
  menuCourse?: MenuCourseProps;
  divider?: DividerProps;
  menuLessonItemList?: MenuLessonItemListProps;
  addLesson?: MenuUnitProps;
  className?: string;
};

const EditCourseMenu: React.FC<EditCourseMenuProps> = ({
  menuCourse,
  divider,
  menuLessonItemList,
  addLesson,
  className,
}) => {
  return (
    <div className={cx(styles.editCourseMenu, className)}>
      <MenuCourse
        className={styles.menuCourse}
        {...menuCourse} />
      <Divider
        className={styles.divider}
        {...divider} />
      <MenuLessonItemList
        className={styles.menuLessonItemList}
        {...menuLessonItemList} />
      <MenuUnit
        className={styles.addLesson}
        {...addLesson} />
    </div>
  );
};

EditCourseMenu.defaultProps = defaultProps;

export default EditCourseMenu;
