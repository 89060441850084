import React from 'react';
import cx from 'classnames';

import styles from './HtmlEditBlock.module.scss';

import BlockHeader, { BlockHeaderProps } from '../../molecules/BlockHeader';
import InputField, { InputFieldProps } from '../../molecules/InputField';
import TextAreaField, { TextAreaFieldProps } from '../../molecules/TextAreaField';
import InputWithStepperField, { InputWithStepperFieldProps } from '../../molecules/InputWithStepperField';
import HtmlEditor, { HtmlEditorProps } from '../../molecules/HtmlEditor';
import ButtonSection, { ButtonSectionProps } from '../../molecules/ButtonSection';
import ConfirmationModal, { ConfirmationModalProps } from '../../organisms/ConfirmationModal';

export const defaultProps = {
  blockHeader: {
    type: 'Default',
    text: {
      type: 'Title1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'HTML',
    },
  } as BlockHeaderProps,
  name: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Unit name',
    },
    input: {
      style: 'Grey',
      textPlaceholder: 'HTML',
    },
  } as InputFieldProps,
  description: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Unit description',
    },
    textArea: {
      textPlaceholder: 'Text area text',
    },
  } as TextAreaFieldProps,
  estimatedTime: {
    state: 'Default',
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'Estimated time (in minutes)',
    },
    inputWithStepper: {
      input: {
        style: 'Grey',
        textPlaceholder: '00',
      },
      stepDown: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronUp',
          style: 'Dark',
        },
      },
      stepUp: {
        type: 'IconButton',
        size: 'Small',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'ChevronDown',
          style: 'Dark',
        },
      },
    },
  } as InputWithStepperFieldProps,
  htmlEditor: {
    label: {
      type: 'Paragraph1',
      style: 'Dark',
      size: 'Large',
      align: 'Left',
      value: 'HTML editor',
    },
  } as HtmlEditorProps,
  buttonSection: {
    state: 'WithDeleteButton',
    divider: {
      style: 'Horizontal',
    },
    buttonGroup: {
      primary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'Colour',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'White',
          size: 'Medium',
          align: 'Center',
          value: 'Save',
        },
      },
      secondary: {
        type: 'TextButton',
        size: 'Large',
        fill: 'White',
        colour: 'Brand',
        text: {
          type: 'Subtitle1',
          style: 'Brand',
          size: 'Medium',
          align: 'Center',
          value: 'Discard',
        },
      },
    },
    tertiary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Danger',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Delete',
      },
    },
  } as ButtonSectionProps,
};

export type HtmlEditBlockProps = {
  blockHeader?: BlockHeaderProps;
  name?: InputFieldProps;
  description?: TextAreaFieldProps;
  estimatedTime?: InputWithStepperFieldProps;
  htmlEditor?: HtmlEditorProps;
  buttonSection?: ButtonSectionProps;
  className?: string;
};

const HtmlEditBlock: React.FC<HtmlEditBlockProps> = ({
  blockHeader,
  name,
  description,
  estimatedTime,
  htmlEditor,
  buttonSection,
  className,
}) => {
  return (
    <div className={cx(styles.htmlEditBlock, className)}>
      <BlockHeader
        className={styles.blockHeader}
        {...blockHeader} />
      <div className={styles.card}>
        <InputField
          className={styles.name}
          {...name} />
        <TextAreaField
          className={styles.description}
          {...description} />
        <InputWithStepperField
          className={styles.estimatedTime}
          {...estimatedTime} />
        <HtmlEditor
          className={styles.htmlEditor}
          {...htmlEditor} />
        <ButtonSection
          className={styles.buttonSection}
          {...buttonSection} />
      </div>
    </div>
  );
};

HtmlEditBlock.defaultProps = defaultProps;

export default HtmlEditBlock;
