import React from 'react';
import cx from 'classnames';

import styles from './CourseCardList.module.scss';

import CourseCard, { CourseCardProps } from '../../molecules/CourseCard';

export const defaultProps = {
  courseCards: [
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
      {
        title: {
          type: 'Title3',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'How to Build a Compelling Competitve Strategy',
        },
        units: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '5 Lessons | 2 Quiz | 2 Videos',
        },
        estimatedTime: {
          type: 'Paragraph3',
          style: 'Grey',
          size: 'Small',
          align: 'Left',
          value: '4 Hours',
        },
        description: {
          type: 'Paragraph3',
          style: 'Dark',
          size: 'Small',
          align: 'Left',
          value: 'In this course you will learn how to build a robust competitive strategy that positions your business strongly against your competition.',
        },
        button: {
          type: 'TextButton',
          size: 'Medium',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Paragraph3',
            style: 'Brand',
            size: 'Small',
            align: 'Center',
            value: 'Open course',
          },
        },
      },
  ] as CourseCardProps[],
};

export type CourseCardListProps = {
  courseCards?: CourseCardProps[];
  className?: string;
};

const CourseCardList: React.FC<CourseCardListProps> = ({
  courseCards,
  className,
}) => {
  const courseCardArray = courseCards?.map((courseCard) => (
    <CourseCard
      key={courseCard.id}
      className={styles.courseCard}
      {...courseCard} />
  ));
  
  return (
    <div className={cx(styles.courseCardList, className)}>
      {courseCardArray}
    </div>
  );
};

CourseCardList.defaultProps = defaultProps;

export default CourseCardList;
