import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { uploadImage } from './api';
import { ImageMetadata, UseUploadImageResult } from './types';

const useUploadImage = (): UseUploadImageResult => {
  const update = useCallback((
    payload: FormData,
  ): Promise<ImageMetadata> => uploadImage(payload), []);
  return useUpdate(update);
};

export default useUploadImage;
