import React, { useContext } from 'react';
import { MenuLessonItemProps } from './MenuLessonItem';
import { MenuLessonPresenterProps } from './MenuLessonItem.presenter';
import useCreateUnit from '../../../modules/unit/useCreateUnit';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import useReorderUnit from '../../../modules/unit/useReorderUnit';
import useReorderUnits from '../../../modules/unit/useReorderUnits';

const withInteractor = (
  Presenter: React.FC<MenuLessonPresenterProps>
): React.FC<MenuLessonItemProps> => {
  const Interactor: React.FC<MenuLessonItemProps> = (props) => {
    const lessonId = props.lesson.id;
    const { courseState } = useContext(CourseDetailsContext);
    const [createUnitState, createUnit] = useCreateUnit(lessonId);
    const [reorderUnitState, reorderUnit] = useReorderUnit();
    // TODO: Remove this hook
    const [, reorderUnits] = useReorderUnits();

    const loading = createUnitState.loading || reorderUnitState.loading;
    const error = createUnitState.error || reorderUnitState.error;

    return (
      <Presenter
        {...props}
        loading={loading}
        error={error}
        createUnit={createUnit}
        reorderUnit={reorderUnit}
        reorderUnits={reorderUnits}
        refetchCourse={courseState.refetch}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
