import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { updateUnit } from './api';
import { Unit, UpdateUnitPayload, UseUpdateUnitResult } from './types';

const useUpdateUnit = (id: number): UseUpdateUnitResult => {
  const update = useCallback((
    payload: UpdateUnitPayload,
  ): Promise<Unit> => updateUnit(id, payload), [id]);
  return useUpdate(update);
};

export default useUpdateUnit;
