import axios from '../../lib/api/axios';
import { getMediaUrl, toApiError } from '../../lib/api/utils';
import {
  ImageMetadata,
  FileMetadata,
  CreateMultipartUploadPayload,
  CompleteMultipartUploadPayload,
  CreateMultipartUploadResponse,
  MediaUploadType,
} from './types';

export const uploadImage = async (
  payload: FormData,
): Promise<ImageMetadata> => {
  try {
    const { data } = await axios.post<ImageMetadata>(
      `${getMediaUrl()}/images`,
      payload,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const createMultipartUpload = async (
  payload: CreateMultipartUploadPayload,
  mediaType: MediaUploadType,
): Promise<CreateMultipartUploadResponse> => {
  try {
    const { data } = await axios.post<CreateMultipartUploadResponse>(
      `${getMediaUrl()}/${mediaType}/actions/createUpload`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const completeMultipartUpload = async (
  payload: CompleteMultipartUploadPayload,
  mediaType: MediaUploadType,
): Promise<FileMetadata> => {
  try {
    const { data } = await axios.post<FileMetadata>(
      `${getMediaUrl()}/${mediaType}/actions/completeUpload`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};
