import { useCallback } from 'react';
import useDelete from '../../lib/api/useDelete';
import { deleteCourse } from './api';
import { UseDeleteCourseResult } from './types';

const useDeleteCourse = (): UseDeleteCourseResult => {
  const onDelete = useCallback((id: number): Promise<void> => deleteCourse(id), []);
  return useDelete(onDelete);
};

export default useDeleteCourse;
