import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import useCreateAdditionalResource from '../../../modules/additionalResource/useCreateAdditionalResource';
import useReorderAdditionalResource from '../../../modules/additionalResource/useReorderAdditionalResource';
import useReorderAdditionalResources from '../../../modules/additionalResource/useReorderAdditionalResources';
import useUpdateAdditionalResource from '../../../modules/additionalResource/useUpdateAdditionalResource';
import useMultipartUpload from '../../../modules/common/useMultipartUpload';
import useDeleteLesson from '../../../modules/lesson/useDeleteLesson';
import useUpdateLesson from '../../../modules/lesson/useUpdateLesson';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import { ConfirmationContext } from '../../organisms/ConfirmationModal/context';
import { ErrorContext } from '../../organisms/ModalError/context';
import { LessonEditBlockProps } from './LessonEditBlock';
import { LessonEditBlockPresenterProps } from './LessonEditBlock.presenter';

const withInteractor = (
  Presenter: React.FC<LessonEditBlockPresenterProps>,
): React.FC<LessonEditBlockProps> => {
  const Interactor: React.FC<LessonEditBlockProps> = (props) => {
    const { setError } = useContext(ErrorContext);
    const { setConfirmation } = useContext(ConfirmationContext);
    const { courseState } = useContext(CourseDetailsContext);

    const { lessonId: id } = useParams<{ lessonId: string }>();
    const lessonId = Number(id);

    const [updateLessonState, updateLesson] = useUpdateLesson(lessonId);
    const [createResourceState, createResource] = useCreateAdditionalResource(lessonId);
    const [updateResourceState, updateResources] = useUpdateAdditionalResource(lessonId);
    const [reorderResourceState, reorderResource] = useReorderAdditionalResource();
    // TODO: Remove this hook
    const [, reorderResources] = useReorderAdditionalResources();

    const [
      { loading: deleteLoading }, deleteLesson,
    ] = useDeleteLesson();

    const [uploadFileState, uploadFile] = useMultipartUpload('files');

    const loading = courseState.loading
      || updateLessonState.loading
      || updateResourceState.loading
      || deleteLoading
      || reorderResourceState.loading
      || createResourceState.loading
      || uploadFileState.loading;

    return (
      <Presenter
        {...props}
        data={courseState.data}
        loading={loading}
        error={courseState.error}
        setError={setError}
        setConfirmation={setConfirmation}
        updateLesson={updateLesson}
        deleteLesson={deleteLesson}
        refetchCourse={courseState.refetch}
        uploadFile={uploadFile}
        updateResources={updateResources}
        createResource={createResource}
        reorderResource={reorderResource}
        reorderResources={reorderResources}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
