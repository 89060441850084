import React from 'react';
import cx from 'classnames';

import styles from './QuestionItemList.module.scss';

import QuestionItem, { QuestionItemProps } from '../QuestionItem';

export const defaultProps = {
  questionItems: [
    {
      state: 'View',
      divider: {
        style: 'Horizontal',
      },
      question: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Question goes here',
      },
      buttonGroup: {
        primary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Edit',
          },
        },
        secondary: {
          type: 'IconButton',
          size: 'Large',
          fill: 'White',
          colour: 'Basic',
          icon: {
            asset: 'MoreVertical',
            style: 'Blue',
          },
        },
      },
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Options',
      },
      answerOptionList: {
        answerOptions: [
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Checked',
              icon: {
                asset: 'SquareCheck',
                style: 'Blue',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
        ],
      },
    },
    {
      state: 'View',
      divider: {
        style: 'Horizontal',
      },
      question: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Question goes here',
      },
      buttonGroup: {
        primary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Edit',
          },
        },
        secondary: {
          type: 'IconButton',
          size: 'Large',
          fill: 'White',
          colour: 'Basic',
          icon: {
            asset: 'MoreVertical',
            style: 'Blue',
          },
        },
      },
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Options',
      },
      answerOptionList: {
        answerOptions: [
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Checked',
              icon: {
                asset: 'SquareCheck',
                style: 'Blue',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
        ],
      },
    },
    {
      state: 'View',
      divider: {
        style: 'Horizontal',
      },
      question: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Question goes here',
      },
      buttonGroup: {
        primary: {
          type: 'TextButton',
          size: 'Large',
          fill: 'White',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'Brand',
            size: 'Medium',
            align: 'Center',
            value: 'Edit',
          },
        },
        secondary: {
          type: 'IconButton',
          size: 'Large',
          fill: 'White',
          colour: 'Basic',
          icon: {
            asset: 'MoreVertical',
            style: 'Blue',
          },
        },
      },
      label: {
        type: 'Paragraph1',
        style: 'Dark',
        size: 'Large',
        align: 'Left',
        value: 'Options',
      },
      answerOptionList: {
        answerOptions: [
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Unchecked',
              icon: {
                asset: 'Square',
                style: 'Dark',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
          {
            state: 'View',
            checkbox: {
              state: 'Checked',
              icon: {
                asset: 'SquareCheck',
                style: 'Blue',
              },
            },
            text: {
              type: 'Paragraph1',
              style: 'Dark',
              size: 'Large',
              align: 'Left',
              value: 'Option',
            },
          },
        ],
      },
    },
  ] as QuestionItemProps[],
};

export type QuestionItemListProps = {
  questionItems?: QuestionItemProps[];
  className?: string;
};

const QuestionItemList: React.FC<QuestionItemListProps> = ({
  questionItems,
  className,
}) => {
  const questionItemArray = questionItems?.map((questionItem) => (
    <QuestionItem
      key={questionItem.id}
      className={styles.questionItem}
      {...questionItem} />
  ));

  return (
    <div className={cx(styles.questionItemList, className)}>
      {questionItemArray}
    </div>
  );
};

QuestionItemList.defaultProps = defaultProps;

export default QuestionItemList;
