import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { removeCourseTag } from './api';
import { UseUpdateCourseTagResult, UpdateCourseTagPayload } from './types';

const useRemoveCourseTag = (courseId: number): UseUpdateCourseTagResult => {
  const handleRemoveTag = useCallback((
    payload: UpdateCourseTagPayload,
  ): Promise<void> => removeCourseTag(courseId, payload), [courseId]);
  return useUpdate(handleRemoveTag);
};

export default useRemoveCourseTag;
