import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getCourseDetails } from './api';
import { CourseDetails } from '../types';

export type UseCourseDetailsResult = {
  loading: boolean;
  error: undefined | Error;
  data: CourseDetails | null;
  refetch: () => void;
};

const useCourseDetails = (id: number | string): UseCourseDetailsResult => {
  const get = useCallback((): Promise<CourseDetails> => getCourseDetails(id), [id]);
  return useGet(get);
};

export default useCourseDetails;
