import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { CreateTagPayload, Tag, UpdateTagPayload } from './types';

// eslint-disable-next-line import/prefer-default-export
export const getTags = async (): Promise<Tag[]> => {
  try {
    const { data } = await axios.get<Tag[]>(
      `${getServerUrl()}/tags`,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const postTag = async (
  payload: CreateTagPayload,
): Promise<Tag> => {
  try {
    const { data } = await axios.post<Tag>(
      `${getServerUrl()}/tags`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const updateTag = async (
  id: number, payload: UpdateTagPayload,
): Promise<Tag> => {
  try {
    const { data } = await axios.patch<Tag>(
      `${getServerUrl()}/tags/${id}`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const deleteTag = async (
  id: number,
): Promise<void> => {
  try {
    await axios.delete<void>(
      `${getServerUrl()}/tags/${id}`,
    );
  } catch (error) {
    throw toApiError(error);
  }
};
