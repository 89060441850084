import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../common/types';
import { UpdateQuestionsPayload, Question, CreateQuestionPayload } from './types';

export const postQuestion = async (
  payload: CreateQuestionPayload,
): Promise<Question> => {
  try {
    const { quizId, examId, question } = payload;
    let data;
    if (quizId) {
      ({ data } = await axios.post<Question>(
        `${getServerUrl()}/quizzes/${quizId}/questions`,
        question,
      ));
    } else if (examId) {
      ({ data } = await axios.post<Question>(
        `${getServerUrl()}/exams/${quizId}/questions`,
        question,
      ));
    } else {
      throw new Error('Must provide question or exam id');
    }
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const putQuestions = async (
  payload: UpdateQuestionsPayload,
): Promise<Question[]> => {
  try {
    const { quizId, examId, ...rest } = payload;
    let data;
    if (quizId) {
      ({ data } = await axios.put<Question[]>(
        `${getServerUrl()}/quizzes/${quizId}/actions/updateQuestions`,
        rest,
      ));
    } else if (examId) {
      ({ data } = await axios.put<Question[]>(
        `${getServerUrl()}/exams/${examId}/actions/updateQuestions`,
        rest,
      ));
    } else {
      throw new Error('Must provide question or exam id');
    }

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderQuestion = async (
  payload: ReorderEntityPayload,
): Promise<void> => {
  const { id, order } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/questions/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

// TODO: Remove this function
export const reorderQuestions = async (
  payload: ReorderEntitiesPayload,
): Promise<void> => {
  const { ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/questions/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
