// TODO: Remove this file
import { useCallback } from 'react';
import { ReorderEntitiesPayload } from '../../modules/common/types';
import { ApiResponse } from '../api/types';

const useHandleReorder = <T extends { id: number; order: number }>(
  state: T[],
  callback: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>,
  refetch: () => void,
): (dragIndex: number) => void => {
  return useCallback(
    async (dragIndex: number) => {
      const dragCard = state[dragIndex];
      if (!dragCard) return;

      await callback({ ids: state.map((item) => item.id) });
      refetch();
    },
    [callback, refetch, state],
  );
};

export default useHandleReorder;
