import React from 'react';
import cx from 'classnames';

import styles from './TextArea.module.scss';

export const defaultProps = {
  textPlaceholder: 'Text area text',
};

export type TextAreaProps = {
  textValue?: string;
  textPlaceholder?: string;
  onTextChanged?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void;
  className?: string;
};

const TextArea: React.FC<TextAreaProps> = ({
  textValue,
  textPlaceholder,
  onTextChanged,
  className,
}) => {
  return (
    <div className={cx(styles.textArea, className)}>
      <textarea
        placeholder={textPlaceholder}
        value={textValue}
        onChange={onTextChanged}
        className={styles.text}/>
    </div>
  );
};

TextArea.defaultProps = defaultProps;

export default TextArea;
