import React from 'react';
import cx from 'classnames';

import styles from './MainMenuItemList.module.scss';

import MainMenuItem, { MainMenuItemProps } from '../../atoms/MainMenuItem';

export const defaultProps = {
  mainMenuItems: [
      {
        type: 'Default',
        icon: {
          asset: 'Grid',
          style: 'Dark',
        },
        text: {
          type: 'Subtitle2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Courses',
        },
      },
      {
        type: 'Selected',
        icon: {
          asset: 'Grid',
          style: 'Dark',
        },
        text: {
          type: 'Subtitle2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Courses',
        },
      },
      {
        type: 'Default',
        icon: {
          asset: 'Grid',
          style: 'Dark',
        },
        text: {
          type: 'Subtitle2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Courses',
        },
      },
  ] as MainMenuItemProps[],
};

export type MainMenuItemListProps = {
  mainMenuItems?: MainMenuItemProps[];
  className?: string;
};

const MainMenuItemList: React.FC<MainMenuItemListProps> = ({
  mainMenuItems,
  className,
}) => {
  const mainMenuItemArray = mainMenuItems?.map((mainMenuItem) => (
    <MainMenuItem
      className={styles.mainMenuItem}
      {...mainMenuItem} />
  ));
  
  return (
    <div className={cx(styles.mainMenuItemList, className)}>
      {mainMenuItemArray}
    </div>
  );
};

MainMenuItemList.defaultProps = defaultProps;

export default MainMenuItemList;
