import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { updateTag } from './api';
import { Tag, UpdateTagPayload, UseUpdateTagResult, } from './types';

const useUpdateTag = (): UseUpdateTagResult => {
  const update = useCallback((
    payload: UpdateTagPayload,
  ): Promise<Tag> => updateTag(payload.id, payload), []);
  return useUpdate(update);
};

export default useUpdateTag;
