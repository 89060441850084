import { XYCoord } from 'dnd-core';
import { useRef } from 'react';
import { useDrop, DropTargetMonitor, useDrag } from 'react-dnd';

type UseDragAndDropParams = {
  type: string;
  direction: 'vertical' | 'horizontal';
  onDrag?: (dragIndex: number, hoverIndex: number) => void;
  onDrop?: (dragIndex: number, hoverIndex: number) => void;
  canDrag?: boolean;
  index?: number;
  id?: number;
};

type DragItem = {
  index: number;
  id: number;
  type: string;
};

const useDragAndDrop = <T extends HTMLElement>(
  params: UseDragAndDropParams,
): { ref: React.RefObject<T>; isDragging: boolean; handlerId: string | symbol | null } => {
  const {
    id, type, direction, onDrag, onDrop, index, canDrag,
  } = params;
  const ref = useRef<T>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: type,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current || !onDrag || typeof index === 'undefined') {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      if (direction === 'vertical') {
        // Get vertical middle
        const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Get pixels to the top
        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }
      } else {
        // Get horizontal middle
        const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;

        // Get pixels to the left
        const hoverClientX = (clientOffset as XYCoord).x - hoverBoundingRect.left;

        // Only perform the move when the mouse has crossed half of the items width
        // When dragging leftwards, only move when the cursor is left 50%
        // When dragging rightwards, only move when the cursor is right 50%

        // Dragging rightwards
        if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
          return;
        }

        // Dragging leftwards
        if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
          return;
        }
      }
      // Time to actually perform the action
      onDrag(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      // eslint-disable-next-line no-param-reassign
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type,
    item: () => {
      return { id, index } as DragItem;
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: DragItem) => {
      if (!onDrop || typeof index === 'undefined') {
        return;
      }
      onDrop(item.index, index);
    },
    canDrag,
  });

  drag(drop(ref));

  return { ref, isDragging, handlerId };
};

export default useDragAndDrop;
