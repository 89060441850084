import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { CourseDetails, CourseStatus } from '../../../modules/types';
import { ErrorContextValue } from '../../organisms/ModalError/context/ErrorContext';
import { defaultProps as defaultCourseHeaderProps } from '../../organisms/CourseHeader/CourseHeader';
import { CourseEditLayoutProps, defaultProps } from './CourseEditLayout';
import { defaultProps as defaultConfirmationModalProps } from '../../organisms/ConfirmationModal/ConfirmationModal';
import {
  CourseResponse,
  UpdateCoursePayload,
} from '../../../modules/course/types';
import { handlePublishCourse } from './utils';

export type CourseEditLayoutPresenterProps = CourseEditLayoutProps & {
  courseDetails: CourseDetails | null;
  setError: ErrorContextValue['setError'];
  refetch: () => void;
  isEditing: boolean;
  updateCourse: (payload: UpdateCoursePayload) => Promise<CourseResponse>;
};

const withPresenter = (
  View: React.FC<CourseEditLayoutProps>,
): React.FC<CourseEditLayoutPresenterProps> => {
  const Presenter: React.FC<CourseEditLayoutPresenterProps> = (props) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { appId, serviceName } = useParams<{
      appId: string;
      serviceName: string;
    }>();

    const {
      error,
      refetch,
      setError,
      isEditing,
      courseDetails,
      updateCourse,
    } = props;

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);

    const handleCloseModal = (): void => {
      setConfirmationModalOpen(false);
    };

    useEffect(() => {
      if (error) {
        setError({
          error,
          description: error.message,
        });
      }
    }, [error, setError]);

    const courseHeader = { ...defaultProps.courseHeader };
    courseHeader.button = {
      ...courseHeader.button,
      onButtonClicked: (): void => {
        history.push(`/${appId}/${serviceName}`);
      },
    };

    const validateCourseStatusPublish =
      courseDetails?.status === CourseStatus.PUBLISH;
    courseHeader.publishButton = {
      ...defaultCourseHeaderProps.publishButton,
      colour: validateCourseStatusPublish ? 'Danger' : 'Brand',
      text: {
        ...defaultCourseHeaderProps.publishButton?.text,
        value: validateCourseStatusPublish
          ? t('course.status.unpublish')
          : t('course.status.publish'),
      },
      onButtonClicked: () =>
        validateCourseStatusPublish
          ? setConfirmationModalOpen(true)
          : handlePublishCourse(
              courseDetails,
              updateCourse,
              setError,
              setConfirmationModalOpen,
              refetch,
            ),
    };

    const viewProps: CourseEditLayoutProps = {
      confirmationModal: {
        ...defaultConfirmationModalProps,        
        title: {
          ...defaultConfirmationModalProps.title,        
          value: t('warning.modal.course.unpublish.title')
        },        
        description: {
          ...defaultConfirmationModalProps.description,
          value: t('warning.modal.course.unpublish.description'),
        },
        buttonGroup: {
          ...defaultConfirmationModalProps.buttonGroup,
          primary: {
            ...defaultConfirmationModalProps.buttonGroup.primary,
            colour: 'Danger',
            text: {
              style: 'White',
              type: 'Subtitle2',
              className: 'text-white',
              value: 'Yes',
            },
            onButtonClicked: () =>
              handlePublishCourse(
                courseDetails,
                updateCourse,
                setError,
                setConfirmationModalOpen,
                refetch,
              ),
          },
          secondary: {
            ...defaultConfirmationModalProps.buttonGroup.secondary,
            text: {
              style: 'White',
              type: 'Subtitle2',
              value: 'No',
            },
            onButtonClicked: handleCloseModal,
          },
        },
        show: confirmationModalOpen,
        onClose: handleCloseModal,
      },
    };

    return <View {...props} {...viewProps} courseHeader={courseHeader} />;
  };

  return Presenter;
};

export default withPresenter;
