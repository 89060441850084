import { useCallback } from 'react';
import useDelete from '../../lib/api/useDelete';
import { deleteTag } from './api';
import { UseDeleteTagResult } from './types';

const useDeleteTag = (): UseDeleteTagResult => {
  const onDelete = useCallback((id: number): Promise<void> => deleteTag(id), []);
  return useDelete(onDelete);
};

export default useDeleteTag;
