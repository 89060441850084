import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import useDeleteCourse from '../../../modules/course/useDeleteCourse';
import useUpdateCourse from '../../../modules/course/useUpdateCourse';
import useUploadImage from '../../../modules/common/useUploadImage';
import { CourseDetailsContext, CourseEditContext } from '../../layout/CourseEditLayout/context';
import { ErrorContext } from '../../organisms/ModalError/context';
import { ConfirmationContext } from '../../organisms/ConfirmationModal/context';
import { CourseEditBlockProps } from './CourseEditBlock';
import { CourseEditBlockPresenterProps } from './CourseEditBlock.presenter';
import useTags from '../../../modules/tags/useTags';
import useAddCourseTag from '../../../modules/course/useAddCourseTag';
import useRemoveCourseTag from '../../../modules/course/useRemoveCourseTag';

const withInteractor = (
  Presenter: React.FC<CourseEditBlockPresenterProps>,
): React.FC<CourseEditBlockProps> => {
  const Interactor: React.FC<CourseEditBlockProps> = (props) => {
    const { courseId } = useParams<{ courseId: string }>();
    const [
      { loading: updateLoading }, updateCourse,
    ] = useUpdateCourse(Number(courseId));
    const [
      { loading: deleteLoading }, deleteCourse,
    ] = useDeleteCourse();
    const [
      { loading: uploadImageLoading }, uploadImage,
    ] = useUploadImage();
    const { setError } = useContext(ErrorContext);
    const { setConfirmation } = useContext(ConfirmationContext);
    const { setIsEditing } = useContext(CourseEditContext);
    const {
      courseState: {
        data, loading: getLoading, error: getError, refetch,
      },
    } = useContext(CourseDetailsContext);
    const { data: tagsData, loading: tagsLoading, error: tagsError } = useTags();
    const [
      { loading: addCourseTagLoading }, addCourseTag,
    ] = useAddCourseTag(Number(courseId));
    const [
      { loading: removeCourseTagLoading }, removeCourseTag,
    ] = useRemoveCourseTag(Number(courseId));

    const loading = getLoading || updateLoading || uploadImageLoading
    || deleteLoading || tagsLoading || addCourseTagLoading || removeCourseTagLoading;

    const error = getError || tagsError;

    return (
      <Presenter
        {...props}
        data={data}
        allTags={tagsData}
        loading={loading}
        error={error}
        setError={setError}
        setConfirmation={setConfirmation}
        updateCourse={updateCourse}
        deleteCourse={deleteCourse}
        uploadImage={uploadImage}
        addCourseTag={addCourseTag}
        removeCourseTag={removeCourseTag}
        refetchCourse={refetch}
        setIsEditing={setIsEditing}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
