import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Location } from 'history';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import deepEqual from 'deep-equal';
import cloneDeep from 'lodash.clonedeep';
import { HtmlEditBlockProps, defaultProps } from './HtmlEditBlock';
import { UnitResponse, UpdateUnitPayload } from '../../../modules/unit/types';
import { Lesson, HtmlUnit } from '../../../modules/types';
import { ApiResponse } from '../../../lib/api/types';
import { getBasePath } from '../../../lib/api/utils';
import { QUILL_EMPTY_FIELD } from '../../molecules/HtmlEditor/HtmlEditor';
import { ErrorContextState } from '../../organisms/ModalError/context/ErrorContext';
import { ConfirmationContextState } from '../../organisms/ConfirmationModal/context/ConfirmationContext';
import { setNavigateAwayConfirmationModal } from '../../../lib/utils';

type UnitState = {
  title?: string;
  description?: string;
  canSkip?: boolean;
  estimatedTime?: number;
  text?: string;
};

export type HtmlEditBlockPresenterProps = HtmlEditBlockProps & {
  loading?: boolean;
  error?: Error;
  data: Lesson | null;
  updateUnit: (payload: UpdateUnitPayload) => Promise<UnitResponse>;
  deleteUnit: (id: number) => Promise<ApiResponse<void>>;
  refetchCourse: () => void;
  setError: React.Dispatch<React.SetStateAction<ErrorContextState | undefined>>;
  setConfirmation: React.Dispatch<React.SetStateAction<ConfirmationContextState | undefined>>;
};

const withPresenter = (
  View: React.FC<HtmlEditBlockProps>,
): React.FC<HtmlEditBlockPresenterProps> => {
  const Presenter: React.FC<HtmlEditBlockPresenterProps> = (props) => {
    const {
      data: lesson, updateUnit, deleteUnit, setError, setConfirmation, refetchCourse,
    } = props;

    const history = useHistory();
    const {
      unitId, lessonId, courseId,
    } = useParams<{ unitId: string; lessonId: string; courseId: string}>();

    const unit: HtmlUnit | null = useMemo(() => cloneDeep(lesson?.units.find((item) => {
      return `${item.id}` === unitId;
    })) || null, [lesson, unitId]);

    const [unitState, setUnitState] = useState<UnitState>({
      title: unit?.title || '',
      description: unit?.description || '',
      canSkip: unit?.canSkip || true,
      estimatedTime: unit?.estimatedTime || 0,
      text: unit?.text || '',
    });

    const isUnitModified = !deepEqual(unit, unitState);

    /**
     * Changing text state between two large strings has serious performance issues
     * Instead we change to loading first and then to the new value which makes it
     * render faster
     */
    const discardChanges = useCallback((): void => {
      setUnitState({ ...unit, text: 'Loading...' });
    }, [unit]);

    useEffect(() => {
      if (unitState?.text === 'Loading...') {
        setUnitState({ ...unit });
      }
    }, [unit, unitState]);

    useEffect(() => {
      discardChanges();
    }, [discardChanges, unitId]);

    const { t } = useTranslation();
    const {
      title,
      description,
      estimatedTime,
      text,
    } = unitState;

    const handleUpdateUnit = async (): Promise<void> => {
      const { data, error } = await updateUnit({ ...unitState, unitType: 'html' });
      if (!error && data) {
        refetchCourse();
        setError(undefined);
      } else {
        // Dispatch error modal
        setError({
          error,
          description: t('error.modal.update_unit.description'),
          primaryButton: {
            text: t('error.modal.update_unit.button.primary'),
            onClicked: handleUpdateUnit,
          },
        });
      }
    };

    const handleDeleteUnit = async (): Promise<void> => {
      if (!unit) {
        return;
      }
      const { error } = await deleteUnit(unit.id);

      setConfirmation(undefined);

      if (error) {
        // Dispatch Error Modal
        setError({
          error,
          title: t('error.modal.delete_unit.title'),
          description: t('error.modal.delete_unit.description'),
          primaryButton: {
            text: t('error.modal.delete_unit.button.primary'),
            onClicked: handleUpdateUnit,
          },
        });
      } else if (lesson) {
        const currentUnitIndex = lesson.units.findIndex((item) => item.id === Number(unitId));

        let redirectIndex = -1;
        if (currentUnitIndex !== -1) {
          if (lesson.units[currentUnitIndex + 1]) {
            redirectIndex = currentUnitIndex + 1;
          } else if (lesson.units[currentUnitIndex - 1]) {
            redirectIndex = currentUnitIndex - 1;
          }
        }
        const baseRedirectPath = `${getBasePath()}/course/${courseId}/lesson/${lessonId}`;
        if (redirectIndex !== -1) {
          const redirectUnit = lesson.units[redirectIndex];
          history.replace(`${baseRedirectPath}/${redirectUnit.unitType}/${redirectUnit.id}`);
        } else {
          history.replace(`${baseRedirectPath}`);
        }
        refetchCourse();
      }
    };

    // Get change handler for unit input fields
    const getChangeHandler = (property: keyof UnitState) => {
      return (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
      ): void => {
        setUnitState({
          ...unitState,
          [property]: event.currentTarget.value,
        });
      };
    };

    const handleUpdateHtml = (value: string): void => {
      /**
       * If the input is empty we will set state to empty string instead of ReactQuill's default
       * If the update is coming as a result of a discard, i.e. the value is the original value
       * or an empty input and the unit is currently updated
       */
      if (!((value === unit?.text || value === QUILL_EMPTY_FIELD) && isUnitModified)) {
        setUnitState({ ...unitState, text: value === QUILL_EMPTY_FIELD ? '' : value });
      }
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      setNavigateAwayConfirmationModal(handleUpdateUnit, () => Promise.resolve(discardChanges()), nextLocation, history, setConfirmation, t);
      return false;
    }

    const setDeleteModal = (): void => {
      setConfirmation({
        description: t('warning.modal.delete_unit.description', { unitName: unit?.title }),
        primaryButton: {
          onClicked: handleDeleteUnit
        }
      })
    };

    const viewProps: HtmlEditBlockProps = {
      ...defaultProps,
      ...props,
      blockHeader: {
        ...defaultProps.blockHeader,
        text: {
          ...defaultProps.blockHeader.text,
          value: title,
        },
      },
      name: {
        ...defaultProps.name,
        input: { textValue: title, onTextChanged: getChangeHandler('title') },
      },
      description: {
        ...defaultProps.description,
        textArea: { textValue: description, onTextChanged: getChangeHandler('description') },
      },
      htmlEditor: {
        onTextChanged: handleUpdateHtml,
        textValue: text,
      },
      estimatedTime: {
        ...defaultProps.estimatedTime,
        inputWithStepper: {
          ...defaultProps.estimatedTime.inputWithStepper,
          input: { textValue: `${estimatedTime}`, onTextChanged: getChangeHandler('estimatedTime') },
        },
      },
      buttonSection: {
        ...defaultProps.buttonSection,
        buttonGroup: {
          primary: {
            ...defaultProps.buttonSection.buttonGroup?.primary,
            onButtonClicked: handleUpdateUnit,
            disabled: !isUnitModified,
          },
          secondary: {
            ...defaultProps.buttonSection.buttonGroup?.secondary,
            onButtonClicked: discardChanges,
            disabled: !isUnitModified,
          },
        },
        tertiary: {
          ...defaultProps.buttonSection.tertiary,
          onButtonClicked: setDeleteModal,
        },
      },
    };

    return (
      <>
        <Prompt when={isUnitModified} message={handleBlockedNavigation} />
        <View {...viewProps} />
      </>
    );
  };

  return Presenter;
};

export default withPresenter;
