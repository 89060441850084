import { CourseDetails, CourseStatus } from '../../../modules/types';
import {
  CourseResponse,
  UpdateCoursePayload,
} from '../../../modules/course/types';
import { ErrorContextValue } from '../../organisms/ModalError/context/ErrorContext';

export const handlePublishCourse = async (
  courseDetails: CourseDetails | null,
  updateCourse: (payload: UpdateCoursePayload) => Promise<CourseResponse>,
  setError: ErrorContextValue['setError'],
  setConfirmationModalOpen: (value: React.SetStateAction<boolean>) => void,
  refetch: () => void,
) => {
  if (!courseDetails) {
    return;
  }
  const { status } = courseDetails;
  const toggleStatus =
    status === CourseStatus.PUBLISH
      ? CourseStatus.UNPUBLISH
      : CourseStatus.PUBLISH;
  const { error } = await updateCourse({ status: toggleStatus });
  if (error) {
    return setError({
      error,
      description: error.message,
    });
  }
  setConfirmationModalOpen(false);
  refetch();
};
