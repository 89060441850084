import React from 'react';
import cx from 'classnames';

import styles from './Icon.module.scss';

import VimeoAsset from '../../../resources/icons/Vimeo.svg';
import YoutubeAsset from '../../../resources/icons/Youtube.svg';
import GridAsset from '../../../resources/icons/Grid.svg';
import MoreVerticalAsset from '../../../resources/icons/MoreVertical.svg';
import BarChartAsset from '../../../resources/icons/BarChart.svg';
import StarAsset from '../../../resources/icons/Star.svg';
import PlusAsset from '../../../resources/icons/Plus.svg';
import UploadAsset from '../../../resources/icons/Upload.svg';
import ExternalLinkAsset from '../../../resources/icons/ExternalLink.svg';
import VideoReelAsset from '../../../resources/icons/VideoReel.svg';
import ArrowLeftAsset from '../../../resources/icons/ArrowLeft.svg';
import ExitAsset from '../../../resources/icons/Exit.svg';
import ArrowUpAsset from '../../../resources/icons/ArrowUp.svg';
import ArrowDownAsset from '../../../resources/icons/ArrowDown.svg';
import SettingsAsset from '../../../resources/icons/Settings.svg';
import ExpandAsset from '../../../resources/icons/Expand.svg';
import CollapseAsset from '../../../resources/icons/Collapse.svg';
import PriceTagAsset from '../../../resources/icons/PriceTag.svg';
import CloseAsset from '../../../resources/icons/Close.svg';
import UndoAsset from '../../../resources/icons/Undo.svg';
import PaperPlaneAsset from '../../../resources/icons/PaperPlane.svg';
import CornerUpRightAsset from '../../../resources/icons/CornerUpRight.svg';
import PlusCircleAsset from '../../../resources/icons/PlusCircle.svg';
import FileAsset from '../../../resources/icons/File.svg';
import VideoAsset from '../../../resources/icons/Video.svg';
import BookAsset from '../../../resources/icons/Book.svg';
import FileTimeAsset from '../../../resources/icons/FileTime.svg';
import CodeAsset from '../../../resources/icons/Code.svg';
import FileAddAsset from '../../../resources/icons/FileAdd.svg';
import ChevronUpAsset from '../../../resources/icons/ChevronUp.svg';
import ChevronDownAsset from '../../../resources/icons/ChevronDown.svg';
import MoreAppsAsset from '../../../resources/icons/MoreApps.svg';
import BellAsset from '../../../resources/icons/Bell.svg';
import CircleAsset from '../../../resources/icons/Circle.svg';
import CircleCheckAsset from '../../../resources/icons/CircleCheck.svg';
import SquareAsset from '../../../resources/icons/Square.svg';
import SquareCheckAsset from '../../../resources/icons/SquareCheck.svg';
import DragHandleAsset from '../../../resources/icons/DragHandle.svg';
import ClearFilledAsset from '../../../resources/icons/ClearFilled.svg';
import CircleChevronUpAsset from '../../../resources/icons/CircleChevronUp.svg';
import CircleChevronDownAsset from '../../../resources/icons/CircleChevronDown.svg';
import BellDotAsset from '../../../resources/icons/BellDot.svg';
import UploadImageAsset from '../../../resources/icons/UploadImage.svg';

export type IconAssetType = 'Vimeo' | 'Youtube' | 'Grid' | 'MoreVertical' | 'BarChart' | 'Star' | 'Plus' | 'Upload' | 'ExternalLink' | 'VideoReel' | 'ArrowLeft' | 'Exit' | 'ArrowUp' | 'ArrowDown' | 'Settings' | 'Expand' | 'Collapse' | 'PriceTag' | 'Close' | 'Undo' | 'PaperPlane' | 'CornerUpRight' | 'PlusCircle' | 'File' | 'Video' | 'Book' | 'FileTime' | 'Code' | 'FileAdd' | 'ChevronUp' | 'ChevronDown' | 'MoreApps' | 'Bell' | 'Circle' | 'CircleCheck' | 'Square' | 'SquareCheck' | 'DragHandle' | 'ClearFilled' | 'CircleChevronUp' | 'CircleChevronDown' | 'BellDot' | 'UploadImage';
export type IconStyleType = 'Default' | 'Dark' | 'Blue' | 'Brand' | 'Disabled' | 'Light' | 'Grey';

export const defaultProps = {
  asset: 'UploadImage' as IconAssetType,
  style: 'Blue' as IconStyleType,
};

export type IconProps = {
  asset?: IconAssetType;
  style?: IconStyleType;
  contentAlt?: string;
  className?: string;
};

const Icon: React.FC<IconProps> = ({
  asset,
  style,
  contentAlt,
  className,
}) => {

  const currentStyle = styles[`icon${asset}${style}`];

  let content = '';
  switch (asset) {
    case 'Vimeo':
      content = VimeoAsset;
      break;
    case 'Youtube':
      content = YoutubeAsset;
      break;
    case 'Grid':
      content = GridAsset;
      break;
    case 'MoreVertical':
      content = MoreVerticalAsset;
      break;
    case 'BarChart':
      content = BarChartAsset;
      break;
    case 'Star':
      content = StarAsset;
      break;
    case 'Plus':
      content = PlusAsset;
      break;
    case 'Upload':
      content = UploadAsset;
      break;
    case 'ExternalLink':
      content = ExternalLinkAsset;
      break;
    case 'VideoReel':
      content = VideoReelAsset;
      break;
    case 'ArrowLeft':
      content = ArrowLeftAsset;
      break;
    case 'Exit':
      content = ExitAsset;
      break;
    case 'ArrowUp':
      content = ArrowUpAsset;
      break;
    case 'ArrowDown':
      content = ArrowDownAsset;
      break;
    case 'Settings':
      content = SettingsAsset;
      break;
    case 'Expand':
      content = ExpandAsset;
      break;
    case 'Collapse':
      content = CollapseAsset;
      break;
    case 'PriceTag':
      content = PriceTagAsset;
      break;
    case 'Close':
      content = CloseAsset;
      break;
    case 'Undo':
      content = UndoAsset;
      break;
    case 'PaperPlane':
      content = PaperPlaneAsset;
      break;
    case 'CornerUpRight':
      content = CornerUpRightAsset;
      break;
    case 'PlusCircle':
      content = PlusCircleAsset;
      break;
    case 'File':
      content = FileAsset;
      break;
    case 'Video':
      content = VideoAsset;
      break;
    case 'Book':
      content = BookAsset;
      break;
    case 'FileTime':
      content = FileTimeAsset;
      break;
    case 'Code':
      content = CodeAsset;
      break;
    case 'FileAdd':
      content = FileAddAsset;
      break;
    case 'ChevronUp':
      content = ChevronUpAsset;
      break;
    case 'ChevronDown':
      content = ChevronDownAsset;
      break;
    case 'MoreApps':
      content = MoreAppsAsset;
      break;
    case 'Bell':
      content = BellAsset;
      break;
    case 'Circle':
      content = CircleAsset;
      break;
    case 'CircleCheck':
      content = CircleCheckAsset;
      break;
    case 'Square':
      content = SquareAsset;
      break;
    case 'SquareCheck':
      content = SquareCheckAsset;
      break;
    case 'DragHandle':
      content = DragHandleAsset;
      break;
    case 'ClearFilled':
      content = ClearFilledAsset;
      break;
    case 'CircleChevronUp':
      content = CircleChevronUpAsset;
      break;
    case 'CircleChevronDown':
      content = CircleChevronDownAsset;
      break;
    case 'BellDot':
      content = BellDotAsset;
      break;
    case 'UploadImage':
      content = UploadImageAsset;
      break;
    default:
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      <img
        className={styles.content}
        alt={contentAlt}
        src={content} />
    </div>
  );
};

Icon.defaultProps = defaultProps;

export default Icon;
