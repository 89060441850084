import React from 'react';
import cx from 'classnames';

import styles from './MainMenu.module.scss';

import MainMenuHeader, { MainMenuHeaderProps } from '../../atoms/MainMenuHeader';
import Divider, { DividerProps } from '../../atoms/Divider';
import MainMenuItemList, { MainMenuItemListProps } from '../../organisms/MainMenuItemList';

export const defaultProps = {
  mainMenuHeader: {
    text: {
      type: 'Subtitle1',
      style: 'Dark',
      size: 'Medium',
      align: 'Left',
      value: 'Varicent LMS',
    },
  } as MainMenuHeaderProps,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  mainMenuItemList: {
    mainMenuItems: [
        {
          type: 'Default',
          icon: {
            asset: 'Grid',
            style: 'Dark',
          },
          text: {
            type: 'Subtitle2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Courses',
          },
        },
        {
          type: 'Selected',
          icon: {
            asset: 'Grid',
            style: 'Dark',
          },
          text: {
            type: 'Subtitle2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Courses',
          },
        },
        {
          type: 'Default',
          icon: {
            asset: 'Grid',
            style: 'Dark',
          },
          text: {
            type: 'Subtitle2',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Courses',
          },
        },
    ],
  } as MainMenuItemListProps,
};

export type MainMenuProps = {
  mainMenuHeader?: MainMenuHeaderProps;
  divider?: DividerProps;
  mainMenuItemList?: MainMenuItemListProps;
  className?: string;
};

const MainMenu: React.FC<MainMenuProps> = ({
  mainMenuHeader,
  divider,
  mainMenuItemList,
  className,
}) => {
  return (
    <div className={cx(styles.mainMenu, className)}>
      <MainMenuHeader
        className={styles.mainMenuHeader}
        {...mainMenuHeader} />
      <Divider
        className={styles.divider}
        {...divider} />
      <MainMenuItemList
        className={styles.mainMenuItemList}
        {...mainMenuItemList} />
    </div>
  );
};

MainMenu.defaultProps = defaultProps;

export default MainMenu;
