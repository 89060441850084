import React from 'react';
import cx from 'classnames';

import styles from './TableItemList.module.scss';

import TableItem, { TableItemProps } from '../TableItem';

export const defaultProps = {
  tableItems: [
    {
      state: 'NoFileSelected',
      type: 'FileUpload',
      resourceTypeSelector: {
        text: {
          type: 'Paragraph1',
          style: 'Grey',
          size: 'Large',
          align: 'Left',
          value: 'Upload file',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Disabled',
        },
      },
      resourceNameInput: {
        style: 'Grey',
        textPlaceholder: 'Name',
      },
      selectFileItem: {
        state: 'Default',
        button: {
          type: 'TextButton',
          size: 'Large',
          fill: 'Colour',
          colour: 'Brand',
          text: {
            type: 'Subtitle1',
            style: 'White',
            size: 'Medium',
            align: 'Center',
            value: 'Select file',
          },
        },
      },
      moreButton: {
        type: 'IconButton',
        size: 'Large',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'MoreVertical',
          style: 'Dark',
        },
      },
    },
    {
      state: 'FileSelected',
      type: 'FileUpload',
      resourceTypeSelector: {
        text: {
          type: 'Paragraph1',
          style: 'Grey',
          size: 'Large',
          align: 'Left',
          value: 'Upload file',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Disabled',
        },
      },
      resourceNameInput: {
        style: 'Grey',
        textPlaceholder: 'Name',
      },
      selectFileItem: {
        state: 'FileSelected',
        text: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'File name.pdf',
        },
        button: {
          type: 'IconButton',
          size: 'Large',
          fill: 'White',
          colour: 'Basic',
          icon: {
            asset: 'Close',
            style: 'Dark',
          },
        },
      },
      moreButton: {
        type: 'IconButton',
        size: 'Large',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'MoreVertical',
          style: 'Dark',
        },
      },
    },
    {
      state: 'Default',
      type: 'ExternalLink',
      resourceTypeSelector: {
        text: {
          type: 'Paragraph1',
          style: 'Grey',
          size: 'Large',
          align: 'Left',
          value: 'External link',
        },
        icon: {
          asset: 'ChevronDown',
          style: 'Disabled',
        },
      },
      deleteButton: {
        type: 'IconButton',
        size: 'Large',
        fill: 'White',
        colour: 'Basic',
        icon: {
          asset: 'Close',
          style: 'Dark',
        },
      },
    },
  ] as TableItemProps[],
};

export type TableItemListProps = {
  tableItems?: TableItemProps[];
  className?: string;
};

const TableItemList: React.FC<TableItemListProps> = ({
  tableItems,
  className,
}) => {
  const tableItemArray = tableItems?.map((tableItem) => (
    <TableItem
      key={tableItem.id}
      className={styles.tableItem}
      {...tableItem} />
  ));

  return (
    <div className={cx(styles.tableItemList, className)}>
      {tableItemArray}
    </div>
  );
};

TableItemList.defaultProps = defaultProps;

export default TableItemList;
