import React from 'react';
import cx from 'classnames';

import styles from './TagSectionList.module.scss';

import TagSectionItem, { TagSectionItemProps } from '../TagSectionItem';

export const defaultProps = {
  tagSectionItems: [
      {
        sectionHeader: {
          state: 'WithDivider',
          text: {
            type: 'Title3',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Tag group',
          },
          divider: {
            style: 'Horizontal',
          },
        },
        tagTypeList: {
          chipsFields: [
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
          ],
        },
      },
      {
        sectionHeader: {
          state: 'WithDivider',
          text: {
            type: 'Title3',
            style: 'Dark',
            size: 'Medium',
            align: 'Left',
            value: 'Tag group',
          },
          divider: {
            style: 'Horizontal',
          },
        },
        tagTypeList: {
          chipsFields: [
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
              {
                label: {
                  type: 'Paragraph1',
                  style: 'Dark',
                  size: 'Large',
                  align: 'Left',
                  value: 'Tag type',
                },
              },
          ],
        },
      },
  ] as TagSectionItemProps[],
};

export type TagSectionListProps = {
  tagSectionItems?: TagSectionItemProps[];
  className?: string;
};

const TagSectionList: React.FC<TagSectionListProps> = ({
  tagSectionItems,
  className,
}) => {
  const tagSectionItemArray = tagSectionItems?.map((tagSectionItem) => (
    <TagSectionItem
      className={styles.tagSectionItem}
      {...tagSectionItem} />
  ));
  
  return (
    <div className={cx(styles.tagSectionList, className)}>
      {tagSectionItemArray}
    </div>
  );
};

TagSectionList.defaultProps = defaultProps;

export default TagSectionList;
