import { useCallback } from 'react';
import useCreate from '../../lib/api/usePost';
import { postAdditionalResource } from './api';
import { AdditionalResource, AdditionalResourcePayload, UseCreateAdditionalResourceResult } from './types';

const useCreateAdditionalResource = (lessonId: number): UseCreateAdditionalResourceResult => {
  const post = useCallback((
    payload: AdditionalResourcePayload,
  ): Promise<AdditionalResource> => postAdditionalResource(payload, lessonId), [lessonId]);
  return useCreate(post);
};

export default useCreateAdditionalResource;
