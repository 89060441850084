import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getAllCourseDetails } from './api';
import { CourseDetails } from '../types';

export type UseAllCourseDetailsResult = {
  loading: boolean;
  error: undefined | Error;
  data: CourseDetails[] | null;
  refetch: () => void;
};

const useAllCourseDetails = (): UseAllCourseDetailsResult => {
  const get = useCallback((): Promise<CourseDetails[]> => getAllCourseDetails(), []);
  return useGet(get);
};

export default useAllCourseDetails;
