import { useCallback } from 'react';
import useUpdate from '../../lib/api/useUpdate';
import { updateLesson } from './api';
import { Lesson, UpdateLessonPayload, UseUpdateLessonResult } from './types';

const useUpdateLesson = (id: number): UseUpdateLessonResult => {
  const update = useCallback((
    payload: UpdateLessonPayload,
  ): Promise<Lesson> => updateLesson(id, payload), [id]);
  return useUpdate(update);
};

export default useUpdateLesson;
