import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';
import { EditCourseMenuProps, defaultProps } from './EditCourseMenu';
import { MenuLessonItemProps } from '../../organisms/MenuLessonItem/MenuLessonItem';
import { CourseDetails, LessonWithEstimatedTime } from '../../../modules/types';
import { CreateLessonPayload, LessonResponse } from '../../../modules/lesson/types';
import { ApiResponse } from '../../../lib/types';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../../../modules/common/types';
import useHandleDrag from '../../../lib/hooks/useHandleDrag';
import useHandleReorderMany from '../../../lib/hooks/useHandleReorderMany';

export type EditCourseMenuPresenterProps = EditCourseMenuProps & {
  courseDetails: CourseDetails | null;
  createLesson: (payload: CreateLessonPayload) => Promise<LessonResponse>;
  reorderLesson: (payload: ReorderEntityPayload) => Promise<ApiResponse<void>>;
  // TODO: Remove this
  reorderLessons: (payload: ReorderEntitiesPayload) => Promise<ApiResponse<void>>;
  refetchCourse: () => void;
};

const withPresenter = (
  View: React.FC<EditCourseMenuProps>,
): React.FC<EditCourseMenuPresenterProps> => {
  const Presenter: React.FC<EditCourseMenuPresenterProps> = (props) => {
    const {
      courseDetails, createLesson, reorderLesson, reorderLessons, refetchCourse, ...otherProps
    } = props;
    const {
      appId, serviceName, courseId,
    } = useParams<{ appId: string; serviceName: string; courseId: string }>();
    const { pathname } = useLocation();
    const history = useHistory();

    const [menuLessonsState, setMenuLessonsState] = useState<LessonWithEstimatedTime[]>([]);

    useEffect(() => {
      setMenuLessonsState(courseDetails ? cloneDeep(courseDetails).lessons : []);
    }, [courseDetails]);

    const handleDrag = useHandleDrag(menuLessonsState, setMenuLessonsState);
    // TODO: Change back to useHandleReorder
    const handleReorderLesson = useHandleReorderMany(menuLessonsState, reorderLessons, refetchCourse);

    const currentCoursePagePath = `/${appId}/${serviceName}/course/${courseId}`;

    const menuCourse = { ...defaultProps.menuCourse };
    menuCourse.type = currentCoursePagePath === pathname ? 'Selected' : 'Default';
    menuCourse.text = {
      ...defaultProps.menuCourse.text,
      value: courseDetails?.title || '',
    };
    menuCourse.onMenuCourseClicked = (): void => {
      if (pathname !== currentCoursePagePath) {
        history.push(currentCoursePagePath);
      }
    };

    let menuLessonItems: MenuLessonItemProps[] = [];
    if (menuLessonsState) {
      menuLessonItems = menuLessonsState.map((lesson, index): MenuLessonItemProps => {
        return {
          lesson,
          index,
          id: lesson.id,
          onDrag: handleDrag,
          onDrop: handleReorderLesson,
        };
      });
    }

    const menuLessonItemList = { ...defaultProps.menuLessonItemList };
    menuLessonItemList.menuLessonItems = menuLessonItems;

    const handleCreateLesson = async (): Promise<void> => {
      const { data: lesson } = await createLesson({ description: '', title: 'Untitled Lesson' });
      if (lesson) {
        refetchCourse();
        history.push(`/${appId}/${serviceName}/course/${courseId}/lesson/${lesson.id}`);
      } else {
        // TODO add error handling
      }
    };
    const addLesson = { ...defaultProps.addLesson };
    addLesson.onMenuUnitClicked = handleCreateLesson;

    return <View
      {...otherProps}
      menuCourse={menuCourse}
      menuLessonItemList={menuLessonItemList}
      addLesson={addLesson} />;
  };

  return Presenter;
};

export default withPresenter;
