import React from 'react';
import cx from 'classnames';

import styles from './ButtonSection.module.scss';

import Divider, { DividerProps } from '../../atoms/Divider';
import ButtonGroup, { ButtonGroupProps } from '../ButtonGroup';
import Button, { ButtonProps } from '../../atoms/Button';

export type ButtonSectionStateType = 'WithDeleteButton' | 'NoDeleteButton';

export const defaultProps = {
  state: 'NoDeleteButton' as ButtonSectionStateType,
  divider: {
    style: 'Horizontal',
  } as DividerProps,
  buttonGroup: {
    primary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'Colour',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'White',
        size: 'Medium',
        align: 'Center',
        value: 'Save',
      },
    },
    secondary: {
      type: 'TextButton',
      size: 'Large',
      fill: 'White',
      colour: 'Brand',
      text: {
        type: 'Subtitle1',
        style: 'Brand',
        size: 'Medium',
        align: 'Center',
        value: 'Discard',
      },
    },
  } as ButtonGroupProps,
  tertiary: {
    type: 'TextButton',
    size: 'Large',
    fill: 'White',
    colour: 'Danger',
    text: {
      type: 'Subtitle1',
      style: 'Brand',
      size: 'Medium',
      align: 'Center',
      value: 'Delete',
    },
  } as ButtonProps,
};

export type ButtonSectionProps = {
  state?: ButtonSectionStateType;
  divider?: DividerProps;
  buttonGroup?: ButtonGroupProps;
  tertiary?: ButtonProps;
  className?: string;
};

const ButtonSection: React.FC<ButtonSectionProps> = ({
  state,
  divider,
  buttonGroup,
  tertiary,
  className,
}) => {
  const buttonGroupElement = (
    <ButtonGroup
    className={styles.buttonGroup}
    {...buttonGroup} />
  );

  const currentStyle = styles[`buttonSection${state}`];

  const dividerView = (
    <Divider
      className={styles.divider}
      {...divider} />
  );

  let buttonsView;

  switch (state) {
    case 'WithDeleteButton':
      buttonsView = (
        <div className={styles.buttons}>
          {buttonGroupElement}
          <Button
            className={styles.tertiary}
            {...tertiary} />
        </div>
      );
      break;
    case 'NoDeleteButton':
      buttonsView = (
        <div className={styles.buttons}>
          {buttonGroupElement}
        </div>
      );
      break;
  }

  return (
    <div className={cx(currentStyle, className)}>
      {dividerView}
      {buttonsView}
    </div>
  );
};

ButtonSection.defaultProps = defaultProps;

export default ButtonSection;
