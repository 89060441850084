import { useCallback } from 'react';
import useGet from '../../lib/api/useGet';
import { getAppDetails } from './api';
import { AppDetails, UseAppDetailsResult } from './types';

const useAppDetails = (): UseAppDetailsResult => {
  const get = useCallback((): Promise<AppDetails> => getAppDetails(), []);
  return useGet(get);
};

export default useAppDetails;
