import React from 'react';
import cx from 'classnames';

import styles from './RadioButtonList.module.scss';

import RadioButton, { RadioButtonProps } from '../../atoms/RadioButton';

export const defaultProps = {
  radioButtons: [
      {
        icon: {
          asset: 'Vimeo',
          style: 'Default',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Vimeo',
        },
      },
      {
        icon: {
          asset: 'Youtube',
          style: 'Default',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Youtube',
        },
      },
      {
        icon: {
          asset: 'Upload',
          style: 'Blue',
        },
        text: {
          type: 'Paragraph2',
          style: 'Dark',
          size: 'Medium',
          align: 'Left',
          value: 'Upload',
        },
      },
  ] as RadioButtonProps[],
};

export type RadioButtonListProps = {
  radioButtons?: RadioButtonProps[];
  className?: string;
};

const RadioButtonList: React.FC<RadioButtonListProps> = ({
  radioButtons,
  className,
}) => {
  const radioButtonArray = radioButtons?.map((radioButton) => (
    <RadioButton
      className={styles.radioButton}
      {...radioButton} />
  ));
  
  return (
    <div className={cx(styles.radioButtonList, className)}>
      {radioButtonArray}
    </div>
  );
};

RadioButtonList.defaultProps = defaultProps;

export default RadioButtonList;
