import React from 'react';
import cx from 'classnames';

import styles from './TagTypeList.module.scss';

import InputChipsField, { InputChipsFieldProps } from '../../molecules/InputChipsField';

export const defaultProps = {
  chipsFields: [
      {
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Tag type',
        },
      },
      {
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Tag type',
        },
      },
      {
        label: {
          type: 'Paragraph1',
          style: 'Dark',
          size: 'Large',
          align: 'Left',
          value: 'Tag type',
        },
      },
  ] as InputChipsFieldProps[],
};

export type TagTypeListProps = {
  chipsFields?: InputChipsFieldProps[];
  className?: string;
};

const TagTypeList: React.FC<TagTypeListProps> = ({
  chipsFields,
  className,
}) => {
  const chipsFieldArray = chipsFields?.map((chipsField) => (
    <InputChipsField
      className={styles.tagTypeItem}
      {...chipsField} />
  ));
  
  return (
    <div className={cx(styles.tagTypeList, className)}>
      {chipsFieldArray}
    </div>
  );
};

TagTypeList.defaultProps = defaultProps;

export default TagTypeList;
