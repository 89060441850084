import axios from '../../lib/api/axios';
import { getServerUrl, toApiError } from '../../lib/api/utils';
import { ReorderEntitiesPayload, ReorderEntityPayload } from '../common/types';
import {
  CreateLessonPayload, Lesson, UpdateLessonPayload,
} from './types';

// eslint-disable-next-line import/prefer-default-export
export const getLessons = async (id: number): Promise<Lesson[]> => {
  try {
    const { data } = await axios.get<Lesson[]>(
      `${getServerUrl()}/courses/${id}/lessons`,
    );
    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const postLesson = async (
  courseId: number, payload: CreateLessonPayload,
): Promise<Lesson> => {
  try {
    const { data } = await axios.post<Lesson>(
      `${getServerUrl()}/courses/${courseId}/lessons`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const updateLesson = async (
  id: number, payload: UpdateLessonPayload,
): Promise<Lesson> => {
  try {
    const { data } = await axios.patch<Lesson>(
      `${getServerUrl()}/lessons/${id}`,
      payload,
    );

    return data;
  } catch (error) {
    throw toApiError(error);
  }
};

export const deleteLesson = async (
  id: number,
): Promise<void> => {
  try {
    await axios.delete<void>(
      `${getServerUrl()}/lessons/${id}`,
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderLesson = async (
  payload: ReorderEntityPayload,
): Promise<void> => {
  const { id, order } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/lessons/${id}/actions/reorder`,
      { order },
    );
  } catch (error) {
    throw toApiError(error);
  }
};

export const reorderLessons = async (
  payload: ReorderEntitiesPayload,
): Promise<void> => {
  const { ids } = payload;
  try {
    await axios.patch<void>(
      `${getServerUrl()}/lessons/actions/reorder`,
      { ids },
    );
  } catch (error) {
    throw toApiError(error);
  }
};
