import { useCallback } from 'react';
import usePost from '../../lib/api/usePost';
import { postCourse } from './api';
import { Course, CreateCoursePayload, UseCreateCourseResult } from './types';

const useCreateCourse = (): UseCreateCourseResult => {
  const post = useCallback((
    payload: CreateCoursePayload,
  ): Promise<Course> => postCourse(payload), []);
  return usePost(post);
};

export default useCreateCourse;
