import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Lesson } from '../../../modules/lesson/types';
import useUpdateQuestions from '../../../modules/question/useUpdateQuestions';
import useUpdateUnit from '../../../modules/unit/useUpdateUnit';
import useDeleteUnit from '../../../modules/unit/useDeleteUnit';
import { CourseDetailsContext } from '../../layout/CourseEditLayout/context';
import { ErrorContext } from '../../organisms/ModalError/context';
import { QuizEditBlockProps } from './QuizEditBlock';
import { QuizEditBlockPresenterProps } from './QuizEditBlock.presenter';
import useCreateQuestion from '../../../modules/question/useCreateQuestion';
import useCreateAnswer from '../../../modules/answer/useCreateAnswer';
import useReorderQuestion from '../../../modules/question/useReorderQuestion';
import useReorderAnswer from '../../../modules/answer/useReorderAnswer';
import useReorderQuestions from '../../../modules/question/useReorderQuestions';
import useReorderAnswers from '../../../modules/answer/useReorderUnits';
import { ConfirmationContext } from '../../organisms/ConfirmationModal/context';

const withInteractor = (
  Presenter: React.FC<QuizEditBlockPresenterProps>,
): React.FC<QuizEditBlockProps> => {
  const Interactor: React.FC<QuizEditBlockProps> = (props) => {
    const { setError } = useContext(ErrorContext);
    const { setConfirmation } = useContext(ConfirmationContext);
    const { courseState } = useContext(CourseDetailsContext);

    const { lessonId, unitId } = useParams<{ lessonId: string; unitId: string }>();
    const [updateUnitState, updateUnit] = useUpdateUnit(Number(unitId));
    const [updateQuestionsState, updateQuestions] = useUpdateQuestions();
    const [createQuestionState, createQuestion] = useCreateQuestion();
    const [createAnswerState, createAnswer] = useCreateAnswer();
    const [reorderQuestionState, reorderQuestion] = useReorderQuestion();
    // TODO: Remove this hook
    const [, reorderQuestions] = useReorderQuestions();
    const [reorderAnswerState, reorderAnswer] = useReorderAnswer();
    // TODO: Remove this hook
    const [, reorderAnswers] = useReorderAnswers();

    const lesson: Lesson | null = courseState.data?.lessons.find((item) => {
      return `${item.id}` === lessonId;
    }) || null;

    const [
      { loading: deleteLoading }, deleteUnit,
    ] = useDeleteUnit();

    const loading = courseState.loading
      || updateUnitState.loading
      || updateQuestionsState.loading
      || deleteLoading
      || createQuestionState.loading
      || createAnswerState.loading
      || reorderQuestionState.loading
      || reorderAnswerState.loading;

    return (
      <Presenter
        {...props}
        data={lesson}
        loading={loading}
        error={courseState.error}
        setError={setError}
        setConfirmation={setConfirmation}
        updateUnit={updateUnit}
        deleteUnit={deleteUnit}
        refetchCourse={courseState.refetch}
        updateQuestions={updateQuestions}
        createQuestion={createQuestion}
        createAnswer={createAnswer}
        reorderQuestion={reorderQuestion}
        reorderAnswer={reorderAnswer}
        reorderQuestions={reorderQuestions}
        reorderAnswers={reorderAnswers}
      />
    );
  };

  return Interactor;
};

export default withInteractor;
